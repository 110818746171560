import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  IconButton,
  Typography,
  Box,
  Divider,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { Add, Delete } from '@mui/icons-material';

interface Ativo {
  nome: string;
  codigo: string;
  tipoAtivo: string;
  grupos: { descricao: string; limite_maximo: number | null }[];
}
interface CadastroAtivoModalProps {
    isOpen: boolean;
    onRequestClose: () => void;
    onSave: (ativo: Ativo) => void;
  }
  
  interface Item {
    descricao: string;
    limite_maximo: number | null;
    vedado: boolean;
  }
  
  interface Grupo {
    descricaoGrupo: string;
    limiteGrupo: number | null;
    limites: Item[];
  }

const CadastroAtivoModal: React.FC<CadastroAtivoModalProps> = ({ isOpen, onRequestClose, onSave }) => {
    const [nome, setNome] = useState('');
    const [codigo, setCodigo] = useState('');
    const [tipoAtivo, setTipoAtivo] = useState('');
    const [grupos, setGrupos] = useState<Grupo[]>([]);
    const [emissores, setEmissores] = useState<Item[]>([]);
    const [investimentosExterior, setInvestimentosExterior] = useState<Item[]>([]);
    const [instrumentosDerivativos, setInstrumentosDerivativos] = useState<Item[]>([]);
    const [outrasEstrategias, setOutrasEstrategias] = useState<Item[]>([]);
    const [gruposLigados, setGruposLigados] = useState<Grupo[]>([]);
    const [operaVendido, setOperaVendido] = useState(false);
  
    const adicionarItem = (setFunc: React.Dispatch<React.SetStateAction<Item[]>>) => {
      setFunc((prev) => [...prev, { descricao: '', limite_maximo: null, vedado: false }]);
    };
  
    const removerItem = (index: number, setFunc: React.Dispatch<React.SetStateAction<Item[]>>) => {
      setFunc((prev) => prev.filter((_, i) => i !== index));
    };
  
    const atualizarItem = (
        index: number,
        field: keyof Item,
        value: string | number | boolean,
        setFunc: React.Dispatch<React.SetStateAction<Item[]>>
      ) => {
        setFunc((prev) => {
          const newItems = [...prev];
          if (field === 'limite_maximo') {
            value = Number(value);
          }
          (newItems[index][field] as string | number | boolean) = value; // Adicionando o type assertion
          return newItems;
        });
    };

    const adicionarLimiteAoGrupo = (grupoIndex: number, setFunc: React.Dispatch<React.SetStateAction<Grupo[]>>) => {
        setFunc((prevGrupos) => {
          const novosGrupos = [...prevGrupos];
          novosGrupos[grupoIndex].limites.push({ descricao: '', limite_maximo: null, vedado: false });
          return novosGrupos;
        });
      };

    const atualizarLimite = (
        grupoIndex: number,
        limiteIndex: number,
        field: keyof Item,
        value: string | number | boolean,
        setFunc: React.Dispatch<React.SetStateAction<Grupo[]>>
      ) => {
        setFunc((prevGrupos) => {
          const novosGrupos = [...prevGrupos];
          if (field === 'limite_maximo') {
            value = Number(value);
          }
          (novosGrupos[grupoIndex].limites[limiteIndex][field] as string | number | boolean) = value; // Adicionando type assertion
          return novosGrupos;
        });
    };

    const atualizarGrupo = (
        grupoIndex: number,
        field: keyof Grupo,
        value: string | number | null,
        setFunc: React.Dispatch<React.SetStateAction<Grupo[]>>
      ) => {
        setFunc((prevGrupos) => {
          const novosGrupos = [...prevGrupos];
          if (field === 'limiteGrupo') {
            value = Number(value);
          }
          (novosGrupos[grupoIndex][field] as string | number | null) = value;
          return novosGrupos;
        });
      };

    const removerLimiteDoGrupo = (
        grupoIndex: number,
        limiteIndex: number,
        setFunc: React.Dispatch<React.SetStateAction<Grupo[]>>
      ) => {
        setFunc((prevGrupos) => {
          const novosGrupos = [...prevGrupos];
          novosGrupos[grupoIndex].limites = novosGrupos[grupoIndex].limites.filter((_, i) => i !== limiteIndex);
          return novosGrupos;
        });
      };
  
    const adicionarGrupo = () => {
      setGrupos([...grupos, { descricaoGrupo: '', limiteGrupo: null, limites: [] }]);
    };
  
    const removerGrupo = (
        grupoIndex: number,
        setFunc: React.Dispatch<React.SetStateAction<Grupo[]>>
      ) => {
        setFunc((prevGrupos) => prevGrupos.filter((_, i) => i !== grupoIndex));
    };

    const handleSave = () => {
      const novoAtivo: Ativo = {
        nome,
        codigo,
        tipoAtivo,
        grupos: [], // Pode adicionar os grupos se necessário
      };
  
      onSave(novoAtivo); // Passa o novo ativo para o componente pai
      setNome('');
      setCodigo('');
      setTipoAtivo('');
    };

  return (
    <Dialog open={isOpen} onClose={onRequestClose} fullWidth maxWidth="md">
        <DialogTitle>Cadastrar Novo Ativo</DialogTitle>
        <DialogContent>
            {/* Campos principais do ativo */}
            <Box display="flex" flexDirection="column" gap={2} mt={1}>
            <Select
                value={tipoAtivo}
                onChange={(e) => setTipoAtivo(e.target.value)}
                fullWidth
                displayEmpty
            >
                <MenuItem value="" disabled>Selecione o Tipo de Ativo</MenuItem>
                <MenuItem value="Renda Fixa">Renda Fixa</MenuItem>
                <MenuItem value="Renda Variável">Renda Variável</MenuItem>
                <MenuItem value="Fundo de Invesimento">Fundos de Investimento</MenuItem>
                <MenuItem value="Fundos de Investimento no Exterior">Fundos de Investimento no Exterior</MenuItem>
            </Select>
            <TextField
                label="Nome do Ativo"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
                fullWidth
                required
            />
            <TextField
                label="Código do Ativo"
                value={codigo}
                onChange={(e) => setCodigo(e.target.value)}
                fullWidth
                required
            />
            </Box>

            {/* Exibição condicional com base no tipoAtivo */}
            {tipoAtivo === 'Fundo de Invesimento' ? (
                <>
                    <Divider sx={{ my: 3 }} />

                    {/* Seção Grupos */}
                    <Typography variant="h6" gutterBottom>Grupos</Typography>
                    {grupos.map((grupo, grupoIndex) => (
                    <Box key={grupoIndex} mb={3} p={2} border={1} borderRadius={2}>
                        <Typography variant="h6">Grupo {grupoIndex + 1}</Typography>
                        <TextField
                        label="Descrição do Grupo"
                        value={grupo.descricaoGrupo}
                        onChange={(e) =>
                            setGrupos((prevGrupos) => {
                            const newGrupos = [...prevGrupos];
                            newGrupos[grupoIndex].descricaoGrupo = e.target.value;
                            return newGrupos;
                            })
                        }
                        fullWidth
                        required
                        sx={{ mt: 1 }}
                        />
                        <TextField
                        label="Limite Máximo do Grupo (%)"
                        type="number"
                        value={grupo.limiteGrupo || ''}
                        onChange={(e) =>
                            setGrupos((prevGrupos) => {
                            const newGrupos = [...prevGrupos];
                            newGrupos[grupoIndex].limiteGrupo = Number(e.target.value);
                            return newGrupos;
                            })
                        }
                        fullWidth
                        required
                        sx={{ mt: 2 }}
                        />
                        <Button
                        onClick={() => removerGrupo(grupoIndex, setGrupos)}
                        color="secondary"
                        variant="outlined"
                        sx={{ mt: 2 }}
                        >
                        Remover Grupo
                        </Button>
                    </Box>
                    ))}
                    <Button
                    onClick={adicionarGrupo}
                    variant="contained"
                    sx={{ mt: 3 }}
                    >
                    Adicionar Grupo
                    </Button>

                    {/* Seções adicionais */}
                    <Divider sx={{ my: 3 }} />
                    {[
                        { label: 'Emissores', state: emissores, setState: setEmissores },
                        { label: 'Investimentos no Exterior', state: investimentosExterior, setState: setInvestimentosExterior },
                        { label: 'Instrumentos Derivativos', state: instrumentosDerivativos, setState: setInstrumentosDerivativos },
                        { label: 'Outras Estratégias', state: outrasEstrategias, setState: setOutrasEstrategias },
                        ].map(({ label, state, setState }, index) => (
                        <Box key={index} mb={3}>
                            <Typography variant="h6" gutterBottom>{label}</Typography>
                            {state.map((item, idx) => (
                            <Box key={idx} display="flex" alignItems="center" gap={2} mt={1}>
                                <TextField
                                label="Descrição"
                                value={item.descricao}
                                onChange={(e) => atualizarItem(idx, 'descricao', e.target.value, setState)}
                                fullWidth
                                required
                                />
                                <TextField
                                label="Limite Máximo (%)"
                                type="number"
                                value={item.vedado ? '' : item.limite_maximo || ''}
                                onChange={(e) => atualizarItem(idx, 'limite_maximo', e.target.value, setState)}
                                fullWidth
                                required
                                disabled={item.vedado}
                                />
                                <FormControlLabel
                                control={
                                    <Checkbox
                                    checked={item.vedado}
                                    onChange={(e) => atualizarItem(idx, 'vedado', e.target.checked, setState)}
                                    />
                                }
                                label="Vedado"
                                />
                                <IconButton onClick={() => removerItem(idx, setState)} color="secondary">
                                <Delete />
                                </IconButton>
                            </Box>
                            ))}
                            <Button
                            onClick={() => adicionarItem(setState)}
                            variant="outlined"
                            startIcon={<Add />}
                            sx={{ mt: 2 }}
                            >
                            Adicionar {label}
                            </Button>
                        </Box>
                        ))}

                        <Divider sx={{ my: 3 }} />

                        {/* Grupos Ligados */}
                        <Typography variant="h6" gutterBottom>Grupos Ligados</Typography>
                        {gruposLigados.map((grupo, grupoIndex) => (
                        <Box key={grupoIndex} mb={3} p={2} border={1} borderRadius={2}>
                            <Typography variant="h6">Grupo Ligado {grupoIndex + 1}</Typography>
                            <TextField
                            label="Descrição do Grupo Ligado"
                            value={grupo.descricaoGrupo}
                            onChange={(e) => atualizarGrupo(grupoIndex, 'descricaoGrupo', e.target.value, setGruposLigados)}
                            fullWidth
                            required
                            sx={{ mt: 1 }}
                            />
                            <TextField
                            label="Limite Máximo do Grupo Ligado (%)"
                            type="number"
                            value={grupo.limiteGrupo || ''}
                            onChange={(e) => atualizarGrupo(grupoIndex, 'limiteGrupo', e.target.value, setGruposLigados)}
                            fullWidth
                            required
                            sx={{ mt: 2 }}
                            />
                            <Typography variant="subtitle1" mt={2}>Regras</Typography>
                            {grupo.limites.map((limite, limiteIndex) => (
                            <Box key={limiteIndex} display="flex" alignItems="center" gap={2} mt={1}>
                                <TextField
                                label="Descrição"
                                value={limite.descricao}
                                onChange={(e) =>
                                    atualizarLimite(grupoIndex, limiteIndex, 'descricao', e.target.value, setGruposLigados)
                                }
                                fullWidth
                                required
                                />
                                <TextField
                                label="Limite Máximo (%)"
                                type="number"
                                value={limite.vedado ? '' : limite.limite_maximo || ''}
                                onChange={(e) =>
                                    atualizarLimite(grupoIndex, limiteIndex, 'limite_maximo', e.target.value, setGruposLigados)
                                }
                                fullWidth
                                disabled={limite.vedado}
                                />
                                <FormControlLabel
                                control={
                                    <Checkbox
                                    checked={limite.vedado}
                                    onChange={(e) =>
                                        atualizarLimite(grupoIndex, limiteIndex, 'vedado', e.target.checked, setGruposLigados)
                                    }
                                    />
                                }
                                label="Vedado"
                                />
                                <IconButton
                                onClick={() => removerLimiteDoGrupo(grupoIndex, limiteIndex, setGruposLigados)}
                                color="secondary"
                                >
                                <Delete />
                                </IconButton>
                            </Box>
                            ))}
                            <Button
                            onClick={() => adicionarLimiteAoGrupo(grupoIndex, setGruposLigados)}
                            variant="outlined"
                            startIcon={<Add />}
                            sx={{ mt: 2 }}
                            >
                            Adicionar Regra
                            </Button>
                            <Button
                            onClick={() => removerGrupo(grupoIndex, setGruposLigados)}
                            color="secondary"
                            variant="outlined"
                            sx={{ mt: 2 }}
                            >
                            Remover Grupo Ligado
                            </Button>
                        </Box>
                        ))}
                        <Button
                        onClick={() => setGruposLigados([...gruposLigados, { descricaoGrupo: '', limiteGrupo: null, limites: [] }])}
                        variant="contained"
                        sx={{ mt: 3 }}
                        >
                        Adicionar Grupo Ligado
                        </Button>
                    </>
                    
                  ) : tipoAtivo && (
                    <>
                      {/* Conteúdo para outros tipos de ativos */}
                      <Divider sx={{ my: 3 }} />
                      <Typography variant="h6" gutterBottom>Informações do Ativo</Typography>
                      <Box display="flex" flexDirection="column" gap={2}>
                        <Select
                          displayEmpty
                          defaultValue=""
                          fullWidth
                        >
                          <MenuItem value="" disabled>Descrição do Ativo</MenuItem>
                          <MenuItem value="Ativo 1">Ações</MenuItem>
                        </Select>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(e) => setOperaVendido(e.target.checked)}
                            />
                          }
                          label="Opera Vendido?"
                        />
                        {operaVendido && (
                          <TextField
                            label="Código Contraparte"
                            fullWidth
                          />
                        )}
                      </Box>
                    </>
                  )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={onRequestClose} color="error">Cancelar</Button>
                    <Button
                    onClick={handleSave}
                    color="primary"
                    variant="contained"
                    >
                    Salvar
                    </Button>
                </DialogActions>
                </Dialog>
            );
    };

export default CadastroAtivoModal;