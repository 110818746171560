import api from './api';
import {CarteiraDiariaAtivos, CarteiraDiariaPatrimonioLiquido, CarteiraDiariaPassivo, CarteiraDiariaOutrosPassivos, CarteiraDiariaTesouraria } from '../types/carteiraDiaria';


export const getCarteiraAtivos = async (
  pageIndex: number,
  pageSize: number = 100,
  sortBy: any[] = [],
  globalFilter: string = '',
  dataFilter: string = ''  // Adicionando o filtro de data
) => {
  try {
    // Ordenação (se houver)
    const sortParam = sortBy.length
      ? `&ordering=${sortBy[0].desc ? '-' : ''}${sortBy[0].id}` 
      : '';

    // Filtro global (se houver)
    const searchParam = globalFilter ? `&search=${globalFilter}` : '';

    // Filtro de data (se houver)
    const dateParam = dataFilter ? `&data_posicao=${dataFilter}` : '';

    // Montar a URL dinamicamente, adicionando apenas os parâmetros necessários
    const queryString = `/ativos/carteira-diaria-ativos/?page=${pageIndex + 1}&page_size=${pageSize}`
      + `${sortParam}${searchParam}${dateParam}`;

    const response = await api.get<{ results: CarteiraDiariaAtivos[]; count: number }>(
      queryString
    );

    return {
      results: response.data.results,
      totalPages: Math.ceil(response.data.count / pageSize),
    };
  } catch (error) {
    throw error;
  }
};

export const getCarteiraPatrimonioLiquido = async (
  pageIndex: number,
  pageSize: number = 100,
  sortBy: any[] = [],
  globalFilter: string = '',
  dataFilter: string = ''  // Adicionando o filtro de data
) => {
  try {
    const sortParam = sortBy.length > 0 ? `&ordering=${sortBy.map(sort => (sort.desc ? '-' : '') + sort.id).join(',')}` : '';
    const filterParam = globalFilter ? `&search=${globalFilter}` : ''; 
    const dateParam = dataFilter ? `&data_posicao=${dataFilter}` : ''; 

    const queryString = `/ativos/carteira-diaria-patrimonio-liquido/?page=${pageIndex + 1}&page_size=${pageSize}`
      + `${sortParam}${filterParam}${dateParam}`;

    const response = await api.get<{ results: CarteiraDiariaPatrimonioLiquido[], count: number }>(
      queryString
    );

    return {
      results: response.data.results,
      totalPages: Math.ceil(response.data.count / pageSize),
    };
  } catch (error) {
    console.error("Erro ao buscar Carteira Diária Patrimônio Líquido:", error);
    throw error;
  }
};

export const getCarteiraPassivo = async (
  pageIndex: number,
  pageSize: number = 100,
  sortBy: any[] = [],
  globalFilter: string = '',
  dataFilter: string = ''  // Adicionando o filtro de data
) => {
  try {
    const sortParam = sortBy.length > 0 ? `&ordering=${sortBy.map(sort => (sort.desc ? '-' : '') + sort.id).join(',')}` : '';
    const filterParam = globalFilter ? `&search=${globalFilter}` : ''; 
    const dateParam = dataFilter ? `&data_posicao=${dataFilter}` : ''; 

    const queryString = `/ativos/carteira-diaria-passivo/?page=${pageIndex + 1}&page_size=${pageSize}`
      + `${sortParam}${filterParam}${dateParam}`;

    const response = await api.get<{ results: CarteiraDiariaPassivo[], count: number }>(
      queryString
    );
  
    return {
      results: response.data.results,
      totalPages: Math.ceil(response.data.count / pageSize),
    };
  } catch (error) {
    console.error("Erro ao buscar Carteira Diária Passivo:", error);
    throw error;
  }
};

export const getCarteiraOutrosPassivos = async (
  pageIndex: number,
  pageSize: number = 100,
  sortBy: any[] = [],
  globalFilter: string = '',
  dataFilter: string = ''  // Adicionando o filtro de data
) => {
  try {
    const sortParam = sortBy.length > 0 ? `&ordering=${sortBy.map(sort => (sort.desc ? '-' : '') + sort.id).join(',')}` : '';
    const filterParam = globalFilter ? `&search=${globalFilter}` : ''; 
    const dateParam = dataFilter ? `&data_posicao=${dataFilter}` : ''; 

    const queryString = `/ativos/carteira-diaria-outros-passivos/?page=${pageIndex + 1}&page_size=${pageSize}`
      + `${sortParam}${filterParam}${dateParam}`;

    const response = await api.get<{ results: CarteiraDiariaOutrosPassivos[], count: number }>(
      queryString
    );
    
    return {
      results: response.data.results,
      totalPages: Math.ceil(response.data.count / pageSize),
    };
  } catch (error) {
    console.error("Erro ao buscar Carteira Diária Outros Passivos:", error);
    throw error;
  }
};

export const getCarteiraTesouraria = async (
  pageIndex: number,
  pageSize: number = 100,
  sortBy: any[] = [],
  globalFilter: string = '',
  dataFilter: string = ''  // Adicionando o filtro de data
) => {
  try {
    const sortParam = sortBy.length > 0 ? `&ordering=${sortBy.map(sort => (sort.desc ? '-' : '') + sort.id).join(',')}` : '';
    const filterParam = globalFilter ? `&search=${globalFilter}` : ''; 
    const dateParam = dataFilter ? `&data_posicao=${dataFilter}` : ''; 

    const queryString = `/ativos/saldo-tesouraria/?page=${pageIndex + 1}&page_size=${pageSize}`
      + `${sortParam}${filterParam}${dateParam}`;

    const response = await api.get<{ results: CarteiraDiariaTesouraria[], count: number }>(
      queryString
    );
    
    return {
      results: response.data.results,
      totalPages: Math.ceil(response.data.count / pageSize),
    };
  } catch (error) {
    console.error("Erro ao buscar Carteira Diária Outros Passivos:", error);
    throw error;
  }
};


export const updateCarteira = async (carteira: number, id: number, data: any) => {
  try {
    // Realiza a requisição PATCH para atualizar a carteira específica
    const response = await api.patch(`/ativos/${carteira}/${id}/`, data);

    // Retorna os dados da resposta em caso de sucesso
    return response.data;
  } catch (error) {
    // Em caso de erro, lança uma exceção para tratamento no componente
    throw error;
  }
};

export const getCarteiraPorFundoData = async (fundoId: string, dataPosicao: string) => {
  try {
    const response = await api.get(`/ativos/carteira-diaria/fundo/${fundoId}/data/${dataPosicao}/`);
    return response.data; // Retorna os dados obtidos da API
  } catch (error) {
    console.error('Erro ao buscar os dados da carteira:', error);
    throw error; // Lança o erro para ser tratado onde a função for chamada
  }
};