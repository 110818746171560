import React, { useState } from 'react';
import { getResumoDiario } from '../services/ativosService';
import { TextField, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import TableResumoDiario from '../components/TableResumoDiario';
import { format, parseISO } from 'date-fns';

const ResumoDiarioConsulta: React.FC = () => {
  const [dataInicio, setDataInicio] = useState<string>('');
  const [dataFim, setDataFim] = useState<string>('');
  const [resumoDiario, setResumoDiario] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const formatarData = (data: string): string => {
    try {
      return format(parseISO(data), 'dd/MM/yyyy');
    } catch (error) {
      console.error('Erro ao formatar a data:', error);
      return '-';
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
  
    try {
      const data = await getResumoDiario(dataInicio, dataFim);
  
      // Certifique-se de formatar as datas corretamente
      const formattedData = data.map((item: any) => ({
        data_posicao: formatarData(item.data_posicao),// Formata a data
        ativos: item.ativos,
        passivos_outros: item.passivos_outros,
        saldo_tesouraria: item.saldo_tesouraria,
        patrimonio: item.patrimonio,
      }));
  
      setResumoDiario(formattedData); // Define os dados formatados
    } catch (error) {
      console.error('Erro ao buscar o resumo diário:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <h2>Resumo Diário</h2>
      <form onSubmit={handleSubmit} style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
        {/* Input de Data Início */}
        <TextField
          id="dataInicio"
          type="date"
          label="Data Início"
          value={dataInicio}
          onChange={(e) => setDataInicio(e.target.value)}
          required
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
          style={{ minWidth: 200 }}
        />

        {/* Input de Data Fim */}
        <TextField
          id="dataFim"
          type="date"
          label="Data Fim"
          value={dataFim}
          onChange={(e) => setDataFim(e.target.value)}
          required
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
          style={{ minWidth: 200 }}
        />

        {/* Botão de Buscar */}
        <Button type="submit" variant="contained" color="primary" endIcon={<SearchIcon />}>
          Buscar
        </Button>
      </form>

      {isLoading ? (
        <p>Carregando...</p>
      ) : (
        <>
          {resumoDiario.length > 0 ? (
            <TableResumoDiario
              columns={[
                { header: 'Data Posição', accessor: 'data_posicao' },
                { header: 'Ativos', accessor: 'ativos', isCurrency: true },
                { header: 'Passivos', accessor: 'passivos_outros', isCurrency: true },
                { header: 'Saldo Tesouraria', accessor: 'saldo_tesouraria', isCurrency: true },
                { header: 'Patrimônio', accessor: 'patrimonio', isCurrency: true },
              ]}
              data={resumoDiario}
              title="Resumo Diário"
            />
          ) : (
            <p>Nenhum dado encontrado para o período informado.</p>
          )}
        </>
      )}
    </div>
  );
};

export default ResumoDiarioConsulta;
