import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
} from '@mui/material';

interface Regra {
  capitulo: string;
  paragrafo: string;
  inciso: string;
  descricao: string;
  limiteMinimo: number | null;
  limiteMaximo: number | null;
  grupo: string;
}

interface CadastroRegraModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onSave: (regra: Regra) => void;
  regra: Regra | null;
}

const CadastroRegraModal: React.FC<CadastroRegraModalProps> = ({
  isOpen,
  onRequestClose,
  onSave,
  regra,
}) => {
  const [localRegra, setLocalRegra] = useState<Regra>({
    capitulo: '',
    paragrafo: '',
    inciso: '',
    descricao: '',
    limiteMinimo: null,
    limiteMaximo: null,
    grupo: '',
  });

  const handleClose = () => {
    setLocalRegra({
      capitulo: '',
      paragrafo: '',
      inciso: '',
      descricao: '',
      limiteMinimo: null,
      limiteMaximo: null,
      grupo: '',
    });
    onRequestClose();
  };

  useEffect(() => {
    if (regra) {
      setLocalRegra(regra);
    }
  }, [regra]);

  return (
    <Dialog open={isOpen} onClose={onRequestClose} fullWidth maxWidth="md">
      <DialogTitle>{regra ? 'Nova Regra' : 'Editar Regra'}</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 2, // Espaçamento entre os campos
          }}
        >
          <TextField
            label="Capítulo"
            value={localRegra.capitulo}
            onChange={(e) => setLocalRegra({ ...localRegra, capitulo: e.target.value })}
            sx={{ flex: '1 1 calc(33.33% - 16px)' }} // 33% da largura com espaçamento
          />
          <TextField
            label="Parágrafo"
            value={localRegra.paragrafo}
            onChange={(e) => setLocalRegra({ ...localRegra, paragrafo: e.target.value })}
            sx={{ flex: '1 1 calc(33.33% - 16px)' }}
          />
          <TextField
            label="Inciso"
            value={localRegra.inciso}
            onChange={(e) => setLocalRegra({ ...localRegra, inciso: e.target.value })}
            sx={{ flex: '1 1 calc(33.33% - 16px)' }}
          />
          <TextField
            label="Descrição"
            value={localRegra.descricao}
            onChange={(e) => setLocalRegra({ ...localRegra, descricao: e.target.value })}
            sx={{ flex: '1 1 100%' }} // Campo ocupa 100% da largura
            multiline
            rows={3}
          />
          <TextField
            label="Limite Mínimo(%)"
            type="number"
            value={localRegra.limiteMinimo || ''}
            onChange={(e) =>
              setLocalRegra({ ...localRegra, limiteMinimo: Number(e.target.value) || null })
            }
            sx={{ flex: '1 1 calc(50% - 16px)' }} // 50% da largura com espaçamento
          />
          <TextField
            label="Limite Máximo(%)"
            type="number"
            value={localRegra.limiteMaximo || ''}
            onChange={(e) =>
              setLocalRegra({ ...localRegra, limiteMaximo: Number(e.target.value) || null })
            }
            sx={{ flex: '1 1 calc(50% - 16px)' }}
          />
          <TextField
            label="Grupo"
            value={localRegra.grupo}
            onChange={(e) => setLocalRegra({ ...localRegra, grupo: e.target.value })}
            sx={{ flex: '1 1 100%' }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="error">
          Cancelar
        </Button>
        <Button
          onClick={() => {
            onSave(localRegra); // Salva a regra
            handleClose(); // Fecha o modal
          }}
          color="primary"
          variant="contained"
          disabled={!localRegra.capitulo || !localRegra.descricao || !localRegra.grupo}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CadastroRegraModal;
