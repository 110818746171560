import React from 'react';
import { Grid, Card, CardContent, Typography, Box, Divider, List, ListItem, ListItemText } from '@mui/material';
import { PieChart, Pie, Cell, ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';

// Mock Data
const statusMovimentacoesData = [
  { name: 'OK', value: 6, color: '#0D47A1' },
];

const saldoAplicacoesData = [
  { name: 'Divergências', value: 0, color: '#4CAF50' },
];

const aberturaContasData = [
  { name: 'Solicitado', value: 0 },
  { name: 'Em análise', value: 0 },
  { name: 'Pendente', value: 0 },
  { name: 'Finalizado', value: 1 },
];

const CustomLabel = ({
  x,
  y,
  width,
  value,
}: {
  x?: number;
  y?: number;
  width?: number;
  value?: number;
}) => {
  if (!value || value === 0) return null; // Não renderiza o rótulo se o valor for zero

  return (
    <text
      x={x! + width! / 2} // Centraliza no meio da barra
      y={y! + 15} // Ajusta para ficar dentro da barra
      fill="#fff" // Cor branca para destacar dentro da barra
      fontSize={12}
      textAnchor="middle"
    >
      {value}
    </text>
  );
};


const PassivoSection: React.FC = () => {
  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h6" fontWeight="bold" color="#002855" gutterBottom>
        Passivo
      </Typography>
      <Grid container spacing={2}>
        {/* Gráfico Status das Movimentações */}
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={{ height: '100%' }}>
            <CardContent>
              <Typography variant="subtitle1" fontWeight="bold" textAlign="center" gutterBottom>
                Status das Movimentações
              </Typography>
              <Divider sx={{ my: 1 }} />
              <Box display="flex" justifyContent="center" alignItems="center" mt={2} position="relative">
                <ResponsiveContainer width={200} height={200}>
                  <PieChart>
                    <Pie
                      data={statusMovimentacoesData}
                      cx="50%"
                      cy="50%"
                      innerRadius={50}
                      outerRadius={80}
                      paddingAngle={5}
                      dataKey="value"
                    >
                      {statusMovimentacoesData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
                <Box position="absolute" top="50%" left="50%" sx={{ transform: 'translate(-50%, -50%)', textAlign: 'center' }}>
                  <Typography variant="h6" fontWeight="bold">
                    Total
                  </Typography>
                  <Typography variant="h5" fontWeight="bold">
                    6
                  </Typography>
                </Box>
              </Box>
              <List sx={{ mt: 2 }}>
                {statusMovimentacoesData.map((item, index) => (
                  <ListItem key={index} disableGutters>
                    <Box sx={{ width: 12, height: 12, backgroundColor: item.color, borderRadius: '50%', mr: 1 }} />
                    <ListItemText primary={item.name} />
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
        </Grid>

        {/* Gráfico Saldo das Aplicações */}
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={{ height: '100%' }}>
            <CardContent>
              <Typography variant="subtitle1" fontWeight="bold" textAlign="center" gutterBottom>
                Saldo Gerencial das Aplicações
              </Typography>
              <Divider sx={{ my: 1 }} />
              <Box display="flex" justifyContent="center" alignItems="center" mt={2} position="relative">
                <ResponsiveContainer width={200} height={200}>
                  <PieChart>
                    <Pie
                      data={saldoAplicacoesData}
                      cx="50%"
                      cy="50%"
                      innerRadius={50}
                      outerRadius={80}
                      paddingAngle={5}
                      dataKey="value"
                    >
                      {saldoAplicacoesData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
                <Box position="absolute" top="50%" left="50%" sx={{ transform: 'translate(-50%, -50%)', textAlign: 'center' }}>
                  <Typography variant="h6" fontWeight="bold">
                    0
                  </Typography>
                  <Typography variant="subtitle2" fontWeight="bold">
                    Divergências
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Gráfico Abertura de Contas */}
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={{ height: '100%' }}>
            <CardContent>
              <Typography variant="subtitle1" fontWeight="bold" textAlign="center" gutterBottom>
                Abertura de Contas
              </Typography>
              <Divider sx={{ my: 1 }} />
              <Typography variant="body2" textAlign="center" gutterBottom>
                20/11/2024 - 27/11/2024
              </Typography>
              <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                <ResponsiveContainer width="100%" height={150}>
                  <BarChart
                    data={aberturaContasData}
                    margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 10,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis tickCount={21} domain={[0, 'dataMax']} allowDecimals={false} />
                    <Tooltip />
                    <Bar dataKey="value" fill="#8884d8" label={(props) => <CustomLabel {...props} />}>
                      {aberturaContasData.map((entry, index) => (
                        <Cell key={`cell-${index}`} />
                      ))}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PassivoSection;
