import React, { useEffect, useState } from 'react';
import TableAtivos from '../components/TableAtivos';
import { getRendaFixaCorporativo, getSegmentos } from '../services/ativosService';
import { RendaFixaCorporativo, Segmento } from '../types/ativos';
import { ColumnDef, SortingState } from '@tanstack/react-table';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import Modal from '@mui/material/Modal';
import CadastroAtivoForm from '../components/CadastroAtivoForm';
import SnackbarAlert from '../components/SnackbarAlert';

const RendaFixa_Corporativo: React.FC = () => {
  const [rendafixacorporativo, setRendaFixaCorporativo] = useState<RendaFixaCorporativo[]>([]);
  const [segmentos, setSegmentos] = useState<Segmento[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [pageCount, setPageCount] = useState(0);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const [sorting, setSorting] = useState<SortingState>([]); // Estado para ordenação
  const [globalFilter, setGlobalFilter] = useState(''); // Filtro global
  const [isCadastroModalOpen, setIsCadastroModalOpen] = useState<boolean>(false);
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    message: '',
    severity: 'success' as 'success' | 'error',
  });

  // Função para buscar dados paginados do backend
  const fetchData = async (pageIndex: number, pageSize: number) => {
    setLoading(true);
    try {
      // Carrega os dados dos rendafixacorporativo e segmentos de forma paginada
      const [rendafixacorporativoData, segmentosData] = await Promise.all([
        getRendaFixaCorporativo(pageIndex, pageSize),
        getSegmentos(),
      ]);

      setRendaFixaCorporativo(rendafixacorporativoData.results);
      setPageCount(rendafixacorporativoData.totalPages);
      setSegmentos(segmentosData);
    } catch (error) {
      console.error('Erro ao buscar dados', error);
      setSnackbarProps({
        open: true,
        message: 'Erro ao buscar dados',
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(pagination.pageIndex, pagination.pageSize);
  }, [pagination.pageIndex, pagination.pageSize, sorting, globalFilter]); // Atualiza com paginação, ordenação e filtro global

  const handleCadastroSuccess = () => {
    fetchData(pagination.pageIndex, pagination.pageSize);
    setSnackbarProps({
      open: true,
      message: 'Cadastro realizado com sucesso!',
      severity: 'success',
    });
    setIsCadastroModalOpen(false);
  };

  // Função para mapear o segmento ID para o nome
  const getSegmentoNome = (segmentoId: any): string => {
    const segmento = segmentos.find((seg) => seg.id === segmentoId);
    return segmento ? segmento.nome : 'Segmento desconhecido';
  };

  // Define as colunas da tabela
  const columns = React.useMemo<ColumnDef<RendaFixaCorporativo>[]>(() => [
    {
      header: 'Nome',
      accessorKey: 'nome',
    },
    {
      header: 'Código ISIN',
      accessorKey: 'codigo_isin',
    },
    {
      header: 'Segmento',
      accessorFn: (row) => getSegmentoNome(row.segmento),
    },
  ], [segmentos, getSegmentoNome]);

  return (
    <div>
      <h2>Renda Fixa - Corporativo</h2>
      <div style={{ display: 'flex', justifyContent: 'space-between', float: 'right' }}>
        <IconButton onClick={() => setIsCadastroModalOpen(true)} color="primary">
          <AddIcon />
        </IconButton>
      </div>
      <TableAtivos
        columns={columns}
        data={rendafixacorporativo}
        fetchData={fetchData}  // Passa o pageIndex e o pageSize
        pageCount={pageCount}
        pagination={pagination} // Estado de paginação
        setPagination={setPagination} // Permite alterar paginação
        sorting={sorting} // Ordenação
        setSorting={setSorting} // Permite alterar ordenação
        globalFilter={globalFilter} // Filtro global
        setGlobalFilter={setGlobalFilter} // Permite alterar filtro global
        isLoading={loading}
        segmentoNome='renda-fixa-corporativo'
      />
      <Modal open={isCadastroModalOpen} onClose={() => setIsCadastroModalOpen(false)}>
        <div>
          <CadastroAtivoForm
            isOpen={isCadastroModalOpen}
            onRequestClose={() => setIsCadastroModalOpen(false)}
            onCadastroSuccess={handleCadastroSuccess}
            setSnackbarProps={setSnackbarProps}
          />
        </div>
      </Modal>
      <SnackbarAlert
        open={snackbarProps.open}
        message={snackbarProps.message}
        severity={snackbarProps.severity}
        onClose={() => setSnackbarProps({ ...snackbarProps, open: false })}
      />
    </div>
  );
};

export default RendaFixa_Corporativo;
