import React, { useState, useEffect } from 'react';
import { Box, Button, Modal, TextField, Typography, Grid, Divider, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { updatePassivo } from '../../services/passivosService'; // Importe o serviço de passivos

interface EditModalPassivosProps {
  isOpen: boolean;
  onRequestClose: () => void;
  rowData?: any;  // Os dados atuais do passivo que serão editados
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxWidth: '1200px',
  maxHeight: '80vh',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
};

const EditModalPassivos: React.FC<EditModalPassivosProps> = ({ isOpen, onRequestClose, rowData }) => {
  const [formData, setFormData] = useState(rowData || {});

  const CATEGORIA_CHOICES = [
    'Serviços Essenciais',
    'Demais Serviços',
    'Reguladores',
    'Resgates',
  ];

  // Sincroniza o estado do formData com o rowData recebido
  useEffect(() => {
    if (rowData) {
      setFormData(rowData);
    }
  }, [rowData]);

  // Função para lidar com mudanças nos campos de texto
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Função para lidar com mudanças no Select (para campos de escolha)
  const handleSelectChange = (e: SelectChangeEvent, field: string) => {
    setFormData({
      ...formData,
      [field]: e.target.value,
    });
  };

  // Função para submeter os dados editados
  const handleSubmit = async () => {
    try {
      await updatePassivo(formData.id, formData);  // Chama o serviço para atualizar o passivo
      onRequestClose(); // Fecha o modal após a submissão bem-sucedida
    } catch (error) {
      console.error('Erro ao atualizar o passivo:', error);
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={onRequestClose}
      aria-labelledby="edit-modal-title"
      aria-describedby="edit-modal-description"
    >
      <Box sx={style}>
        <Typography id="edit-modal-title" variant="h6" component="h2">
          Editar Passivo
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Informações do Passivo</Typography>
            <Divider sx={{ mb: 2 }} />
          </Grid>

          {/* Campo de Descrição */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Descrição do Passivo"
              name="descricao"
              value={formData.descricao || ''}
              onChange={handleChange}
              margin="normal"
              fullWidth
            />
          </Grid>

          {/* Campo de Conta (ChoiceField) */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Conta</InputLabel>
              <Select
                value={formData.conta || ''}
                onChange={(e) => handleSelectChange(e, 'conta')} // Atualiza a conta selecionada
                label="Conta"
              >
                {CATEGORIA_CHOICES.map((choice) => (
                  <MenuItem key={choice} value={choice}>
                    {choice}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Adicione mais campos conforme a necessidade */}
        </Grid>

        <Box sx={{ mt: 2 }}>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Salvar Alterações
          </Button>
          <Button
            variant="outlined"
            color="error"
            onClick={onRequestClose}
            sx={{ ml: 2 }}
          >
            Cancelar
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default EditModalPassivos;