import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  Typography,
} from '@mui/material';
import { LegislacaoAtivos, DescricaoAtivo } from '../../types/ativos';
import {
  getDescricaoAtivo,
  createDescricaoAtivo,
  getLegislacaoAtivos,
  saveLegislacaoAtivos,
} from '../../services/ativosService';

interface EditModalOutrosAtivosProps {
  isOpen: boolean;
  onRequestClose: () => void;
  rowData: LegislacaoAtivos;
}

const EditModalOutrosAtivos: React.FC<EditModalOutrosAtivosProps> = ({
  isOpen,
  onRequestClose,
  rowData,
}) => {
  const [descricao, setDescricao] = useState(rowData.descricao?.id || '');
  const [opera, setOpera] = useState(rowData.opera || false);
  const [contraparte, setContraparte] = useState(rowData.contraparte || '');
  const [descricaoOptions, setDescricaoOptions] = useState<DescricaoAtivo[]>([]);
  const [novaDescricao, setNovaDescricao] = useState('');
  const [isNewDescricaoDialogOpen, setIsNewDescricaoDialogOpen] = useState(false); // Controle do modal

  // Carrega opções de descrição e dados de legislação do ativo ao abrir o modal
  useEffect(() => {
    if (isOpen) {
      const fetchInitialData = async () => {
        try {
          const descricoes = await getDescricaoAtivo();
          setDescricaoOptions(descricoes);

          // Carrega os dados específicos da legislação se o ID estiver disponível
          const legislacaoData = await getLegislacaoAtivos(rowData.id);
          setDescricao(legislacaoData.descricao || '');
          setOpera(legislacaoData.opera || false);
          setContraparte(legislacaoData.contraparte || '');
        } catch (error) {
          console.error('Erro ao carregar dados:', error);
        }
      };
      fetchInitialData();
    }
  }, [isOpen, rowData.id]);

  // Função para salvar ou atualizar as alterações
  const handleSave = async () => {
    const updatedData = {
      descricao,
      opera,
      contraparte,
    };
  
    try {
      await saveLegislacaoAtivos(rowData.id, updatedData);
      onRequestClose(); // Fecha o modal após salvar
    } catch (error) {
      console.error('Erro ao salvar legislação de ativos:', error);
    }
  };

  // Função para criar uma nova descrição e adicioná-la à lista
  const handleAddDescricao = async () => {
    try {
      const novaDescricaoCriada = await createDescricaoAtivo(novaDescricao);
      setDescricaoOptions([...descricaoOptions, novaDescricaoCriada]);
      setDescricao(novaDescricaoCriada.id); // Define a nova descrição como selecionada
      setNovaDescricao('');
      setIsNewDescricaoDialogOpen(false); // Fecha o modal
    } catch (error) {
      console.error('Erro ao criar nova descrição:', error);
    }
  };

  return (
    <Dialog open={isOpen} onClose={onRequestClose} fullWidth maxWidth="sm">
      <DialogTitle>Editar Legislação Ativo</DialogTitle>
      <DialogContent>
      <Typography variant="subtitle1"> Descrição do ativo </Typography>
        <Select
          label="Descrição do ativo"
          value={descricao}
          onChange={(e) => setDescricao(Number(e.target.value))}
          fullWidth
          displayEmpty
        >
          <MenuItem value="" disabled>Selecione uma descrição</MenuItem>
          {descricaoOptions.map((desc) => (
            <MenuItem key={desc.id} value={desc.id}>{desc.descricao}</MenuItem>
          ))}
          <MenuItem onClick={() => setIsNewDescricaoDialogOpen(true)}>
            + Adicionar nova descrição
          </MenuItem>
        </Select>

        {isNewDescricaoDialogOpen && (
          <Dialog open={isNewDescricaoDialogOpen} onClose={() => setIsNewDescricaoDialogOpen(false)}>
            <DialogTitle>Adicionar Nova Descrição</DialogTitle>
            <DialogContent>
              <TextField
                label="Nova Descrição"
                value={novaDescricao}
                onChange={(e) => setNovaDescricao(e.target.value)}
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setIsNewDescricaoDialogOpen(false)} color="error">Cancelar</Button>
              <Button onClick={handleAddDescricao} color="primary" variant="contained">
                Adicionar
              </Button>
            </DialogActions>
          </Dialog>
        )}
         
        <Typography variant="body1"> Opera vendido ?</Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={opera}
              onChange={(e) => setOpera(e.target.checked)}
              color="primary"
            />
          }
          label="Sim"
        />

        {opera && (
          <TextField
            label="Código da Contraparte"
            value={contraparte}
            onChange={(e) => setContraparte(e.target.value)}
            fullWidth
            margin="normal"
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onRequestClose} color="error">Cancelar</Button>
        <Button onClick={handleSave} color="primary" variant="contained">
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditModalOutrosAtivos;
