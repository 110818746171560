import React, { useState } from 'react';
import {
  useReactTable,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
} from '@tanstack/react-table';
import {
  TextField,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CadastroAtivoModal from '../components/modals/CadastroAtivoModal'; // Importação do modal

interface Ativo {
  nome: string;
  codigo: string;
  tipoAtivo: string;
  grupos: { descricao: string; limite_maximo: number | null }[];
}

const CadastroAtivos: React.FC = () => {
  const [data, setData] = useState<Ativo[]>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const [sorting, setSorting] = useState<SortingState>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Colunas da tabela
  const columns: ColumnDef<Ativo>[] = [
    { accessorKey: 'nome', header: 'Nome' },
    { accessorKey: 'codigo', header: 'Código' },
    { accessorKey: 'tipoAtivo', header: 'Tipo Ativo' },
    {
      id: 'acoes',
      header: 'Ações',
      cell: ({ row }) => (
        <div style={{ display: 'flex', gap: '8px' }}>
          <VisibilityIcon
            style={{ cursor: 'pointer' }}
            onClick={() => handleView(row.original)}
          />
          <EditIcon
            style={{ cursor: 'pointer' }}
            onClick={() => handleEdit(row.original)}
          />
          <DeleteIcon
            style={{ cursor: 'pointer', color:'red' }}
          />
        </div>
      ),
    },
  ];

  const table = useReactTable({
    data,
    columns,
    state: {
      pagination,
      sorting,
      globalFilter,
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const handleView = (ativo: Ativo) => {
    console.log('Visualizando:', ativo);
  };

  const handleEdit = (ativo: Ativo) => {
    console.log('Editando:', ativo);
  };

  const handleAddAtivo = (novoAtivo: Ativo) => {
    setData((prevData) => [...prevData, novoAtivo]);
    setIsModalOpen(false);
  };

  return (
    <div>
      <h2>Cadastro Ativos</h2>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
        <TextField
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
          placeholder="Buscar na tabela..."
          variant="outlined"
          size="small"
          style={{ width: '300px' }}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setIsModalOpen(true)}
        >
          Novo Ativo
        </Button>
      </div>

      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                  style={{
                    cursor: header.column.getCanSort() ? 'pointer' : 'default',
                  }}
                >
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      <div style={{ display: 'flex', marginTop: '10px', alignItems: 'center' }}>
        <IconButton
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
          color="primary"
        >
          <NavigateBeforeIcon />
        </IconButton>
        <span>
          Página{' '}
          <strong>
            {table.getState().pagination.pageIndex + 1} de {table.getPageCount()}
          </strong>
        </span>
        <IconButton
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
          color="primary"
        >
          <NavigateNextIcon />
        </IconButton>

        <FormControl variant="outlined" size="small" style={{ marginLeft: '10px' }}>
          <InputLabel id="page-size-label">Itens por página</InputLabel>
          <Select
            labelId="page-size-label"
            value={pagination.pageSize}
            onChange={(e) =>
              setPagination((prev) => ({
                ...prev,
                pageSize: Number(e.target.value),
              }))
            }
            label="Itens por página"
          >
            {[10, 20, 30, 40, 50].map((size) => (
              <MenuItem key={size} value={size}>
                {size}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      {/* Modal de cadastro de ativo */}
      <CadastroAtivoModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        onSave={handleAddAtivo}
      />
    </div>
  );
};

export default CadastroAtivos;
