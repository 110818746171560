import React, { useState } from 'react';
import {
  useReactTable,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  getFilteredRowModel,
} from '@tanstack/react-table';
import TextField from '@mui/material/TextField';
import Spinner from './Spinner';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import VisibilityIcon from '@mui/icons-material/Visibility';
import IconButton from '@mui/material/IconButton';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import './Table.css';
import DetailModalCarteiras from './modals/DetailModalCarteira';
import EditModalCarteiras from './modals/EditModalCarteira';

interface TableProps {
  columns: ColumnDef<any>[]; 
  data: any[]; 
  fetchData: (pageIndex: number, pageSize: number, sortBy: SortingState, globalFilter: string, dataFilter: string) => void;
  pageCount: number;
  pagination: { pageIndex: number; pageSize: number };
  setPagination: React.Dispatch<React.SetStateAction<{ pageIndex: number; pageSize: number }>>;
  sorting: SortingState;
  setSorting: React.Dispatch<React.SetStateAction<SortingState>>;
  isLoading: boolean;
  globalFilter: string;
  setGlobalFilter: React.Dispatch<React.SetStateAction<string>>;
  tipoCarteira?: any;
}

const TableCarteiraDiaria: React.FC<TableProps> = ({
  columns,
  data,
  fetchData,
  pageCount,
  pagination,
  setPagination,
  sorting,
  setSorting,
  isLoading,
  globalFilter,
  setGlobalFilter,
  tipoCarteira
}) => {
  // Estados locais para controlar os modais
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentRowData, setCurrentRowData] = useState<any>(null);
  const [dataFilter, setDataFilter] = useState(''); // Estado para armazenar o filtro de data

  // Função para formatar os números no padrão pt-BR
  const formatNumber = (value: number) => {
    if (typeof value !== 'number') return value;
    return value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 7 });
  };

  // Configura a tabela
  const table = useReactTable({
    data,
    columns,
    pageCount,
    manualPagination: true,
    manualSorting: true,
    state: {
      sorting,
      pagination,
      globalFilter,
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  // Chama fetchData sempre que houver mudanças na paginação, ordenação, globalFilter ou dataFilter
  React.useEffect(() => {
    fetchData(pagination.pageIndex, pagination.pageSize, sorting, globalFilter, dataFilter);
  }, [pagination.pageIndex, pagination.pageSize, sorting, globalFilter, dataFilter]);

  const getSortingIcon = (header: any) => {
    if (!header.column.getCanSort()) return null;

    return header.column.getIsSorted() === 'asc' ? (
      <KeyboardArrowUpIcon />
    ) : header.column.getIsSorted() === 'desc' ? (
      <KeyboardArrowDownIcon />
    ) : (
      <UnfoldMoreIcon />
    );
  };

  const handleOpenDetalhesModal = (rowData: any) => {
    setCurrentRowData(rowData);
    setIsDetailModalOpen(true);
  };

  const handleOpenEditarModal = (rowData: any) => {
    setCurrentRowData(rowData);
    setIsEditModalOpen(true);
  };

  const handleDataUpdate = () => {
    fetchData(pagination.pageIndex, pagination.pageSize, sorting, globalFilter, dataFilter);
  };

  return (
    <>
      <div style={{ marginBottom: '10px' }}>
        <TextField
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
          placeholder="Buscar na tabela..."
          variant="outlined"  
          size="small"
          style={{ width: '300px' }}
        />
        <TextField
          type="date"
          value={dataFilter}
          onChange={(e) => setDataFilter(e.target.value)}
          variant="outlined"
          size="small"
          label="Filtrar por Data"
          InputLabelProps={{
            shrink: true, // Mantém o label acima do valor inserido
          }}
          style={{ minWidth: 200, marginLeft: '10px' }}
        />
      </div>

      {isLoading ? (
        <Spinner />
      ) : (
        <table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                    style={{ cursor: header.column.getCanSort() ? 'pointer' : 'default' }}
                  >
                    {header.isPlaceholder ? null : (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {getSortingIcon(header)}
                      </div>
                    )}
                  </th>
                ))}
                <th>Ações</th>
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {typeof cell.getValue() === 'number' 
                      ? formatNumber(cell.getValue() as number) 
                      : flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
                <td>
                  <VisibilityIcon
                    onClick={() => handleOpenDetalhesModal(row.original)}
                    style={{ cursor: 'pointer', marginRight: '10px' }}
                  />
                  <EditIcon
                    onClick={() => handleOpenEditarModal(row.original)}
                    style={{ cursor: 'pointer' }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <div style={{display: 'flex', marginTop: '10px'}}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        {/* Botão de página anterior */}
          <IconButton 
            onClick={() => table.previousPage()} 
            disabled={!table.getCanPreviousPage()}
            color="primary"
          >
            <NavigateBeforeIcon />
          </IconButton>

          {/* Indicador da página atual */}
          <span>
            Página{' '}
            <strong>
              {table.getState().pagination.pageIndex + 1} de {table.getPageCount()}
            </strong>
          </span>

          {/* Botão de próxima página */}
          <IconButton 
            onClick={() => table.nextPage()} 
            disabled={!table.getCanNextPage()}
            color="primary"
          >
            <NavigateNextIcon />
          </IconButton>
        </div>
        <FormControl variant="outlined" size="small" style={{ minWidth: 120 }}>
          <InputLabel id="page-size-label">Itens por página</InputLabel>
          <Select
            labelId="page-size-label"
            value={pagination.pageSize}
            onChange={(e) => setPagination({ ...pagination, pageSize: Number(e.target.value) })}
            label="Itens por página"
          >
            {[10, 20, 30, 40, 50].map((size) => (
              <MenuItem key={size} value={size}>
                Mostrar {size}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      {/* Modais */}
      {isDetailModalOpen && (
        <DetailModalCarteiras
          isOpen={isDetailModalOpen}
          onRequestClose={() => setIsDetailModalOpen(false)}
          rowData={currentRowData}
          tipoCarteira={tipoCarteira}
        />
      )}

      {isEditModalOpen && (
        <EditModalCarteiras
          isOpen={isEditModalOpen}
          onRequestClose={() => {
            setIsEditModalOpen(false);
            handleDataUpdate();
          }}
          rowData={currentRowData}
          tipoCarteira={tipoCarteira}
        />
      )}
    </>
  );
};

export default TableCarteiraDiaria;
