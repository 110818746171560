import api from './api';  
import { Notification } from '../types/notification';

interface NotificationsResponse {
  count: number;
  results: Notification[];
}
export const fetchNotifications = async (): Promise<NotificationsResponse> => {
  try {
    const response = await api.get('/core/notificacoes');
    return response.data; // Retorna o `count` e as `results` (notificações)
  } catch (error) {
    console.error('Erro ao buscar notificações:', error);
    throw error;
  }
};