import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import logo from '../../assets/img/logo.png';
import { getTiposAtivo, getNacionalidades, getBenchmarks, getStatus, getTributacoes, getCondominios, getDistribuicoes, getSegmentos } from '../../services/ativosService'; 
import { getFundosGerenciados } from '../../services/fundosGerenciadosService'; 

interface DetailModalAtivosProps {
  isOpen: boolean;
  onRequestClose: () => void;
  rowData?: any;
  segmentoNome?: string;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxWidth: '1500px',
  maxHeight: '80vh',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
};

const logoStyle = {
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: '20px',
  width: '160px',
};
const renderSection = (fields: { label: any, value: any }[]) => (
  <>
    <Typography variant="h6">
      <strong>Detalhes do ativo</strong>
    </Typography>
    <Divider sx={{ my: 1 }} />
    <Grid container spacing={2} sx={{ mb: 4 }}>
      {fields.map((field, index) => (
        <Grid item xs={12} sm={4} key={index}>
          <Typography variant="body2" gutterBottom>
            <strong>{field.label}:</strong> {field.value || 'N/A'}
          </Typography>
        </Grid>
      ))}
    </Grid>
  </>
);
const DetailModalAtivos: React.FC<DetailModalAtivosProps> = ({ isOpen, onRequestClose, rowData, segmentoNome }) => {
  const [tiposAtivo, setTiposAtivo] = useState<{ id: number; nome: string }[]>([]);
  const [nacionalidades, setNacionalidades] = useState<{ id: number; nome: string }[]>([]);
  const [benchmarks, setBenchmarks] = useState<{ id: number; nome: string }[]>([]);
  const [statusList, setStatusList] = useState<{ id: number; nome: string }[]>([]);
  const [tributacoes, setTributacoes] = useState<{ id: number; tipo: string }[]>([]);
  const [condominios, setCondominios] = useState<{ id: number; nome: string }[]>([]);
  const [distribuicoes, setDistribuicoes] = useState<{ id: number; nome: string }[]>([]);
  const [fundosGerenciados, setFundosGerenciados] = useState<{ id: number; fantasia: string }[]>([]);
  const [segmentos, setSegmentos] = useState<{ id: number; nome: string }[]>([]);

  useEffect(() => {
    if (isOpen) {
      getTiposAtivo().then(setTiposAtivo);
      getNacionalidades().then(setNacionalidades);
      getBenchmarks().then(setBenchmarks);
      getStatus().then(setStatusList);
      getTributacoes().then(setTributacoes);
      getCondominios().then(setCondominios);
      getDistribuicoes().then(setDistribuicoes);
      getFundosGerenciados().then(setFundosGerenciados);
      getSegmentos().then(setSegmentos);
    }
  }, [isOpen]);

  const getNomesFundosByIds = (ids: number[], list: { id: number; fantasia: string }[]) => {
    return ids.map(id => {
      const fundo = list.find(f => f.id === id);
      return fundo ? fundo.fantasia : 'Fundo desconhecido';
    }).join(', ');
  };

  const getNomeById = (id: number, list: { id: number; [key: string]: any }[], key: string = 'nome') => {
    const item = list.find(el => el.id === id);
    return item ? item[key] : 'Desconhecido';
  };
  const getFieldsForSegmento = () => {
    switch (segmentoNome) {
      case 'fundo':
        return [
          { label: 'Nome', value: rowData?.nome },
          { label: 'CNPJ', value: rowData?.cnpj },
          { label: 'Razão Social', value: rowData?.razao_social },
          { label: 'Anbima', value: rowData?.anbima },
          { label: 'CVM', value: rowData?.cvm },
          { label: 'Classificação CVM', value: rowData?.classificacao_cvm },
          { label: 'Classificação Anbima', value: rowData?.classificacao_anbima },
          { label: 'Risco Gestora', value: rowData?.risco_gestora },
          { label: 'Número Risco Mont', value: rowData?.numero_risco_mont },
          { label: 'Taxa de Administração', value: rowData?.taxa_administracao },
          { label: 'Taxa de Performance', value: rowData?.taxa_performance },
          { label: 'Benchmark de Referência', value: rowData?.referencia_benchmark },
          { label: 'Taxa de Custódia', value: rowData?.taxa_custodia },
          { label: 'Data Primeiro Aporte', value: rowData?.data_primeiro_aporte },
          { label: 'Descrição da Carteira', value: rowData?.descricao_carteira },
          { label: 'Código Mont', value: rowData?.codigo_mont },
          { label: 'Cotização Aplicação', value: rowData?.cotizacao_aplicacao },
          { label: 'Captação', value: rowData?.captacao },
          { label: 'Cotização Resgate', value: rowData?.cotizacao_resgate },
          { label: 'Liquidação Resgate', value: rowData?.liquidacao_resgate },
          { label: 'Aplicação Inicial Mínima', value: rowData?.aplicacao_inicial_minima },
          { label: 'Movimentação Mínima', value: rowData?.movimentacao_minima },
          { label: 'Saldo Mínimo', value: rowData?.saldo_minimo },
          { label: 'Paga Proventos', value: rowData?.paga_proventos ? 'Sim' : 'Não' },
          { label: 'Periodicidade', value: rowData?.periodicidade },
          { label: 'Com Subclasse', value: rowData?.com_subclasse ? 'Sim' : 'Não' },
          { label: 'Data Resgate', value: rowData?.data_resgate },
          { label: 'Data Liquidação', value: rowData?.data_liquidacao },
          { label: 'Aplicação Mínima', value: rowData?.aplicacao_minima },
          { label: 'Código BTG Digital', value: rowData?.codigo_btg_digital },
          { label: 'Banco', value: rowData?.banco },
          { label: 'Número do Banco', value: rowData?.numero_banco },
          { label: 'Agência', value: rowData?.agencia },
          { label: 'Conta Bancária', value: rowData?.conta_bancaria },
          { label: 'Código CETIP Mnemonico', value: rowData?.codigo_cetip_mnemonico },
          { label: 'Conta CETIP Contraparte', value: rowData?.conta_cetip_contraparte },
          { label: 'Segmento', value: getNomeById(rowData?.segmento, segmentos, 'nome') },
          { label: 'Tipo Ativo', value: getNomeById(rowData?.tipo_ativo, tiposAtivo, 'nome') },
          { label: 'Nacionalidade', value: getNomeById(rowData?.nacionalidade, nacionalidades, 'origem') },
          { label: 'Benchmark', value: getNomeById(rowData?.benchmark, benchmarks, 'nome') },
          { label: 'Status', value: getNomeById(rowData?.status, statusList, 'estado') },
          { label: 'Tributação', value: getNomeById(rowData?.tributacao, tributacoes, 'tipo') }, // Usando 'tipo' para tributacoes
          { label: 'Condomínio', value: getNomeById(rowData?.condominio, condominios, 'tipo') },
          { label: 'Distribuição', value: getNomeById(rowData?.distribuicao, distribuicoes, 'tipo') },
          { label: 'Fundos associados', value: getNomesFundosByIds(rowData?.fundos, fundosGerenciados) }
        ];
      case 'renda-fixa-titulos-publicos':
        return [
          { label: 'Nome', value: rowData?.nome },
          { label: 'Código ISIN', value: rowData?.codigo_isin },
          { label: 'Indexador', value: rowData?.indexador },
          { label: 'Data de Vencimento', value: rowData?.data_vencimento },
          { label: 'Taxa', value: rowData?.taxa },
          { label: 'Segmento', value: getNomeById(rowData?.segmento, segmentos, 'nome') },
          { label: 'Tipo Ativo', value: getNomeById(rowData?.tipo_ativo, tiposAtivo, 'nome') },
          { label: 'Fundos', value: getNomesFundosByIds(rowData?.fundos, fundosGerenciados) },
        ];
      case 'compromissada':
        return [
          { label: 'Nome', value: rowData?.nome },
          { label: 'ISIN', value: rowData?.codigo_isin },
          { label: 'Indexador', value: rowData?.indexador },
          { label: 'Data de Vencimento', value: rowData?.data_vencimento },
          { label: 'Taxa', value: rowData?.taxa },
          { label: 'Segmento', value: getNomeById(rowData?.segmento, segmentos, 'nome') },
          { label: 'Tipo Ativo', value: getNomeById(rowData?.tipo_ativo, tiposAtivo, 'nome') },
          { label: 'Fundos', value: getNomesFundosByIds(rowData?.fundos, fundosGerenciados) },
        ];
      case 'renda-fixa-bancario':
        return [
          { label: 'Nome', value: rowData?.nome },
          { label: 'ISIN', value: rowData?.codigo_isin },
          { label: 'Nome Título', value: rowData?.nome_titulo },
          { label: 'Instituição Emitente', value: rowData?.instituicao_emitente },
          { label: 'Descrição', value: rowData?.descricao_ativo },
          { label: 'Indexador', value: rowData?.indexador },
          { label: 'Data de Vencimento', value: rowData?.data_vencimento },
          { label: 'Taxa', value: rowData?.taxa },
          { label: 'Segmento', value: getNomeById(rowData?.segmento, segmentos, 'nome') },
          { label: 'Tipo Ativo', value: getNomeById(rowData?.tipo_ativo, tiposAtivo, 'nome') },
          { label: 'Fundos', value: getNomesFundosByIds(rowData?.fundos, fundosGerenciados) },
        ];
      case 'renda-fixa-corporativo':
          return [
            { label: 'Nome', value: rowData?.nome },
            { label: 'ISIN', value: rowData?.codigo_isin },
            { label: 'Ticker B3', value: rowData?.ticker_b3 },
            { label: 'Nome Título', value: rowData?.nome_titulo },
            { label: 'Instituição Emitente', value: rowData?.instituicao_emitente },
            { label: 'Descrição', value: rowData?.descricao_ativo },
            { label: 'Indexador', value: rowData?.indexador },
            { label: 'Data de Vencimento', value: rowData?.data_vencimento },
            { label: 'Taxa', value: rowData?.taxa },
            { label: 'Segmento', value: getNomeById(rowData?.segmento, segmentos, 'nome') },
            { label: 'Tipo Ativo', value: getNomeById(rowData?.tipo_ativo, tiposAtivo, 'nome') },
            { label: 'Fundos', value: getNomesFundosByIds(rowData?.fundos, fundosGerenciados) },
          ];
        case 'renda-fixa-securitizado':
          return [
            { label: 'Nome', value: rowData?.nome },
            { label: 'ISIN', value: rowData?.codigo_isin },
            { label: 'Ticker B3', value: rowData?.ticker_b3 },
            { label: 'Nome Título', value: rowData?.nome_titulo },
            { label: 'Instituição Emitente', value: rowData?.instituicao_emitente },
            { label: 'Característica', value: rowData?.caracteristica },
            { label: 'Descrição', value: rowData?.descricao_ativo },
            { label: 'Indexador', value: rowData?.indexador },
            { label: 'Data de Vencimento', value: rowData?.data_vencimento },
            { label: 'Taxa', value: rowData?.taxa },
            { label: 'Segmento', value: getNomeById(rowData?.segmento, segmentos, 'nome') },
            { label: 'Tipo Ativo', value: getNomeById(rowData?.tipo_ativo, tiposAtivo, 'nome') },
            { label: 'Fundos', value: getNomesFundosByIds(rowData?.fundos, fundosGerenciados) },
          ];
      case 'renda-variavel':
        return [
          { label: 'Nome', value: rowData?.nome },
          { label: 'ISIN', value: rowData?.codigo_isin },
          { label: 'Ticker B3', value: rowData?.ticker_b3 },
          { label: 'Descrição Ticker B3', value: rowData?.descricao_ticker },
          { label: 'Empresa Nome CVM', value: rowData?.empresa_nome_cvm },
          { label: 'Segmento', value: getNomeById(rowData?.segmento, segmentos, 'nome') },
          { label: 'Tipo Ativo', value: getNomeById(rowData?.tipo_ativo, tiposAtivo, 'nome') },
          { label: 'Fundos', value: getNomesFundosByIds(rowData?.fundos, fundosGerenciados) },
        ];
      case 'adr':
        return [
          { label: 'Nome', value: rowData?.nome },
          { label: 'Papel', value: rowData?.papel },
          { label: 'Segmento', value: getNomeById(rowData?.segmento, segmentos, 'nome') },
          { label: 'Tipo Ativo', value: getNomeById(rowData?.tipo_ativo, tiposAtivo, 'nome') },
          { label: 'Fundos', value: getNomesFundosByIds(rowData?.fundos, fundosGerenciados) },
        ];
      default:
        return [{ label: 'Informações', value: 'Não disponíveis' }];
    }
  };

  return (
    <Modal open={isOpen} onClose={onRequestClose}>
      <Box sx={style}>
        <img src={logo} alt="Logo" style={logoStyle} />
        
        {renderSection(getFieldsForSegmento())}

        <Button onClick={onRequestClose} variant="contained" sx={{ mt: 2 }}>
          Fechar
        </Button>
      </Box>
    </Modal>
  );
};

export default DetailModalAtivos;