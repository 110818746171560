import React, { useState } from 'react';
import {
  Box,
  Typography,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  TextField,
} from '@mui/material';
interface HistoricoModalProps {
  fundo: string; // Nome do fundo selecionado
  onClose: () => void; // Função para fechar o modal
}

const HistoricoModal: React.FC<HistoricoModalProps> = ({ fundo, onClose }) => {
  const [filters, setFilters] = useState({
      dataOperacao: '',
      dataLancamento: '',
      dataCotizacao: '',
      dataLiquidacao: '',
      movimentacao: '',
    });
  const rows = [
    {
      "codLoc": "545596",
      "n": 6,
      "dataLancamento": "qui 31/10/24",
      "dataOperacao": "qui 31/10/24",
      "dataCotizacao": "sex 1/11/24",
      "dataLiquidacao": "qui 31/10/24",
      "plReferencia": "ter 29/10/24",
      "cnpj": "08.830.947/0001-31",
      "codBTG": "909599",
      "movimentacao": "GUEPARDO INST FIC DE FI DE AÇÕES",
      "invest": "Ger",
      "captacao": "Aprovado",
      "cotzAplic": 1,
      "pagam": 2,
      "cotiz": 30,
      "aplicMin": "R$ 5.000,00",
      "movMin": "R$ 1.000,00",
      "horario": "14h10",
      "plTotal": "R$ 60.000,00",
      "x": "X",
      "movAplRes": "A",
      "aplicFundos": "R$ 60.000,00",
      "resgFund": "R$ 0,00",
      "rFundos": "R$ 2.648.298,60",
      "percPL": "33,60%"
    },
    {
      "codLoc": "345601",
      "n": 3,
      "dataLancamento": "ter 5/11/24",
      "dataOperacao": "ter 5/11/24",
      "dataCotizacao": "ter 5/11/24",
      "dataLiquidacao": "ter 5/11/24",
      "plReferencia": "qui 31/10/24",
      "cnpj": "14.438.229/0001-17",
      "codBTG": "2345926",
      "movimentacao": "CHARLES RIVER FI DE AÇÕES",
      "invest": "Ger",
      "captacao": "Aprovado",
      "cotzAplic": 0,
      "pagam": 2,
      "cotiz": 30,
      "aplicMin": "R$ 5.000,00",
      "movMin": "R$ 1.000,00",
      "horario": "14h40",
      "plTotal": "R$ 25.000,00",
      "x": "X",
      "movAplRes": "A",
      "aplicFundos": "R$ 25.000,00",
      "resgFund": "R$ 0,00",
      "rFundos": "R$ 3.070.292,02",
      "percPL": "38,90%"
    },
    {
      "codLoc": "445601",
      "n": 6,
      "dataLancamento": "ter 5/11/24",
      "dataOperacao": "ter 5/11/24",
      "dataCotizacao": "qua 6/11/24",
      "dataLiquidacao": "ter 5/11/24",
      "plReferencia": "qui 31/10/24",
      "cnpj": "08.830.947/0001-31",
      "codBTG": "909599",
      "movimentacao": "GUEPARDO INST FIC DE FI DE AÇÕES",
      "invest": "Ger",
      "captacao": "Aprovado",
      "cotzAplic": 1,
      "pagam": 2,
      "cotiz": 30,
      "aplicMin": "R$ 5.000,00",
      "movMin": "R$ 1.000,00",
      "horario": "14h10",
      "plTotal": "R$ 35.000,00",
      "x": "X",
      "movAplRes": "A",
      "aplicFundos": "R$ 35.000,00",
      "resgFund": "R$ 0,00",
      "rFundos": "R$ 2.660.370,04",
      "percPL": "33,70%"
    },
    {
      "codLoc": "445604",
      "n": 3,
      "dataLancamento": "sex 8/11/24",
      "dataOperacao": "sex 8/11/24",
      "dataCotizacao": "sex 8/11/24",
      "dataLiquidacao": "sex 8/11/24",
      "plReferencia": "ter 5/11/24",
      "cnpj": "14.438.229/0001-17",
      "codBTG": "2345926",
      "movimentacao": "CHARLES RIVER FI DE AÇÕES",
      "invest": "Ger",
      "captacao": "Aprovado",
      "cotzAplic": 0,
      "pagam": 2,
      "cotiz": 30,
      "aplicMin": "R$ 5.000,00",
      "movMin": "R$ 1.000,00",
      "horario": "14h40",
      "plTotal": "R$ 35.981,38",
      "x": "X",
      "movAplRes": "A",
      "aplicFundos": "R$ 35.981,00",
      "resgFund": "R$ 0,00",
      "rFundos": "R$ 3.140.886,56",
      "percPL": "38,80%"
    },
    {
      "codLoc": "545604",
      "n": 6,
      "dataLancamento": "sex 8/11/24",
      "dataOperacao": "sex 8/11/24",
      "dataCotizacao": "seg 11/11/24",
      "dataLiquidacao": "sex 8/11/24",
      "plReferencia": "ter 5/11/24",
      "cnpj": "08.830.947/0001-31",
      "codBTG": "909599",
      "movimentacao": "GUEPARDO INST FIC DE FI DE AÇÕES",
      "invest": "Ger",
      "captacao": "Aprovado",
      "cotzAplic": 1,
      "pagam": 2,
      "cotiz": 30,
      "aplicMin": "R$ 5.000,00",
      "movMin": "R$ 1.000,00",
      "horario": "14h10",
      "plTotal": "R$ 51.087,54",
      "x": "X",
      "movAplRes": "A",
      "aplicFundos": "R$ 51.087,00",
      "resgFund": "R$ 0,00",
      "rFundos": "R$ 2.773.858,98",
      "percPL": "34,30%"
    }
  ];
  

  const filteredRows = rows.filter((row) => {
    const { dataOperacao, dataLancamento, dataCotizacao, dataLiquidacao, movimentacao } = filters;
    return (
      (!dataOperacao || row.dataOperacao === dataOperacao) &&
      (!dataLancamento || row.dataLancamento === dataLancamento) &&
      (!dataCotizacao || row.dataCotizacao === dataCotizacao) &&
      (!dataLiquidacao || row.dataLiquidacao === dataLiquidacao) &&
      (!movimentacao || row.movimentacao.toLowerCase().includes(movimentacao.toLowerCase()))
    );
  });

  // Atualiza o estado dos filtros
  const handleFilterChange = (field: string, value: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: value,
    }));
  };

  // Limpa todos os filtros
  const clearFilters = () => {
    setFilters({
      dataOperacao: '',
      dataLancamento: '',
      dataCotizacao: '',
      dataLiquidacao: '',
      movimentacao: '',
    });
  };

  return (
    <Modal
      open={true}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          maxHeight: '90%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 3,
          overflow: 'auto',
          borderRadius: 2,
        }}
      >
        <Typography id="modal-title" variant="h6" gutterBottom>
          Histórico do Fundo: {fundo}
        </Typography>

        {/* Filtros */}
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            marginBottom: 2,
            flexWrap: 'wrap',
          }}
        >
          <TextField
            label="Data Operação"
            type="date"
            value={filters.dataOperacao}
            onChange={(e) => handleFilterChange('dataOperacao', e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="Data Lançamento"
            type="date"
            value={filters.dataLancamento}
            onChange={(e) => handleFilterChange('dataLancamento', e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="Data Cotização"
            type="date"
            value={filters.dataCotizacao}
            onChange={(e) => handleFilterChange('dataCotizacao', e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="Data Liquidação"
            type="date"
            value={filters.dataLiquidacao}
            onChange={(e) => handleFilterChange('dataLiquidacao', e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="Movimentação"
            type="text"
            value={filters.movimentacao}
            onChange={(e) => handleFilterChange('movimentacao', e.target.value)}
            placeholder="Digite o nome do fundo"
          />
          <Button variant="outlined" onClick={clearFilters}>
            Limpar Filtros
          </Button>
        </Box>

        {/* Tabela */}
        <Box sx={{ overflowX: 'auto', maxWidth: '100%' }}>
          <Table
            sx={{
              minWidth: '1500px',
              tableLayout: 'fixed',
              borderCollapse: 'collapse',
            }}
          >
            <colgroup>
              <col style={{ width: '100px' }} /> {/* Cód.Loc */}
              <col style={{ width: '50px' }} /> {/* N */}
              <col style={{ width: '150px' }} /> {/* Data Lançamento */}
              <col style={{ width: '150px' }} /> {/* Data Operação */}
              <col style={{ width: '150px' }} /> {/* Data Cotização */}
              <col style={{ width: '150px' }} /> {/* Data Liquidação */}
              <col style={{ width: '150px' }} /> {/* PL Referência */}
              <col style={{ width: '200px' }} /> {/* CNPJ */}
              <col style={{ width: '150px' }} /> {/* CÓD BTG */}
              <col style={{ width: '200px' }} /> {/* Movimentação */}
              <col style={{ width: '80px' }} /> {/* Invest */}
              <col style={{ width: '100px' }} /> {/* Captação */}
              <col style={{ width: '80px' }} /> {/* Cotz Aplic */}
              <col style={{ width: '80px' }} /> {/* Pagam */}
              <col style={{ width: '80px' }} /> {/* Cotiz */}
              <col style={{ width: '120px' }} /> {/* Aplic Mín */}
              <col style={{ width: '120px' }} /> {/* Mov Mín */}
              <col style={{ width: '80px' }} /> {/* Hor */}
              <col style={{ width: '150px' }} /> {/* PL Total R$ */}
              <col style={{ width: '50px' }} /> {/* X */}
              <col style={{ width: '120px' }} /> {/* Mov Apl / Res */}
              <col style={{ width: '150px' }} /> {/* Aplic Fundos */}
              <col style={{ width: '150px' }} /> {/* Resg Fund */}
              <col style={{ width: '150px' }} /> {/* R$ Fundos */}
              <col style={{ width: '100px' }} /> {/* % PL */}
            </colgroup>
            <TableHead>
              <TableRow>
                {[
                  'Cód.Loc',
                  'N',
                  'Data Lançamento',
                  'Data Operação',
                  'Data Cotização',
                  'Data Liquidação',
                  'PL Referência',
                  'CNPJ',
                  'CÓD BTG',
                  'Movimentação',
                  'Invest',
                  'Status',
                  'Cotz Aplic',
                  'Pagam',
                  'Cotiz',
                  'Aplic Mín',
                  'Mov Mín',
                  'Hor',
                  'PL Total R$',
                  'X',
                  'Mov Apl / Res',
                  'Aplic Fundos',
                  'Resg Fund',
                  'R$ Fundos',
                  '% PL',
                ].map((header) => (
                  <TableCell
                    key={header}
                    sx={{
                      backgroundColor: '#002855',
                      color: '#ffffff',
                      fontWeight: 'bold',
                      textAlign: 'left',
                      padding: '10px',
                      border: '1px solid #ddd',
                    }}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredRows.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#ffffff',
                    '&:hover': { backgroundColor: '#e0f7fa' },
                  }}
                >
                  <TableCell>{row.codLoc}</TableCell>
                  <TableCell>{row.n}</TableCell>
                  <TableCell>{row.dataLancamento}</TableCell>
                  <TableCell>{row.dataOperacao}</TableCell>
                  <TableCell>{row.dataCotizacao}</TableCell>
                  <TableCell>{row.dataLiquidacao}</TableCell>
                  <TableCell>{row.plReferencia}</TableCell>
                  <TableCell>{row.cnpj}</TableCell>
                  <TableCell>{row.codBTG}</TableCell>
                  <TableCell>{row.movimentacao}</TableCell>
                  <TableCell>{row.invest}</TableCell>
                  <TableCell sx={{
                      color: row.captacao === 'Aprovado' ? 'green' : 'red', // Cor baseada no valor
                      fontWeight: 'bold',
                    }}>{row.captacao}
                  </TableCell>
                  <TableCell>{row.cotzAplic}</TableCell>
                  <TableCell>{row.pagam}</TableCell>
                  <TableCell>{row.cotiz}</TableCell>
                  <TableCell>{row.aplicMin}</TableCell>
                  <TableCell>{row.movMin}</TableCell>
                  <TableCell>{row.horario}</TableCell>
                  <TableCell>{row.plTotal}</TableCell>
                  <TableCell>{row.x}</TableCell>
                  <TableCell>{row.movAplRes}</TableCell>
                  <TableCell>{row.aplicFundos}</TableCell>
                  <TableCell>{row.resgFund}</TableCell>
                  <TableCell>{row.rFundos}</TableCell>
                  <TableCell>{row.percPL}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>

        <Button variant="outlined" onClick={onClose} sx={{ mt: 2 }}>
          Fechar
        </Button>
      </Box>
    </Modal>
  );
};

export default HistoricoModal;