import React, { useState } from 'react';
import {
  useReactTable,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  PaginationState,
  getFilteredRowModel,
} from '@tanstack/react-table';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';  // Ícone de delete
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import DetailModal from './DetailFundosGerenciadosModal';
import EditModal from './EditFundosGerenciadosModal';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import './Table.css';
import { FundosGerenciadosData } from '../types/fundosGerenciados';
import { editarFundo, deleteFundoGerenciado } from '../services/fundosGerenciadosService';

interface TableProps {
  columns: ColumnDef<any>[];
  data: any[];
}

const Table: React.FC<TableProps> = ({ columns, data }) => {
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState<FundosGerenciadosData | null>(null);
  const [updatedData, setUpdatedData] = useState<FundosGerenciadosData[]>(data); // Track updated data
  const [sorting, setSorting] = useState<SortingState>([]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [globalFilter, setGlobalFilter] = useState(''); 
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');

  const table = useReactTable({
    data: updatedData, // Use updatedData
    columns,
    state: {
      sorting,
      pagination,
      globalFilter, 
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    onGlobalFilterChange: setGlobalFilter, 
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(), 
  });

  const handleViewDetails = (row: any) => {
    setSelectedDetails(row.original);
    setIsDetailModalOpen(true);
  };

  const handleEditDetails = (row: any) => {
    setSelectedDetails(row.original);
    setIsEditModalOpen(true);
  };

  const handleDelete = async (id: number) => {
    try {
      await deleteFundoGerenciado(id);
      setUpdatedData((prevData) => prevData.filter((fundo) => fundo.id !== id));
      setSnackbarMessage('Fundo deletado com sucesso!');
      setSnackbarSeverity('success');
    } catch (error) {
      console.error('Erro ao deletar fundo:', error);
      setSnackbarMessage('Erro ao deletar fundo. Por favor, tente novamente.');
      setSnackbarSeverity('error');
    } finally {
      setSnackbarOpen(true);
    }
  };

  const handleSave = async (updatedDetails: FundosGerenciadosData) => {
    try {
      const savedFundo = await editarFundo(updatedDetails);
      setUpdatedData((prevData) =>
        prevData.map((fundo) => (fundo.id === savedFundo.id ? savedFundo : fundo))
      );
      setIsEditModalOpen(false);
    } catch (error) {
      console.error('Erro ao salvar as alterações:', error);
      alert('Ocorreu um erro ao salvar as alterações. Tente novamente.');
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <div style={{ marginBottom: '10px' }}>
        <TextField
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
          placeholder="Buscar na tabela..."
          variant="outlined"
          size="small"
          style={{ width: '300px' }}
        />
      </div>

      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} onClick={header.column.getToggleSortingHandler()} style={{ cursor: 'pointer' }}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, header.getContext())}
                  {header.column.getIsSorted() ? (
                    header.column.getIsSorted() === 'asc' ? (
                      <KeyboardArrowUpIcon fontSize="small" style={{ marginLeft: '5px', color: '#e2e2e2', cursor: 'pointer' }} />
                    ) : (
                      <KeyboardArrowDownIcon fontSize="small" style={{ marginLeft: '5px', color: '#e2e2e2', cursor: 'pointer' }} />
                    )
                  ) : (
                    <UnfoldMoreIcon fontSize="small" style={{ marginLeft: '5px', color: '#e2e2e2', cursor: 'pointer' }} />
                  )}
                </th>
              ))}
              <th>Ações</th>
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
              <td>
                <VisibilityIcon onClick={() => handleViewDetails(row)} style={{ cursor: 'pointer', marginRight: '10px' }} />
                <EditIcon onClick={() => handleEditDetails(row)} style={{ cursor: 'pointer', marginRight: '10px' }} />
                <DeleteIcon onClick={() => handleDelete(row.original.id)} style={{ cursor: 'pointer', color: 'red' }} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div style={{ display: 'flex', marginTop: '10px' }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          {/* Botão de página anterior */}
          <IconButton 
            onClick={() => table.previousPage()} 
            disabled={!table.getCanPreviousPage()}
            color="primary"
          >
            <NavigateBeforeIcon />
          </IconButton>

          <span>
            Página{' '}
            <strong>
              {table.getState().pagination.pageIndex + 1} de {table.getPageCount()}
            </strong>
          </span>

          <IconButton 
            onClick={() => table.nextPage()} 
            disabled={!table.getCanNextPage()}
            color="primary"
          >
            <NavigateNextIcon />
          </IconButton>
        </div>
        <FormControl variant="outlined" size="small" style={{ minWidth: 120 }}>
          <InputLabel id="page-size-label">Itens por página</InputLabel>
          <Select
            labelId="page-size-label"
            value={pagination.pageSize}
            onChange={(e) => setPagination({ ...pagination, pageSize: Number(e.target.value) })}
            label="Itens por página"
          >
            {[10, 20, 30, 40, 50].map((size) => (
              <MenuItem key={size} value={size}>
                Mostrar {size}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      {selectedDetails && (
        <>
          <DetailModal
            isOpen={isDetailModalOpen}
            onRequestClose={() => setIsDetailModalOpen(false)}
            details={selectedDetails}
          />
          <EditModal
            isOpen={isEditModalOpen}
            onRequestClose={() => setIsEditModalOpen(false)}
            details={selectedDetails}
            onSave={handleSave}
          />
        </>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Table;
