import React from 'react';
import { useNavigate } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

const ParametrosList = () => {
  const navigate = useNavigate();

  // Lista de configurações
  const configuracoes = [
    { nome: 'Segmentos', rota: '/config/parametros/segmentos/' },
    { nome: 'Tipos de Ativos', rota: '/config/parametros/tipos_ativos/' },
  ];

  return (
    <div>
      <h1>Lista de Configurações</h1>
      <List>
        {configuracoes.map((config, index) => (
          <ListItem
            key={index} 
            button
            onClick={() => navigate(config.rota)}
            sx={{
              '&:hover': {
                backgroundColor: '#f0f0f0',
              },
            }}
          >
            <ListItemText primary={config.nome} />
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default ParametrosList;
