// services/api.ts
import axios from 'axios';
import { authService } from './authService'; // Serviço de autenticação

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL, 
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Evita loops ao parar se já houve uma tentativa
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      // Tenta renovar o token
      const refreshResponse = await authService.refreshToken();

      if (refreshResponse?.access) {
        // Atualiza o cabeçalho Authorization e refaz a requisição original
        originalRequest.headers.Authorization = `Bearer ${refreshResponse.access}`;
        return api(originalRequest);
      } else {
        // Logout e redirecionamento se o refresh token falhar
        authService.logout();
        window.location.href = '/login';
        return Promise.reject(error); // Impede o loop ao rejeitar
      }
    }

    return Promise.reject(error);
  }
);


export default api;


