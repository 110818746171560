import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import {
  FundosGerenciadosData,
  Tributacao,
  Condominio,
  Captacao,
  Status,
  Corretora,
  FundoCorretoraCreate,
  Subclass,
} from '../types/fundosGerenciados';
import {
  getTributacoes,
  getCondominios,
  getCaptacoes,
  getStatusList,
  getCorretoras,
  createFundoGerenciado,
} from '../services/fundosGerenciadosService';

interface CreateModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onSave: (newFundo: FundosGerenciadosData) => void;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxWidth: '1500px',
  maxHeight: '80vh',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
};

const CreateModal: React.FC<CreateModalProps> = ({ isOpen, onRequestClose, onSave }) => {
  const [formValues, setFormValues] = useState<FundosGerenciadosData>({} as FundosGerenciadosData);
  const [tributacoes, setTributacoes] = useState<Tributacao[]>([]);
  const [condominios, setCondominios] = useState<Condominio[]>([]);
  const [captacoes, setCaptacoes] = useState<Captacao[]>([]);
  const [statusList, setStatusList] = useState<Status[]>([]); 
  const [corretoras, setCorretoras] = useState<Corretora[]>([]);
  const [subclassesEnabled, setSubclassesEnabled] = useState<boolean>(false);
  const [subclassesItems, setSubclassesItems] = useState<Subclass[]>([]);
  const [selectedCorretoras, setSelectedCorretoras] = useState<FundoCorretoraCreate[]>([]);

  // Estados para Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');

  // Estados para os erros do backend
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [tributacoesData, condominiosData, captacoesData, statusData, corretorasData] = await Promise.all([
          getTributacoes(),
          getCondominios(),
          getCaptacoes(),
          getStatusList(),
          getCorretoras(),
        ]);
        setTributacoes(tributacoesData);
        setCondominios(condominiosData);
        setCaptacoes(captacoesData);
        setStatusList(statusData);
        setCorretoras(corretorasData);
      } catch (error) {
        console.error('Erro ao carregar dados para os selects:', error);
      }
    };

    fetchData();
  }, []);

  const handleFieldChange = (key: keyof FundosGerenciadosData, value: any) => {
    setFormValues((prevFields) => ({
      ...prevFields,
      [key]: value,
    }));
  };

  const handleAddCorretora = (corretoraId: number) => {
    const selectedCorretora = corretoras.find(c => c.id === corretoraId);
    if (selectedCorretora) {
      setSelectedCorretoras([...selectedCorretoras, { corretora: selectedCorretora.corretora, codigo_corretora: '' }]);
    }
  };

  const handleCodigoCorretoraChange = (index: number, value: string) => {
    const updatedSelectedCorretoras = [...selectedCorretoras];
    updatedSelectedCorretoras[index].codigo_corretora = value;
    setSelectedCorretoras(updatedSelectedCorretoras);
  };

  const handleDeleteCorretora = (index: number) => {
    const updatedSelectedCorretoras = selectedCorretoras.filter((_, i) => i !== index);
    setSelectedCorretoras(updatedSelectedCorretoras);
  };

  const handleAddSubclass = () => {
    setSubclassesItems([...subclassesItems, { fundo: formValues.id, subclasse: '', serie: '' }]);
  };

  const handleSubclassChange = (index: number, key: keyof Subclass, value: any) => {
    const updatedSubclasses = subclassesItems.map((item, i) => 
      i === index ? { ...item, [key]: value } : item
    );
    setSubclassesItems(updatedSubclasses);
  };

  const handleDeleteSubclass = (index: number) => {
    const updatedSubclasses = subclassesItems.filter((_, i) => i !== index);
    setSubclassesItems(updatedSubclasses);
  };

  const handleSave = async () => {
    try {
      const defaultCaptacao = captacoes.length > 0 ? captacoes[0] : ({} as Captacao);
      const captacaoValue = formValues.condominio?.tipo === 'Aberto' ? formValues.captacao : defaultCaptacao;
      
      // Preparando o payload sem o campo 'fundo' nas corretoras
      const newFundo = await createFundoGerenciado({
        ...formValues,
        captacao: captacaoValue,
        subclasses: subclassesEnabled,
        fundos_corretoras: selectedCorretoras.length > 0 ? selectedCorretoras.map(corretora => ({
          corretora: corretora.corretora,
          codigo_corretora: corretora.codigo_corretora,
          // O campo 'fundo' não é necessário aqui
        })) : [],
        subclasse_items: subclassesItems, // Associando as subclasses
      });
      
      onSave(newFundo);
      setSnackbarMessage('Fundo gerenciado criado com sucesso!');
      setSnackbarSeverity('success');
      onRequestClose();
    } catch (error: any) {
      if (error.response && error.response.data) {
        setErrors(error.response.data);
      }
      setSnackbarMessage('Erro ao criar o fundo gerenciado. Verifique os dados e tente novamente.');
      setSnackbarSeverity('error');
    } finally {
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <Modal open={isOpen} onClose={onRequestClose}>
        <Box sx={style}>
          <Typography variant="h5" component="h2" sx={{ mb: 2 }}>
            Criar Fundo Gerenciado
          </Typography>
          <Divider sx={{ mb: 3 }} />

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">Códigos Identificadores</Typography>
              <Divider sx={{ mb: 2 }} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Nome Fantasia"
                value={formValues.fantasia || ''}
                onChange={(e) => handleFieldChange('fantasia', e.target.value)}
                fullWidth
                margin="normal"
                error={!!errors.fantasia}
                helperText={errors.fantasia ? errors.fantasia[0] : ''}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="CNPJ"
                value={formValues.cnpj || ''}
                onChange={(e) => handleFieldChange('cnpj', e.target.value)}
                fullWidth
                margin="normal"
                error={!!errors.cnpj}
                helperText={errors.cnpj ? errors.cnpj[0] : ''}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Razão Social"
                value={formValues.razao_social || ''}
                onChange={(e) => handleFieldChange('razao_social', e.target.value)}
                fullWidth
                margin="normal"
                error={!!errors.razao_social}
                helperText={errors.razao_social ? errors.razao_social[0] : ''}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Código ISIN"
                value={formValues.isin || ''}
                onChange={(e) => handleFieldChange('isin', e.target.value)}
                fullWidth
                margin="normal"
                error={!!errors.isin}
                helperText={errors.isin ? errors.isin[0] : ''}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Código ANBIMA"
                value={formValues.anbima || ''}
                onChange={(e) => handleFieldChange('anbima', e.target.value)}
                fullWidth
                margin="normal"
                error={!!errors.anbima}
                helperText={errors.anbima ? errors.anbima[0] : ''}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Código GIIN"
                value={formValues.giin || ''}
                onChange={(e) => handleFieldChange('giin', e.target.value)}
                fullWidth
                margin="normal"
                error={!!errors.giin}
                helperText={errors.giin ? errors.giin[0] : ''}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Código Galgo"
                value={formValues.codigo_galgo || ''}
                onChange={(e) => handleFieldChange('codigo_galgo', e.target.value)}
                fullWidth
                margin="normal"
                error={!!errors.codigo_galgo}
                helperText={errors.codigo_galgo ? errors.codigo_galgo[0] : ''}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Código CVM"
                value={formValues.cvm || ''}
                onChange={(e) => handleFieldChange('cvm', e.target.value)}
                fullWidth
                margin="normal"
                error={!!errors.cvm}
                helperText={errors.cvm ? errors.cvm[0] : ''}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">Classificações</Typography>
              <Divider sx={{ mb: 2 }} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Classificação CVM"
                value={formValues.classificacao_cvm || ''}
                onChange={(e) => handleFieldChange('classificacao_cvm', e.target.value)}
                fullWidth
                margin="normal"
                error={!!errors.classificacao_cvm}
                helperText={errors.classificacao_cvm ? errors.classificacao_cvm[0] : ''}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Classificação ANBIMA"
                value={formValues.classificacao_anbima || ''}
                onChange={(e) => handleFieldChange('classificacao_anbima', e.target.value)}
                fullWidth
                margin="normal"
                error={!!errors.classificacao_anbima}
                helperText={errors.classificacao_anbima ? errors.classificacao_anbima[0] : ''}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Perfil de Risco"
                value={formValues.perfil_risco || ''}
                onChange={(e) => handleFieldChange('perfil_risco', e.target.value)}
                fullWidth
                margin="normal"
                error={!!errors.perfil_risco}
                helperText={errors.perfil_risco ? errors.perfil_risco[0] : ''}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Classificação de Risco Mont"
                value={formValues.risco_gestora || ''}
                onChange={(e) => handleFieldChange('risco_gestora', e.target.value)}
                fullWidth
                margin="normal"
                error={!!errors.risco_gestora}
                helperText={errors.risco_gestora ? errors.risco_gestora[0] : ''}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Número de Risco Mont"
                value={formValues.numero_risco_mont || ''}
                onChange={(e) => handleFieldChange('numero_risco_mont', e.target.value)}
                fullWidth
                margin="normal"
                error={!!errors.numero_risco_mont}
                helperText={errors.numero_risco_mont ? errors.numero_risco_mont[0] : ''}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Segmento Gestora"
                value={formValues.segmento_gestora || ''}
                onChange={(e) => handleFieldChange('segmento_gestora', e.target.value)}
                fullWidth
                margin="normal"
                error={!!errors.segmento_gestora}
                helperText={errors.segmento_gestora ? errors.segmento_gestora[0] : ''}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Tipo de Ativo Gestora"
                value={formValues.tipo_ativo_gestora || ''}
                onChange={(e) => handleFieldChange('tipo_ativo_gestora', e.target.value)}
                fullWidth
                margin="normal"
                error={!!errors.tipo_ativo_gestora}
                helperText={errors.tipo_ativo_gestora ? errors.tipo_ativo_gestora[0] : ''}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">Remuneração</Typography>
              <Divider sx={{ mb: 2 }} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Taxa de Administração"
                type="number"
                value={formValues.taxa_administracao || ''}
                onChange={(e) => handleFieldChange('taxa_administracao', e.target.value)}
                fullWidth
                margin="normal"
                inputProps={{
                  step: 0.01,
                  min: 0,
                  max: 100,
                }}
                error={!!errors.taxa_administracao}
                helperText={errors.taxa_administracao ? errors.taxa_administracao[0] : ''}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Taxa de Performance"
                type="number"
                value={formValues.taxa_performance || ''}
                onChange={(e) => handleFieldChange('taxa_performance', e.target.value)}
                fullWidth
                margin="normal"
                inputProps={{
                  step: 0.01,
                  min: 0,
                  max: 100,
                }}
                error={!!errors.taxa_performance}
                helperText={errors.taxa_performance ? errors.taxa_performance[0] : ''}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Benchmark"
                value={formValues.benchmark || ''}
                onChange={(e) => handleFieldChange('benchmark', e.target.value)}
                fullWidth
                margin="normal"
                error={!!errors.benchmark}
                helperText={errors.benchmark ? errors.benchmark[0] : ''}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Referência do Benchmark"
                value={formValues.referencia_benchmark || ''}
                onChange={(e) => handleFieldChange('referencia_benchmark', e.target.value)}
                fullWidth
                margin="normal"
                error={!!errors.referencia_benchmark}
                helperText={errors.referencia_benchmark ? errors.referencia_benchmark[0] : ''}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Taxa de Custódia"
                type="number"
                value={formValues.taxa_custodia || ''}
                onChange={(e) => handleFieldChange('taxa_custodia', e.target.value)}
                fullWidth
                margin="normal"
                inputProps={{
                  step: 0.01,
                  min: 0,
                  max: 100,
                }}
                error={!!errors.taxa_custodia}
                helperText={errors.taxa_custodia ? errors.taxa_custodia[0] : ''}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">Distribuição</Typography>
              <Divider sx={{ mb: 2 }} />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Condomínio</InputLabel>
                <Select
                  value={formValues.condominio?.id || ''}
                  onChange={(e) =>
                    handleFieldChange(
                      'condominio',
                      condominios.find((condominio) => condominio.id === Number(e.target.value))
                    )
                  }
                  label="Condomínio"
                  error={!!errors.condominio}
                >
                  {condominios.map((condominio) => (
                    <MenuItem key={condominio.id} value={condominio.id}>
                      {condominio.tipo}
                    </MenuItem>
                  ))}
                </Select>
                {errors.condominio && <Typography color="primary">{errors.condominio[0]}</Typography>}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Status</InputLabel>
                <Select
                  value={formValues.status?.id || ''}
                  onChange={(e) =>
                    handleFieldChange(
                      'status',
                      statusList.find((status) => status.id === Number(e.target.value))
                    )
                  }
                  label="Status"
                  error={!!errors.status}
                >
                  {statusList.map((status) => (
                    <MenuItem key={status.id} value={status.id}>
                      {status.estado}
                    </MenuItem>
                  ))}
                </Select>
                {errors.status && <Typography color="primary">{errors.status[0]}</Typography>}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Tributação</InputLabel>
                <Select
                  value={formValues.tributacao?.id || ''}
                  onChange={(e) =>
                    handleFieldChange(
                      'tributacao',
                      tributacoes.find((tributacao) => tributacao.id === Number(e.target.value))
                    )
                  }
                  label="Tributação"
                  error={!!errors.tributacao}
                >
                  {tributacoes.map((tributacao) => (
                    <MenuItem key={tributacao.id} value={tributacao.id}>
                      {tributacao.tipo}
                    </MenuItem>
                  ))}
                </Select>
                {errors.tributacao && <Typography color="primary">{errors.tributacao[0]}</Typography>}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Data 1º Aporte"
                type="date"
                value={formValues.data_primeiro_aporte || ''}
                onChange={(e) => handleFieldChange('data_primeiro_aporte', e.target.value)}
                margin="normal"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                error={!!errors.data_primeiro_aporte}
                helperText={errors.data_primeiro_aporte ? errors.data_primeiro_aporte[0] : ''}
              />
            </Grid>

            {/* Campos para Condominio Aberto */}
            {formValues.condominio?.tipo === 'Aberto' && (
              <>
                <Grid item xs={12} sm={3}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Captação</InputLabel>
                    <Select
                      value={formValues.captacao?.id || ''}
                      onChange={(e) =>
                        handleFieldChange(
                          'captacao',
                          captacoes.find((captacao) => captacao.id === Number(e.target.value))
                        )
                      }
                      label="Captação"
                      error={!!errors.captacao}
                    >
                      {captacoes.map((captacao) => (
                        <MenuItem key={captacao.id} value={captacao.id}>
                          {captacao.tipo}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.captacao && <Typography color="primary">{errors.captacao[0]}</Typography>}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="Cotização de Aplicação"
                    value={formValues.cotizacao_aplicacao || ''}
                    onChange={(e) => handleFieldChange('cotizacao_aplicacao', e.target.value)}
                    fullWidth
                    margin="normal"
                    error={!!errors.cotizacao_aplicacao}
                    helperText={errors.cotizacao_aplicacao ? errors.cotizacao_aplicacao[0] : ''}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="Cotização de Resgate"
                    value={formValues.cotizacao_resgate || ''}
                    onChange={(e) => handleFieldChange('cotizacao_resgate', e.target.value)}
                    fullWidth
                    margin="normal"
                    error={!!errors.cotizacao_resgate}
                    helperText={errors.cotizacao_resgate ? errors.cotizacao_resgate[0] : ''}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="Liquidação de Resgate"
                    value={formValues.liquidacao_resgate || ''}
                    onChange={(e) => handleFieldChange('liquidacao_resgate', e.target.value)}
                    fullWidth
                    margin="normal"
                    error={!!errors.liquidacao_resgate}
                    helperText={errors.liquidacao_resgate ? errors.liquidacao_resgate[0] : ''}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="Aplicação Inicial Mínima"
                    value={formValues.aplicacao_inicial_minima || ''}
                    onChange={(e) => handleFieldChange('aplicacao_inicial_minima', e.target.value)}
                    fullWidth
                    margin="normal"
                    error={!!errors.aplicacao_inicial_minima}
                    helperText={errors.aplicacao_inicial_minima ? errors.aplicacao_inicial_minima[0] : ''}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="Movimentação Mínima"
                    value={formValues.movimentacao_minima || ''}
                    onChange={(e) => handleFieldChange('movimentacao_minima', e.target.value)}
                    fullWidth
                    margin="normal"
                    error={!!errors.movimentacao_minima}
                    helperText={errors.movimentacao_minima ? errors.movimentacao_minima[0] : ''}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="Saldo Mínimo"
                    value={formValues.saldo_minimo || ''}
                    onChange={(e) => handleFieldChange('saldo_minimo', e.target.value)}
                    fullWidth
                    margin="normal"
                    error={!!errors.saldo_minimo}
                    helperText={errors.saldo_minimo ? errors.saldo_minimo[0] : ''}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel id="paga-proventos-label">Paga Proventos?</InputLabel>
                    <Select
                      labelId="paga-proventos-label"
                      value={formValues.paga_proventos === true ? 'Sim' : formValues.paga_proventos === false ? 'Não' : ''}
                      onChange={(e) => handleFieldChange('paga_proventos', e.target.value === 'Sim')}
                      label="Paga Proventos?"
                      error={!!errors.paga_proventos}
                    >
                      <MenuItem value="Sim">Sim</MenuItem>
                      <MenuItem value="Não">Não</MenuItem>
                    </Select>
                    {errors.paga_proventos && <Typography color="primary">{errors.paga_proventos[0]}</Typography>}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="Periodicidade"
                    value={formValues.periodicidade || ''}
                    onChange={(e) => handleFieldChange('periodicidade', e.target.value)}
                    fullWidth
                    margin="normal"
                    error={!!errors.periodicidade}
                    helperText={errors.periodicidade ? errors.periodicidade[0] : ''}
                  />
                </Grid>
              </>
            )}

            {/* Campos para Condominio Fechado */}
            {formValues.condominio?.tipo === 'Fechado' && (
              <>
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="Código B3"
                    value={formValues.codigo_b3 || ''}
                    onChange={(e) => handleFieldChange('codigo_b3', e.target.value)}
                    fullWidth
                    margin="normal"
                    error={!!errors.codigo_b3}
                    helperText={errors.codigo_b3 ? errors.codigo_b3[0] : ''}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="Cotização de Aplicação"
                    value={formValues.cotizacao_aplicacao || ''}
                    onChange={(e) => handleFieldChange('cotizacao_aplicacao', e.target.value)}
                    fullWidth
                    margin="normal"
                    error={!!errors.cotizacao_aplicacao}
                    helperText={errors.cotizacao_aplicacao ? errors.cotizacao_aplicacao[0] : ''}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="Data de Resgate"
                    type="date"
                    value={formValues.data_resgate || ''}
                    onChange={(e) => handleFieldChange('data_resgate', e.target.value)}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    error={!!errors.data_resgate}
                    helperText={errors.data_resgate ? errors.data_resgate[0] : ''}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Data de Liquidação"
                    type="date"
                    value={formValues.data_liquidacao || ''}
                    onChange={(e) => handleFieldChange('data_liquidacao', e.target.value)}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    error={!!errors.data_liquidacao}
                    helperText={errors.data_liquidacao ? errors.data_liquidacao[0] : ''}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Aplicação Mínima"
                    value={formValues.aplicacao_minima || ''}
                    onChange={(e) => handleFieldChange('aplicacao_minima', e.target.value)}
                    fullWidth
                    margin="normal"
                    error={!!errors.aplicacao_minima}
                    helperText={errors.aplicacao_minima ? errors.aplicacao_minima[0] : ''}
                  />
                </Grid>
                {/* Subclasses */}
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Subclasses</InputLabel>
                    <Select
                      value={subclassesEnabled ? 'Sim' : 'Não'}
                      onChange={(e) => {
                        const selectedValue = e.target.value === 'Sim';
                        setSubclassesEnabled(selectedValue);
                        if (!selectedValue) {
                          setSubclassesItems([]);
                        }
                      }}
                      label="Subclasses"
                      error={!!errors.subclasses}
                    >
                      <MenuItem value="Sim">Sim</MenuItem>
                      <MenuItem value="Não">Não</MenuItem>
                    </Select>
                    {errors.subclasses && <Typography color="primary">{errors.subclasses[0]}</Typography>}
                  </FormControl>
                </Grid>
                {subclassesEnabled && subclassesItems.map((subclass, index) => (
                  <Grid container spacing={2} key={index}>
                    <Grid item xs={12} sm={5}>
                      <TextField
                        label="Subclasse"
                        value={subclass.subclasse || ''}
                        onChange={(e) => handleSubclassChange(index, 'subclasse', e.target.value)}
                        fullWidth
                        margin="normal"
                        error={!!errors[`subclasse_items.${index}.subclasse`]}
                        helperText={errors[`subclasse_items.${index}.subclasse`] ? errors[`subclasse_items.${index}.subclasse`][0] : ''}
                      />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <TextField
                        label="Série"
                        value={subclass.serie || ''}
                        onChange={(e) => handleSubclassChange(index, 'serie', e.target.value)}
                        fullWidth
                        margin="normal"
                        error={!!errors[`subclasse_items.${index}.serie`]}
                        helperText={errors[`subclasse_items.${index}.serie`] ? errors[`subclasse_items.${index}.serie`][0] : ''}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <IconButton onClick={() => handleDeleteSubclass(index)} style={{ color: 'red' }}>
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
                {subclassesEnabled && (
                  <Grid item xs={12}>
                    <Button onClick={handleAddSubclass} variant="outlined" startIcon={<AddIcon />}>
                      Adicionar Subclasse
                    </Button>
                  </Grid>
                )}
              </>
            )}

            {/* Campos para Contas Definitivas */}
            <Grid item xs={12}>
              <Typography variant="subtitle1">Contas Definitivas</Typography>
              <Divider sx={{ mb: 2 }} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Conta SELIC"
                value={formValues.conta_selic || ''}
                onChange={(e) => handleFieldChange('conta_selic', e.target.value)}
                fullWidth
                margin="normal"
                error={!!errors.conta_selic}
                helperText={errors.conta_selic ? errors.conta_selic[0] : ''}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Conta CETIP"
                value={formValues.codigo_cetip || ''}
                onChange={(e) => handleFieldChange('codigo_cetip', e.target.value)}
                fullWidth
                margin="normal"
                error={!!errors.codigo_cetip}
                helperText={errors.codigo_cetip ? errors.codigo_cetip[0] : ''}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Conta CETIP Contraparte"
                value={formValues.conta_cetip_contraparte || ''}
                onChange={(e) => handleFieldChange('conta_cetip_contraparte', e.target.value)}
                fullWidth
                margin="normal"
                error={!!errors.conta_cetip_contraparte}
                helperText={errors.conta_cetip_contraparte ? errors.conta_cetip_contraparte[0] : ''}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Conta CBLC"
                value={formValues.conta_cblc || ''}
                onChange={(e) => handleFieldChange('conta_cblc', e.target.value)}
                fullWidth
                margin="normal"
                error={!!errors.conta_cblc}
                helperText={errors.conta_cblc ? errors.conta_cblc[0] : ''}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Conta CBLC Custódia"
                value={formValues.conta_cblc_custodia || ''}
                onChange={(e) => handleFieldChange('conta_cblc_custodia', e.target.value)}
                fullWidth
                margin="normal"
                error={!!errors.conta_cblc_custodia}
                helperText={errors.conta_cblc_custodia ? errors.conta_cblc_custodia[0] : ''}
              />
            </Grid>

            {/* Campos para Contas Editáveis */}
            <Grid item xs={12}>
              <Typography variant="subtitle1">Contas Editáveis</Typography>
              <Divider sx={{ mb: 2 }} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Banco"
                value={formValues.banco || ''}
                onChange={(e) => handleFieldChange('banco', e.target.value)}
                fullWidth
                margin="normal"
                error={!!errors.banco}
                helperText={errors.banco ? errors.banco[0] : ''}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Número do Banco"
                value={formValues.numero_banco || ''}
                onChange={(e) => handleFieldChange('numero_banco', e.target.value)}
                fullWidth
                margin="normal"
                error={!!errors.numero_banco}
                helperText={errors.numero_banco ? errors.numero_banco[0] : ''}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Agência"
                value={formValues.agencia || ''}
                onChange={(e) => handleFieldChange('agencia', e.target.value)}
                fullWidth
                margin="normal"
                error={!!errors.agencia}
                helperText={errors.agencia ? errors.agencia[0] : ''}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Conta Bancária"
                value={formValues.conta_bancaria || ''}
                onChange={(e) => handleFieldChange('conta_bancaria', e.target.value)}
                fullWidth
                margin="normal"
                error={!!errors.conta_bancaria}
                helperText={errors.conta_bancaria ? errors.conta_bancaria[0] : ''}
              />
            </Grid>

            {/* Campos para Corretoras */}
            <Grid item xs={12}>
              <Typography variant="subtitle1">Corretoras</Typography>
              <Divider sx={{ mb: 2 }} />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Selecionar Corretora</InputLabel>
                <Select
                  value=""
                  onChange={(e) => handleAddCorretora(Number(e.target.value))}
                  label="Selecionar Corretora"
                  error={!!errors.fundos_corretoras}
                >
                  {corretoras.map((corretora) => (
                    <MenuItem key={corretora.id} value={corretora.id}>
                      {corretora.corretora}
                    </MenuItem>
                  ))}
                </Select>
                {errors.fundos_corretoras && <Typography color="primary">{errors.fundos_corretoras[0]}</Typography>}
              </FormControl>
            </Grid>

            {selectedCorretoras.map((fc, index) => {
              return (
                  <Grid container spacing={2} key={index}>
                      <Grid item xs={6}>
                          <TextField
                              label="Corretora"
                              value={fc.corretora || ''}
                              disabled
                              fullWidth
                              margin="normal"
                              error={!!errors[`fundos_corretoras.${index}.corretora`]}
                              helperText={errors[`fundos_corretoras.${index}.corretora`] ? errors[`fundos_corretoras.${index}.corretora`][0] : ''}
                          />
                      </Grid>
                      <Grid item xs={4}>
                          <TextField
                              label="Código Corretora"
                              value={fc.codigo_corretora || ''}
                              onChange={(e) => handleCodigoCorretoraChange(index, e.target.value)}
                              fullWidth
                              margin="normal"
                              error={!!errors[`fundos_corretoras.${index}.codigo_corretora`]}
                              helperText={errors[`fundos_corretoras.${index}.codigo_corretora`] ? errors[`fundos_corretoras.${index}.codigo_corretora`][0] : ''}
                          />
                      </Grid>
                      <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                          <IconButton onClick={() => handleDeleteCorretora(index)} style={{ color: 'red' }}>
                              <DeleteIcon />
                          </IconButton>
                      </Grid>
                  </Grid>
              );
          })}

            <Grid item xs={12}>
              <Button onClick={handleSave} variant="contained" color="primary" fullWidth>
                Criar Fundo Gerenciado
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CreateModal;
