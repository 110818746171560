import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import CircularProgress from '@mui/material/CircularProgress';
import { cadastrarAtivo } from '../services/ativosService';
import {
  Segmento, TipoAtivo, Benchmark, Tributacao, Nacionalidade, Condominio, Distribuicao, Captacao, Status, CadastroAtivoFormProps
} from '../types/ativos';
import {
  getSegmentos,
  getTiposAtivo,
  getBenchmarks,
  getTributacoes,
  getNacionalidades,
  getCondominios,
  getDistribuicoes,
  getCaptacoes,
  getStatus,
} from '../services/ativosService';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: '1200px',
  maxHeight: '90vh',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
};

const CadastroAtivoForm: React.FC<CadastroAtivoFormProps> = ({ isOpen, onRequestClose, onCadastroSuccess, setSnackbarProps }) => {
  const [segmentos, setSegmentos] = useState<Segmento[]>([]);
  const [tiposAtivo, setTiposAtivo] = useState<TipoAtivo[]>([]);
  const [benchmarks, setBenchmarks] = useState<Benchmark[]>([]);
  const [tributacoes, setTributacoes] = useState<Tributacao[]>([]);
  const [nacionalidades, setNacionalidades] = useState<Nacionalidade[]>([]);
  const [condominios, setCondominios] = useState<Condominio[]>([]);
  const [distribuicoes, setDistribuicoes] = useState<Distribuicao[]>([]);
  const [captacoes, setCaptacoes] = useState<Captacao[]>([]);
  const [statusList, setStatusList] = useState<Status[]>([]);

  const [selectedSegmento, setSelectedSegmento] = useState<number | string>('');
  const [selectedTipoAtivo, setSelectedTipoAtivo] = useState<number | string>('');
  const [formFields, setFormFields] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [formVisible, setFormVisible] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const [segmentosData, tiposAtivoData, benchmarksData, tributacoesData, nacionalidadesData, condominiosData, distribuicoesData, captacoesData, statusData] = await Promise.all([
          getSegmentos(),
          getTiposAtivo(),
          getBenchmarks(),
          getTributacoes(),
          getNacionalidades(),
          getCondominios(),
          getDistribuicoes(),
          getCaptacoes(),
          getStatus(),
        ]);
        setSegmentos(segmentosData);
        setTiposAtivo(tiposAtivoData);
        setBenchmarks(benchmarksData);
        setTributacoes(tributacoesData);
        setNacionalidades(nacionalidadesData); 
        setCondominios(condominiosData);
        setDistribuicoes(distribuicoesData);
        setCaptacoes(captacoesData);
        setStatusList(statusData);
        setLoading(false);
      } catch (error) {
        console.error('Erro ao carregar dados iniciais', error);
      }
    };
    fetchInitialData();
  }, []);

  useEffect(() => {
    if (selectedSegmento) {
      setFormVisible(true);
      setSelectedTipoAtivo('');
    }
  }, [selectedSegmento]);

  const handleFieldChange = (field: string, value: any) => {
    setFormFields((prevFields: any) => ({ ...prevFields, [field]: value }));
    setErrors((prevErrors) => {
      const { [field]: _, ...restErrors } = prevErrors;
      return restErrors;
    });
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      const novoAtivo = {
        segmento: selectedSegmento,
        tipo_ativo: selectedTipoAtivo,
        ...formFields,
      };

      await cadastrarAtivo(novoAtivo); // Faz o cadastro do ativo
      setSnackbarProps({
        open: true,
        message: 'Ativo cadastrado com sucesso!',
        severity: 'success',
      });
      onRequestClose(); // Fecha o modal após o sucesso
      onCadastroSuccess(); // Chama a função de sucesso para atualizar a lista de ativos
    } catch (error: any) {
      if (error.response && error.response.data) {
        // Captura os erros do backend e armazena no estado
        setErrors(error.response.data);
      } else {
        setSnackbarProps({
          open: true,
          message: 'Erro ao cadastrar ativo.',
          severity: 'error',
        });
      }
    }
  };

  if (loading) {
    return (
      <Modal open={isOpen} onClose={onRequestClose}>
        <Box sx={{ ...style, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress />
        </Box>
      </Modal>
    );
  }

  return (
    <Modal open={isOpen} onClose={onRequestClose}>
      <Box sx={style}>
        <Typography variant="h6" component="h2" gutterBottom>
          Cadastrar Novo Ativo
        </Typography>
        <Divider sx={{ mb: 2 }} />

        <FormControl fullWidth margin="normal">
          <InputLabel id="segmento-select-label">Selecione o Segmento</InputLabel>
          <Select
            labelId="segmento-select-label"
            value={selectedSegmento}
            onChange={(e) => setSelectedSegmento(e.target.value as number)}
            fullWidth
            label="Selecione o Segmento"
            error={!!errors.segmento}
          >
            {segmentos.map((segmento) => (
              <MenuItem key={segmento.id} value={segmento.id}>
                {segmento.nome}
              </MenuItem>
            ))}
          </Select>
          {errors.segmento && <Typography color="primary">{errors.segmento[0]}</Typography>}
        </FormControl>

        {formVisible && (
          <form onSubmit={handleSubmit}>
            <FormControl fullWidth margin="normal">
              <InputLabel id="tipo-ativo-select-label">Selecione o Tipo de Ativo</InputLabel>
              <Select
                labelId="tipo-ativo-select-label"
                value={selectedTipoAtivo}
                onChange={(e) => setSelectedTipoAtivo(e.target.value as number)}
                fullWidth
                label="Selecione o Tipo de Ativo"
                error={!!errors.tipo_ativo}
              >
                {tiposAtivo
                  .filter((tipo) => tipo.segmento === Number(selectedSegmento))
                  .map((tipo) => (
                    <MenuItem key={tipo.id} value={tipo.id}>
                      {tipo.nome}
                    </MenuItem>
                  ))}
              </Select>
              {errors.tipo_ativo && <Typography color="primary">{errors.tipo_ativo[0]}</Typography>}
              <TextField
                  label="Nome do Ativo"
                  value={formFields.nome || ''}
                  onChange={(e) => handleFieldChange('nome', e.target.value)}
                  fullWidth
                  margin="normal"
                  size="small"
                  error={!!errors.nome}
                  helperText={errors.nome ? errors.nome[0] : ''}
                />
            </FormControl>

            {/* Seção: Códigos Identificadores */}
            <Divider sx={{ my: 2 }} />
            <Typography variant="h6" gutterBottom>
              Códigos Identificadores
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="CNPJ"
                  value={formFields.cnpj || ''}
                  onChange={(e) => handleFieldChange('cnpj', e.target.value)}
                  fullWidth
                  margin="normal"
                  size="small"
                  error={!!errors.cnpj}
                  helperText={errors.cnpj ? errors.cnpj[0] : ''}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Razão Social"
                  value={formFields.razao_social || ''}
                  onChange={(e) => handleFieldChange('razao_social', e.target.value)}
                  fullWidth
                  margin="normal"
                  size="small"
                  error={!!errors.razao_social}
                  helperText={errors.razao_social ? errors.razao_social[0] : ''}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Nome Fantasia"
                  value={formFields.fantasia || ''}
                  onChange={(e) => handleFieldChange('fantasia', e.target.value)}
                  fullWidth
                  margin="normal"
                  size="small"
                  error={!!errors.fantasia}
                  helperText={errors.fantasia ? errors.fantasia[0] : ''}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Código ANBIMA"
                  value={formFields.anbima || ''}
                  onChange={(e) => handleFieldChange('anbima', e.target.value)}
                  fullWidth
                  margin="normal"
                  size="small"
                  error={!!errors.anbima}
                  helperText={errors.anbima ? errors.anbima[0] : ''}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Código CVM"
                  value={formFields.cvm || ''}
                  onChange={(e) => handleFieldChange('cvm', e.target.value)}
                  fullWidth
                  margin="normal"
                  size="small"
                  error={!!errors.cvm}
                  helperText={errors.cvm ? errors.cvm[0] : ''}
                />
              </Grid>
            </Grid>

            {/* Seção: Classificações */}
            <Divider sx={{ my: 2 }} />
            <Typography variant="h6" gutterBottom>
              Classificações
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Classificação CVM"
                  value={formFields.classificacao_cvm || ''}
                  onChange={(e) => handleFieldChange('classificacao_cvm', e.target.value)}
                  fullWidth
                  margin="normal"
                  size="small"
                  error={!!errors.classificacao_cvm}
                  helperText={errors.classificacao_cvm ? errors.classificacao_cvm[0] : ''}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Classificação ANBIMA"
                  value={formFields.classificacao_anbima || ''}
                  onChange={(e) => handleFieldChange('classificacao_anbima', e.target.value)}
                  fullWidth
                  margin="normal"
                  size="small"
                  error={!!errors.classificacao_anbima}
                  helperText={errors.classificacao_anbima ? errors.classificacao_anbima[0] : ''}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Classificação de Risco Mont"
                  value={formFields.risco_gestora || ''}
                  onChange={(e) => handleFieldChange('risco_gestora', e.target.value)}
                  fullWidth
                  margin="normal"
                  size="small"
                  error={!!errors.risco_gestora}
                  helperText={errors.risco_gestora ? errors.risco_gestora[0] : ''}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Número de Risco Mont"
                  value={formFields.numero_risco_mont || ''}
                  onChange={(e) => handleFieldChange('numero_risco_mont', e.target.value)}
                  fullWidth
                  margin="normal"
                  size="small"
                  error={!!errors.numero_risco_mont}
                  helperText={errors.numero_risco_mont ? errors.numero_risco_mont[0] : ''}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Segmento Gestora"
                  value={formFields.segmento_gestora || ''}
                  onChange={(e) => handleFieldChange('segmento_gestora', e.target.value)}
                  fullWidth
                  margin="normal"
                  size="small"
                  error={!!errors.segmento_gestora}
                  helperText={errors.segmento_gestora ? errors.segmento_gestora[0] : ''}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Tipo de Ativo Gestora"
                  value={formFields.tipo_ativo_gestora || ''}
                  onChange={(e) => handleFieldChange('tipo_ativo_gestora', e.target.value)}
                  fullWidth
                  margin="normal"
                  size="small"
                  error={!!errors.tipo_ativo_gestora}
                  helperText={errors.tipo_ativo_gestora ? errors.tipo_ativo_gestora[0] : ''}
                />
              </Grid>
            </Grid>

            {/* Seção: Características Gerais */}
            <Divider sx={{ my: 2 }} />
            <Typography variant="h6" gutterBottom>
              Características Gerais
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth margin="normal" size="small">
                  <InputLabel id="tributacao-select-label">Tributação</InputLabel>
                  <Select
                    labelId="tributacao-select-label"
                    value={formFields.tributacao || ''}
                    onChange={(e) => handleFieldChange('tributacao', e.target.value)}
                    label="Tributação"
                    error={!!errors.tributacao}
                  >
                    {tributacoes.map((tributacao) => (
                      <MenuItem key={tributacao.id} value={tributacao.id}>
                        {tributacao.tipo}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.tributacao && <Typography color="primary">{errors.tributacao[0]}</Typography>}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth margin="normal" size="small">
                  <InputLabel id="nacionalidade-select-label">Nacionalidade</InputLabel>
                  <Select
                    labelId="nacionalidade-select-label"
                    value={formFields.nacionalidade || ''}
                    onChange={(e) => handleFieldChange('nacionalidade', e.target.value)}
                    label="Nacionalidade"
                    error={!!errors.nacionalidade}
                  >
                    {nacionalidades.map((nacionalidade) => (
                      <MenuItem key={nacionalidade.id} value={nacionalidade.id}>
                        {nacionalidade.origem}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.nacionalidade && <Typography color="primary">{errors.nacionalidade[0]}</Typography>}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth margin="normal" size="small">
                  <InputLabel id="condominio-select-label">Condomínio</InputLabel>
                  <Select
                    labelId="condominio-select-label"
                    value={formFields.condominio || ''}
                    onChange={(e) => handleFieldChange('condominio', e.target.value)}
                    label="Condomínio"
                    error={!!errors.condominio}
                  >
                    {condominios.map((condominio) => (
                      <MenuItem key={condominio.id} value={condominio.id}>
                        {condominio.tipo}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.condominio && <Typography color="primary">{errors.condominio[0]}</Typography>}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth margin="normal" size="small">
                  <InputLabel id="paga-proventos-select-label">Pagamento de Proventos</InputLabel>
                  <Select
                    labelId="paga-proventos-select-label"
                    value={formFields.paga_proventos || ''}
                    onChange={(e) => handleFieldChange('paga_proventos', e.target.value)}
                    label="Pagamento de Proventos"
                    error={!!errors.paga_proventos}
                  >
                    <MenuItem value="true">Sim</MenuItem>
                    <MenuItem value="false">Não</MenuItem>
                  </Select>
                  {errors.paga_proventos && <Typography color="primary">{errors.paga_proventos[0]}</Typography>}
                </FormControl>
              </Grid>
              {formFields.paga_proventos === 'true' && (
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Periodicidade dos Proventos"
                    value={formFields.periodicidade || ''}
                    onChange={(e) => handleFieldChange('periodicidade', e.target.value)}
                    fullWidth
                    margin="normal"
                    size="small"
                    error={!!errors.periodicidade}
                    helperText={errors.periodicidade ? errors.periodicidade[0] : ''}
                  />
                </Grid>
              )}
            </Grid>

            {/* Seção: Remuneração */}
            <Divider sx={{ my: 2 }} />
            <Typography variant="h6" gutterBottom>
              Remuneração
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Taxa de Administração"
                  type="number"
                  inputProps={{ min: 0, max: 100, step: 0.01 }}
                  value={formFields.taxa_administracao || ''}
                  onChange={(e) => handleFieldChange('taxa_administracao', e.target.value)}
                  fullWidth
                  margin="normal"
                  size="small"
                  error={!!errors.taxa_administracao}
                  helperText={errors.taxa_administracao ? errors.taxa_administracao[0] : ''}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Taxa de Performance"
                  type="number"
                  inputProps={{ min: 0, max: 100, step: 0.01 }}
                  value={formFields.taxa_performance || ''}
                  onChange={(e) => handleFieldChange('taxa_performance', e.target.value)}
                  fullWidth
                  margin="normal"
                  size="small"
                  error={!!errors.taxa_performance}
                  helperText={errors.taxa_performance ? errors.taxa_performance[0] : ''}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth margin="normal" size="small">
                  <InputLabel id="benchmark-select-label">Benchmark</InputLabel>
                  <Select
                    labelId="benchmark-select-label"
                    value={formFields.benchmark || ''}
                    onChange={(e) => handleFieldChange('benchmark', e.target.value)}
                    label="Benchmark"
                    error={!!errors.benchmark}
                  >
                    {benchmarks.map((benchmark) => (
                      <MenuItem key={benchmark.id} value={benchmark.id}>
                        {benchmark.nome}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.benchmark && <Typography color="primary">{errors.benchmark[0]}</Typography>}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Referência do Benchmark"
                  value={formFields.referencia_benchmark || ''}
                  onChange={(e) => handleFieldChange('referencia_benchmark', e.target.value)}
                  fullWidth
                  margin="normal"
                  size="small"
                  error={!!errors.referencia_benchmark}
                  helperText={errors.referencia_benchmark ? errors.referencia_benchmark[0] : ''}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Taxa de Custódia"
                  type="number"
                  inputProps={{ min: 0, max: 100, step: 0.01 }}
                  value={formFields.taxa_custodia || ''}
                  onChange={(e) => handleFieldChange('taxa_custodia', e.target.value)}
                  fullWidth
                  margin="normal"
                  size="small"
                  error={!!errors.taxa_custodia}
                  helperText={errors.taxa_custodia ? errors.taxa_custodia[0] : ''}
                />
              </Grid>
            </Grid>

            {/* Seção: Regras de Distribuição */}
            <Divider sx={{ my: 2 }} />
            <Typography variant="h6" gutterBottom>
              Regras de Distribuição
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Data do Primeiro Aporte"
                  type="date"
                  value={formFields.data_primeiro_aporte || ''}
                  onChange={(e) => handleFieldChange('data_primeiro_aporte', e.target.value)}
                  fullWidth
                  margin="normal"
                  size="small"
                  error={!!errors.data_primeiro_aporte}
                  helperText={errors.data_primeiro_aporte ? errors.data_primeiro_aporte[0] : ''}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth margin="normal" size="small">
                  <InputLabel id="status-select-label">Status</InputLabel>
                  <Select
                    labelId="status-select-label"
                    value={formFields.status || ''}
                    onChange={(e) => handleFieldChange('status', e.target.value)}
                    label="Status"
                    error={!!errors.status}
                  >
                    {statusList.map((status) => (
                      <MenuItem key={status.id} value={status.id}>
                        {status.estado}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.status && <Typography color="primary">{errors.status[0]}</Typography>}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Cotização de Aplicação"
                  value={formFields.cotizacao_aplicacao || ''}
                  onChange={(e) => handleFieldChange('cotizacao_aplicacao', e.target.value)}
                  fullWidth
                  margin="normal"
                  size="small"
                  error={!!errors.cotizacao_aplicacao}
                  helperText={errors.cotizacao_aplicacao ? errors.cotizacao_aplicacao[0] : ''}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Cotização de Resgate"
                  value={formFields.cotizacao_resgate || ''}
                  onChange={(e) => handleFieldChange('cotizacao_resgate', e.target.value)}
                  fullWidth
                  margin="normal"
                  size="small"
                  error={!!errors.cotizacao_resgate}
                  helperText={errors.cotizacao_resgate ? errors.cotizacao_resgate[0] : ''}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Liquidação de Resgate"
                  value={formFields.liquidacao_resgate || ''}
                  onChange={(e) => handleFieldChange('liquidacao_resgate', e.target.value)}
                  fullWidth
                  margin="normal"
                  size="small"
                  error={!!errors.liquidacao_resgate}
                  helperText={errors.liquidacao_resgate ? errors.liquidacao_resgate[0] : ''}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Aplicação Inicial Mínima"
                  value={formFields.aplicacao_inicial_minima || ''}
                  onChange={(e) => handleFieldChange('aplicacao_inicial_minima', e.target.value)}
                  fullWidth
                  margin="normal"
                  size="small"
                  error={!!errors.aplicacao_inicial_minima}
                  helperText={errors.aplicacao_inicial_minima ? errors.aplicacao_inicial_minima[0] : ''}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Movimentação Mínima"
                  value={formFields.movimentacao_minima || ''}
                  onChange={(e) => handleFieldChange('movimentacao_minima', e.target.value)}
                  fullWidth
                  margin="normal"
                  size="small"
                  error={!!errors.movimentacao_minima}
                  helperText={errors.movimentacao_minima ? errors.movimentacao_minima[0] : ''}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Saldo Mínimo"
                  value={formFields.saldo_minimo || ''}
                  onChange={(e) => handleFieldChange('saldo_minimo', e.target.value)}
                  fullWidth
                  margin="normal"
                  size="small"
                  error={!!errors.saldo_minimo}
                  helperText={errors.saldo_minimo ? errors.saldo_minimo[0] : ''}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth margin="normal" size="small">
                  <InputLabel id="captacao-select-label">Captação</InputLabel>
                  <Select
                    labelId="captacao-select-label"
                    value={formFields.captacao || ''}
                    onChange={(e) => handleFieldChange('captacao', e.target.value)}
                    label="Captação"
                    error={!!errors.captacao}
                  >
                    {captacoes.map((captacao) => (
                      <MenuItem key={captacao.id} value={captacao.id}>
                        {captacao.tipo}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.captacao && <Typography color="primary">{errors.captacao[0]}</Typography>}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth margin="normal" size="small">
                  <InputLabel id="distribuicao-select-label">Distribuição</InputLabel>
                  <Select
                    labelId="distribuicao-select-label"
                    value={formFields.distribuicao || ''}
                    onChange={(e) => handleFieldChange('distribuicao', e.target.value)}
                    label="Distribuição"
                    error={!!errors.distribuicao}
                  >
                    {distribuicoes.map((distribuicao) => (
                      <MenuItem key={distribuicao.id} value={distribuicao.id}>
                        {distribuicao.tipo}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.distribuicao && <Typography color="primary">{errors.distribuicao[0]}</Typography>}
                </FormControl>
              </Grid>
            </Grid>

            {/* Campos adicionais para Fundo de Investimento */}
            {selectedSegmento === 1 && (
              <>
                <Divider sx={{ my: 2 }} />
                <Typography variant="h6" gutterBottom>
                  Informações Adicionais para Fundo de Investimento
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth margin="normal">
                      <InputLabel id="origem-select-label">Origem</InputLabel>
                      <Select
                        labelId="origem-select-label"
                        value={formFields.origem || ''}
                        onChange={(e) => handleFieldChange('origem', e.target.value)}
                        label="Origem"
                        error={!!errors.origem}
                      >
                        <MenuItem value="BTG">BTG Digital</MenuItem>
                        <MenuItem value="FUNDO">Direto no Fundo</MenuItem>
                      </Select>
                      {errors.origem && <Typography color="primary">{errors.origem[0]}</Typography>}
                    </FormControl>
                  </Grid>

                  {/* Campos específicos se a origem for BTG Digital */}
                  {formFields.origem === 'BTG' && (
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Código BTG Digital"
                        value={formFields.codigo_btg_digital || ''}
                        onChange={(e) => handleFieldChange('codigo_btg_digital', e.target.value)}
                        fullWidth
                        margin="normal"
                        size="small"
                        error={!!errors.codigo_btg_digital}
                        helperText={errors.codigo_btg_digital ? errors.codigo_btg_digital[0] : ''}
                      />
                    </Grid>
                  )}

                  {/* Campos específicos se a origem for Direto no Fundo */}
                  {formFields.origem === 'FUNDO' && (
                    <>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="Banco"
                          value={formFields.banco || ''}
                          onChange={(e) => handleFieldChange('banco', e.target.value)}
                          fullWidth
                          margin="normal"
                          size="small"
                          error={!!errors.banco}
                          helperText={errors.banco ? errors.banco[0] : ''}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="Número do Banco"
                          value={formFields.numero_banco || ''}
                          onChange={(e) => handleFieldChange('numero_banco', e.target.value)}
                          fullWidth
                          margin="normal"
                          size="small"
                          error={!!errors.numero_banco}
                          helperText={errors.numero_banco ? errors.numero_banco[0] : ''}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="Agência"
                          value={formFields.agencia || ''}
                          onChange={(e) => handleFieldChange('agencia', e.target.value)}
                          fullWidth
                          margin="normal"
                          size="small"
                          error={!!errors.agencia}
                          helperText={errors.agencia ? errors.agencia[0] : ''}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="Conta Bancária"
                          value={formFields.conta_bancaria || ''}
                          onChange={(e) => handleFieldChange('conta_bancaria', e.target.value)}
                          fullWidth
                          margin="normal"
                          size="small"
                          error={!!errors.conta_bancaria}
                          helperText={errors.conta_bancaria ? errors.conta_bancaria[0] : ''}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </>
            )}

            {/* Campos adicionais para Previdência */}
            {selectedSegmento === 2 && (
              <>
                <Divider sx={{ my: 2 }} />
                <Typography variant="h6" gutterBottom>
                  Informações Adicionais para Previdência
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Banco"
                      value={formFields.banco || ''}
                      onChange={(e) => handleFieldChange('banco', e.target.value)}
                      fullWidth
                      margin="normal"
                      size="small"
                      error={!!errors.banco}
                      helperText={errors.banco ? errors.banco[0] : ''}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Número do Banco"
                      value={formFields.numero_banco || ''}
                      onChange={(e) => handleFieldChange('numero_banco', e.target.value)}
                      fullWidth
                      margin="normal"
                      size="small"
                      error={!!errors.numero_banco}
                      helperText={errors.numero_banco ? errors.numero_banco[0] : ''}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Agência"
                      value={formFields.agencia || ''}
                      onChange={(e) => handleFieldChange('agencia', e.target.value)}
                      fullWidth
                      margin="normal"
                      size="small"
                      error={!!errors.agencia}
                      helperText={errors.agencia ? errors.agencia[0] : ''}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Conta Bancária"
                      value={formFields.conta_bancaria || ''}
                      onChange={(e) => handleFieldChange('conta_bancaria', e.target.value)}
                      fullWidth
                      margin="normal"
                      size="small"
                      error={!!errors.conta_bancaria}
                      helperText={errors.conta_bancaria ? errors.conta_bancaria[0] : ''}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Código CETIP"
                      value={formFields.codigo_cetip || ''}
                      onChange={(e) => handleFieldChange('codigo_cetip', e.target.value)}
                      fullWidth
                      margin="normal"
                      size="small"
                      error={!!errors.codigo_cetip}
                      helperText={errors.codigo_cetip ? errors.codigo_cetip[0] : ''}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Conta CETIP Contraparte"
                      value={formFields.conta_cetip_contraparte || ''}
                      onChange={(e) => handleFieldChange('conta_cetip_contraparte', e.target.value)}
                      fullWidth
                      margin="normal"
                      size="small"
                      error={!!errors.conta_cetip_contraparte}
                      helperText={errors.conta_cetip_contraparte ? errors.conta_cetip_contraparte[0] : ''}
                    />
                  </Grid>
                </Grid>
              </>
            )}

            {/* Botão de submissão */}
            <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
              Cadastrar
            </Button>
          </form>
        )}
      </Box>
    </Modal>
  );
};

export default CadastroAtivoForm;
