import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import logo from '../../assets/img/logo.png';
import { getFundosGerenciados } from '../../services/fundosGerenciadosService';

interface DetailModalPassivosProps {
  isOpen: boolean;
  onRequestClose: () => void;
  rowData?: any;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxWidth: '1500px',
  maxHeight: '80vh',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
};

const logoStyle = {
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: '20px',
  width: '160px',
};

// Função para renderizar seções de dados
const renderSection = (title: string, fields: { label: string, value: any }[]) => (
  <>
    <Typography variant="h6">
      <strong>{title}</strong>
    </Typography>
    <Divider sx={{ my: 1 }} />
    <Grid container spacing={2} sx={{ mb: 4 }}>
      {fields.map((field, index) => (
        <Grid item xs={4} key={index}>
          <Typography variant="body2" gutterBottom>
            <strong>{field.label}:</strong> {field.value || 'N/A'}
          </Typography>
        </Grid>
      ))}
    </Grid>
  </>
);

const DetailModalPassivos: React.FC<DetailModalPassivosProps> = ({ isOpen, onRequestClose, rowData }) => {
  const [fundosGerenciados, setFundosGerenciados] = useState<{ id: number; fantasia: string }[]>([]);

  useEffect(() => {
    if (isOpen && rowData) {
      getFundosGerenciados().then(setFundosGerenciados); // Busca todos os fundos gerenciados
    }
  }, [isOpen, rowData]);

  // Função para buscar os nomes dos fundos gerenciados pelo ID
  const getNomesFundosByIds = (ids: number[], list: { id: number; fantasia: string }[]) => {
    return ids.map(id => {
      const fundo = list.find(f => f.id === id);
      return fundo ? fundo.fantasia : 'Fundo desconhecido';
    }).join(', ');
  };

  const getFieldsForPassivos = () => {
    if (!rowData) return [];

    return [
      { label: 'Descrição', value: rowData?.descricao },
      { label: 'Conta', value: rowData?.conta },
      { label: 'Fundos Relacionados', value: getNomesFundosByIds(rowData?.fundos || [], fundosGerenciados) }, // Exibe os fundos gerenciados
      // Adicione mais campos conforme necessário
    ];
  };

  return (
    <Modal open={isOpen} onClose={onRequestClose}>
      <Box sx={style}>
        <img src={logo} alt="Logo" style={logoStyle} />

        {/* Renderizando os campos dos passivos */}
        {renderSection('Detalhes do Passivo', getFieldsForPassivos())}

        <Button onClick={onRequestClose} variant="contained" sx={{ mt: 2 }}>
          Fechar
        </Button>
      </Box>
    </Modal>
  );
};

export default DetailModalPassivos;
