import React, { useEffect, useState } from 'react';
import './SegmentosList.css';
import { getTiposAtivo, getSegmentos, createTipoAtivo } from '../services/ativosService';
import { TipoAtivo, Segmento } from '../types/ativos';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const TiposAtivosList: React.FC = () => {
  const [tiposAtivos, setTiposAtivos] = useState<TipoAtivo[]>([]);
  const [segmentos, setSegmentos] = useState<Segmento[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [novoNome, setNovoNome] = useState<string>('');
  const [novoSegmento, setNovoSegmento] = useState<number | ''>('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [tiposData, segmentosData] = await Promise.all([getTiposAtivo(), getSegmentos()]);
        setTiposAtivos(tiposData);
        setSegmentos(segmentosData);
      } catch (err) {
        setError('Erro ao carregar os dados.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleAddTipoAtivo = async () => {
    if (novoNome.trim() && novoSegmento) {
      try {
        const novo = {
          nome: novoNome,
          segmento_id: Number(novoSegmento),
        };
        const tipoCriado = await createTipoAtivo(novo); // Chama o serviço para criar o tipo
        setTiposAtivos([...tiposAtivos, tipoCriado]); // Atualiza a lista local com o tipo criado
        setNovoNome('');
        setNovoSegmento('');
        setModalOpen(false);
      } catch (error) {
        console.error('Erro ao criar tipo de ativo:', error);
        setError('Erro ao criar tipo de ativo.');
      }
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
    <div>
      <h1>Lista de Tipos de Ativos</h1>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setModalOpen(true)}
        sx={{ marginBottom: 2 }}
      >
        Adicionar Tipo de Ativo
      </Button>

      <TableContainer
        component={Paper}
        sx={{
          maxWidth: '40%',
          margin: 'auto',
          marginTop: 0,
          marginLeft: 0,
          marginRight: 'auto',
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="primeira-coluna header">ID</TableCell>
              <TableCell className="header">Nome</TableCell>
              <TableCell className="header">Segmento</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tiposAtivos.map((tipo) => {
              const segmento = segmentos.find((seg) => seg.id === tipo.segmento); // Encontra o segmento correspondente
              return (
                <TableRow key={tipo.id}>
                  <TableCell className="primeira-coluna">{tipo.id}</TableCell>
                  <TableCell>{tipo.nome}</TableCell>
                  <TableCell>{segmento ? segmento.nome : tipo.segmento}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modal para adicionar novo tipo de ativo */}
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalStyle}>
          <h2 id="modal-title">Adicionar Novo Tipo de Ativo</h2>
          <TextField
            fullWidth
            label="Nome do Tipo de Ativo"
            value={novoNome}
            onChange={(e) => setNovoNome(e.target.value)}
            margin="normal"
          />
          <FormControl fullWidth margin="normal">
            <InputLabel id="segmento-label">Segmento</InputLabel>
            <Select
              labelId="segmento-label"
              value={novoSegmento}
              onChange={(e) => setNovoSegmento(e.target.value as number)}
              label="Segmento"
            >
              {segmentos.map((seg) => (
                <MenuItem key={seg.id} value={seg.id}>
                  {seg.nome}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => setModalOpen(false)}
              sx={{ marginRight: 2 }}
            >
              Cancelar
            </Button>
            <Button variant="contained" color="primary" onClick={handleAddTipoAtivo}>
              Salvar
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default TiposAtivosList;
