import React, { useState } from 'react';
import {
  Box,
  Tabs,
  Tab,
  Typography,
  Paper,
  Breadcrumbs,
} from '@mui/material';
import IncluirTabContent from './IncluirTabContent'; // Componente da aba Incluir
import HistoricoAbaContent from './HistoricoAbaContent'; // Componente da aba Histórico

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          {children}
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index: number) => ({
  id: `tab-${index}`,
  'aria-controls': `tabpanel-${index}`,
});

const Boletagem: React.FC = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const tabNames = [
    'Incluir',
    'Boleta em lote',
    'Consultar',
    'Histórico',
    'Eventos Fundos',
    'Gerenciar Dados - Atualizar',
  ];

  return (
    <Box sx={{ width: '100%' }}>
      <Paper elevation={3} sx={{ mb: 3, p: 2 }}>
        <Typography variant="h4" gutterBottom>Boletagem</Typography>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            aria-label="Boletagem Tabs"
            variant="scrollable"
            scrollButtons="auto"
          >
            {tabNames.map((name, index) => (
              <Tab
                key={index}
                label={name}
                {...a11yProps(index)}
                sx={{
                  textTransform: 'none',
                  fontWeight: tabIndex === index ? 'bold' : 'normal',
                }}
              />
            ))}
          </Tabs>
        </Box>
      </Paper>
      <Paper elevation={3} sx={{ p: 2 }}>
        {tabNames.map((name, index) => (
          <TabPanel value={tabIndex} index={index} key={index}>
            {index === 0 ? (
              <IncluirTabContent />
            ) : index === 3 ? (
              <HistoricoAbaContent /> /* Somente o componente histórico renderiza a tabela */
            ) : (
              <Typography>{`Conteúdo da aba ${name}`}</Typography>
            )}
          </TabPanel>
        ))}
      </Paper>
    </Box>
  );
};

export default Boletagem;
