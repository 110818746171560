import React, { useState, useEffect } from 'react';
import { getFundosGerenciados } from '../services/fundosGerenciadosService';
import { getCarteiraPorFundoData } from '../services/carteiraDiariaService';
import TableCarteiraDiariaConsulta from '../components/TableCarteiraDiariaConsulta';
import SearchIcon from '@mui/icons-material/Search';
import { TextField, Select, MenuItem, FormControl, InputLabel, Button } from '@mui/material';
import './CarteiraDiariaConsulta.css'; 

const CarteiraDiariaConsulta: React.FC = () => {
  const [fundosGerenciados, setFundosGerenciados] = useState<any[]>([]);
  const [selectedFundo, setSelectedFundo] = useState<string>(''); 
  const [dataPosicao, setDataPosicao] = useState<string>(''); 
  const [carteiraData, setCarteiraData] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchFundos = async () => {
      const fundos = await getFundosGerenciados();
      setFundosGerenciados(fundos);
    };

    fetchFundos();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const data = await getCarteiraPorFundoData(selectedFundo, dataPosicao);
      setCarteiraData(data); // Armazena os dados recebidos da API
    } catch (error) {
      console.error('Erro ao buscar os dados da carteira:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <h2>Consulta de Carteira Diária</h2>
      <form onSubmit={handleSubmit} style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
        {/* Select do Fundo */}
        <FormControl 
          variant="outlined" 
          size="small" 
          className="fundo-select-container"
        >
          <InputLabel id="fundo-label">Selecione o Fundo</InputLabel>
          <Select
            labelId="fundo-label"
            value={selectedFundo}
            onChange={(e) => setSelectedFundo(e.target.value)}
            required
            label="Selecione o Fundo"
            MenuProps={{
              PaperProps: {
                className: 'dropdown-menu',
              },
            }}
          >
            <MenuItem value="">
              <em>Selecione...</em>
            </MenuItem>
            {fundosGerenciados.map((fundo) => (
              <MenuItem key={fundo.id} value={fundo.id}>
                {fundo.fantasia}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Input de Data */}
        <TextField
          id="dataPosicao"
          type="date"
          label="Data Posição"
          value={dataPosicao}
          onChange={(e) => setDataPosicao(e.target.value)}
          required
          InputLabelProps={{
            shrink: true, // Faz o label não sobrepor o valor
          }}
          size="small"
          style={{ minWidth: 200 }}
        />

        {/* Botão de Buscar */}
        <Button type="submit" variant="contained" color="primary" endIcon={<SearchIcon />}>
          Buscar
        </Button>
      </form>

      {isLoading ? (
        <p>Carregando...</p>
      ) : (
        <>
          {/* Seção Ativos */}
          {carteiraData.ativos && (
            <TableCarteiraDiariaConsulta
              columns={[
                { header: 'Nome', accessorKey: 'nome' },
                { header: 'Valor Total', accessorKey: 'valor_total' },
              ]}
              data={carteiraData.ativos}
              sectionTitle="Ativos"
            />
          )}

          {/* Seção Passivo */}
          {carteiraData.passivos && (
            <TableCarteiraDiariaConsulta
              columns={[
                { header: 'Nome', accessorKey: 'nome' },
                { header: 'Valor Total', accessorKey: 'valor_total' },
              ]}
              data={carteiraData.passivos}
              sectionTitle="Passivos"
            />
          )}

          {/* Seção Passivo */}
          {carteiraData.outros_passivos && (
            <TableCarteiraDiariaConsulta
              columns={[
                { header: 'Nome', accessorKey: 'descricao' },
                { header: 'Valor Total', accessorKey: 'financeiro' },
              ]}
              data={carteiraData.outros_passivos}
              sectionTitle="Outros Passivos"
            />
          )}

          {/* Seção Saldo Tesouraria */}
          {carteiraData.saldo_tesouraria && (
            <TableCarteiraDiariaConsulta
              columns={[
                { header: 'Nome', accessorKey: 'descricao' },
                { header: 'Financeiro', accessorKey: 'financeiro' },
              ]}
              data={carteiraData.saldo_tesouraria}
              sectionTitle="Saldo Tesouraria"
            />
          )}

          {/* Seção Patrimônio Líquido */}
          {carteiraData.patrimonio && (
            <TableCarteiraDiariaConsulta
              columns={[
                { header: 'Patrimônio Líquido Calculado', accessorKey: 'patrimonio_liquido_calculado' },
                { header: 'Patrimônio Líquido API', accessorKey: 'patrimonio_liquido_api' },
              ]}
              data={carteiraData.patrimonio}
              sectionTitle="Patrimônio Líquido"
            />
          )}
        </>
      )}
    </div>
  );
};

export default CarteiraDiariaConsulta;
