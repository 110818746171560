import React from 'react';
import { useLocation } from 'react-router-dom'; // Importa o hook useLocation
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';

const BaseLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const location = useLocation(); // Obtém a rota atual

  // Define se a página atual é a de login
  const isLoginPage = location.pathname === '/login';

  return (
    <div className="base-layout">
      {!isLoginPage && <Header />} {/* Renderiza o Header se não for a página de login */}
      <div className="content">
        {!isLoginPage && <Sidebar />} {/* Renderiza o Sidebar se não for a página de login */}
        <main>
          {children}
        </main>
      </div>
      {!isLoginPage && <Footer />} {/* Renderiza o Footer se não for a página de login */}
    </div>
  );
};

export default BaseLayout;
