import api from './api';
import { Passivo } from '../types/passivos';

export const getPassivos = async (
  pageIndex: number,
  pageSize: number = 100,
  sortBy: any[] = [], // Ordenação opcional
  globalFilter: string = ''  // Filtro global opcional
) => {
  try {
    // Construa a URL com parâmetros de paginação, ordenação e filtro global
    const sortParam = sortBy.length > 0 ? `&ordering=${sortBy.map(sort => (sort.desc ? '-' : '') + sort.id).join(',')}` : '';
    const filterParam = globalFilter ? `&search=${globalFilter}` : ''; // Se houver filtro, adicione à query

    const response = await api.get<{ results: Passivo[], count: number }>(
      `/ativos/passivo/?page=${pageIndex + 1}&page_size=${pageSize}${sortParam}${filterParam}` // Inclui paginação, ordenação e filtro
    );

    // Retorne os dados e a contagem total de páginas (calculada)
    return {
      results: response.data.results,
      totalPages: Math.ceil(response.data.count / pageSize), // Calcula o total de páginas
    };
  } catch (error) {
    throw error;
  }
};

export const updatePassivo = async (id: number, data: any) => {
  try {
    // Realiza a requisição PATCH para atualizar o ativo específico
    const response = await api.patch(`/ativos/passivo/${id}/`, data);

    // Retorna os dados da resposta em caso de sucesso
    return response.data;
  } catch (error) {
    // Em caso de erro, lança uma exceção para tratamento no componente
    throw error;
  }
};
