import React, { useState, useEffect } from 'react';
import './Header.css';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import Badge from '@mui/material/Badge';
import { Divider, IconButton, Menu, MenuItem } from '@mui/material';
import { fetchNotifications } from '../services/notificationService';
import { fetchCurrentUser, authService } from '../services/authService';
import { Notification } from '../types/notification';
import { useNavigate } from 'react-router-dom';

const Header: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [userName, setUserName] = useState<string>('Usuário');
  const navigate = useNavigate();

  const openNotifications = Boolean(anchorEl);
  const openUserMenu = Boolean(userMenuAnchorEl);

  const handleNotificationsClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    loadNotifications();
  };

  const handleNotificationsClose = () => {
    setAnchorEl(null);
  };

  const handleUserMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setUserMenuAnchorEl(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setUserMenuAnchorEl(null);
  };

  const handleLogout = () => {
    authService.logout();
    navigate('/login');
  };

  const loadNotifications = async () => {
    try {
      const notificationsData = await fetchNotifications();
      setNotifications(notificationsData.results);
      setUnreadCount(notificationsData.count);
    } catch (error) {
      console.error('Erro ao buscar notificações:', error);
      setNotifications([]);
      setUnreadCount(0);
    }
  };

  const loadUserProfile = async () => {
    try {
      const userProfile = await fetchCurrentUser();
      setUserName(userProfile.name || 'Usuário');
    } catch (error) {
      console.error('Erro ao buscar perfil do usuário:', error);
    }
  };

  useEffect(() => {
    loadNotifications();
    loadUserProfile();
  }, []);

  return (
    <header>
      <div className='hello-user' style={{ color: 'black' }}>
        <p>Olá, {userName}!</p>
      </div>
      <IconButton onClick={handleNotificationsClick} color="inherit">
        <Badge badgeContent={unreadCount} color="primary" className='badge'>
          <CircleNotificationsIcon className='notification-icon' />
        </Badge>
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={openNotifications}
        onClose={handleNotificationsClose}
        MenuListProps={{
          sx: {
            padding: '0px',
            width: '600px',
          },
        }}
      >
        {notifications.length === 0 ? (
          <MenuItem onClick={handleNotificationsClose}>Sem notificações</MenuItem>
        ) : (
          notifications.map((notification) => (
            <MenuItem
              key={notification.id}
              onClick={handleNotificationsClose}
              style={{ fontWeight: !notification.lida ? 'bold' : 'normal' }}
            >
              <div className='notification-item'>
                <div className='notification-header'>
                  <small className='notification-date'>{new Date(notification.data_criacao).toLocaleDateString()}</small>
                </div>
                <p className='notification-message'>{notification.mensagem}</p>
              </div>
            </MenuItem>
          ))
        )}
      </Menu>

      <IconButton onClick={handleUserMenuClick} color="inherit">
        <AccountCircleIcon className="user-icon" />
      </IconButton>

      <Menu
        anchorEl={userMenuAnchorEl}
        open={openUserMenu}
        onClose={handleUserMenuClose}
      >
        <MenuItem onClick={() => navigate('/config/usuarios')}>
          Usuários
        </MenuItem>
        <MenuItem onClick={() => navigate('/config/parametros')}>
          Parâmetros
        </MenuItem>
        <Divider variant="middle"></Divider>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </header>
  );
};

export default Header;
