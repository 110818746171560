import React, { useState, useEffect } from 'react';
import { Box, Button, Modal, TextField, Typography, Grid, Divider, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { updateCarteira } from '../../services/carteiraDiariaService'; // Importe o serviço de carteiras

interface EditModalCarteirasProps {
  isOpen: boolean;
  onRequestClose: () => void;
  rowData?: any;  // Os dados atuais da carteira que serão editados
  tipoCarteira: any;  // Valor da carteira, utilizado para definir quais campos mostrar
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxWidth: '1200px',
  maxHeight: '80vh',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
};

const EditModalCarteiras: React.FC<EditModalCarteirasProps> = ({ tipoCarteira, isOpen, onRequestClose, rowData }) => {
  const [formData, setFormData] = useState(rowData || {});

  // Sincroniza o estado do formData com o rowData recebido
  useEffect(() => {
    if (rowData) {
      setFormData(rowData);
    }
  }, [rowData]);

  // Função para lidar com mudanças nos campos de texto
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSelectChange = (value: string) => {
    setFormData({
      ...formData,
      situacao: value,  // Atualizando o campo 'situacao' com o valor selecionado
    });
  };

  // Função para submeter os dados editados
  const handleSubmit = async () => {
    try {
      await updateCarteira(tipoCarteira, formData.id, formData);  // Chama o serviço para atualizar a carteira
      onRequestClose(); // Fecha o modal após a submissão bem-sucedida
    } catch (error) {
      console.error('Erro ao atualizar a carteira:', error);
    }
  };

  // Renderiza campos diferentes dependendo da carteira
  const renderFieldsByCarteira = () => {
    switch (tipoCarteira) {
      case 'carteira-diaria-ativos':
        return (
          <>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Admin"
                name="adm"
                value={formData.adm || ''}
                onChange={handleChange}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Situação</InputLabel>
                <Select
                  value={formData.situacao || ''}  // Certifique-se de que 'formData.situacao' esteja sendo passado corretamente
                  onChange={(e) => handleSelectChange(e.target.value)}  // Passa diretamente o valor selecionado
                  label="Situação"
                >
                  <MenuItem value="Mesclado">Mesclado</MenuItem>
                  <MenuItem value="Ñ Mesclado">Ñ Mesclado</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Código 1"
                name="codigo1"
                value={formData.codigo1 || ''}
                onChange={handleChange}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Código 2"
                name="codigo2"
                value={formData.codigo2 || ''}
                onChange={handleChange}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Nome"
                name="nome"
                value={formData.nome || ''}
                onChange={handleChange}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Data Posição"
                name="dataPosicao"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={formData.data_posicao || ''}
                onChange={handleChange}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Quantidade"
                name="quantidade"
                type="number"
                value={formData.quantidade || ''}
                onChange={handleChange}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Valor por unidade"
                name="valor_por_unidade"
                type="number"
                value={formData.valor_por_unidade || ''}
                onChange={handleChange}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Valor total"
                name="valor_total"
                type="number"
                value={formData.valor_total || ''}
                onChange={handleChange}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Proventos"
                name="proventos"
                type="number"
                value={formData.proventos || ''}
                onChange={handleChange}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Custo médio"
                name="custo_medio"
                type="number"
                value={formData.custo_medio || ''}
                onChange={handleChange}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Total Custo"
                name="total_custo"
                type="number"
                value={formData.total_custo || ''}
                onChange={handleChange}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Resultado do dia"
                name="resultado_dia"
                type="number"
                value={formData.resultado_dia || ''}
                onChange={handleChange}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="LN RENT ATIVO"
                name="ln_rent_ativo"
                type="number"
                value={formData.ln_rent_ativo || ''}
                onChange={handleChange}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="LENT RENT ATIVO 2"
                name="lent_rent_ativo_2"
                type="number"
                value={formData.ln_rent_ativo_2 || ''}
                onChange={handleChange}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="RENT FUND IBOV"
                name="rent_fund_ibov"
                type="number"
                value={formData.rent_fund_ibov || ''}
                onChange={handleChange}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="RENT FUND INDEX 1"
                name="rent_fund_index_1"
                type="number"
                value={formData.rent_fund_index_1 || ''}
                onChange={handleChange}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="RENT FUND INDEX 2"
                name="rent_fund_index_2"
                type="number"
                value={formData.rent_fund_index_2 || ''}
                onChange={handleChange}
                margin="normal"
                fullWidth
              />
            </Grid>
          </>
        );
      case 'carteira-diaria-passivo':
        return (
          <>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Nome"
                name="nome"
                value={formData.nome || ''}
                onChange={handleChange}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Data Posição"
                name="dataPosicao"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={formData.data_posicao || ''}
                onChange={handleChange}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Situação</InputLabel>
                <Select
                  value={formData.situacao || ''}  // Certifique-se de que 'formData.situacao' esteja sendo passado corretamente
                  onChange={(e) => handleSelectChange(e.target.value)}  // Passa diretamente o valor selecionado
                  label="Situação"
                >
                  <MenuItem value="Mesclado">Mesclado</MenuItem>
                  <MenuItem value="Ñ Mesclado">Ñ Mesclado</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Categoria"
                name="categoria"
                value={formData.categoria || ''}
                onChange={handleChange}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Valor Total"
                name="valor_total"
                type="number"
                value={formData.valor_total || ''}
                onChange={handleChange}
                margin="normal"
                fullWidth
              />
            </Grid>
            
          </>
        );
      case 'carteira-diaria-patrimonio-liquido':
        return (
          <>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Data Posição"
                name="dataPosicao"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={formData.data_posicao || ''}
                onChange={handleChange}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Patrimônio liquido(CALC)"
                name="patrimonio_liquido_calculado"
                type="number"
                value={formData.patrimonio_liquido_calculado || ''}
                onChange={handleChange}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Quantidade"
                name="quantidade"
                type="number"
                value={formData.quantidade || ''}
                onChange={handleChange}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Valor por unidade"
                name="valor_por_unidade"
                type="number"
                value={formData.valor_por_unidade || ''}
                onChange={handleChange}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Valor total(API)"
                name="patrimonio_liquido_api"
                type="number"
                value={formData.patrimonio_liquido_api || ''}
                onChange={handleChange}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Proventos"
                name="proventos"
                type="number"
                value={formData.proventos || ''}
                onChange={handleChange}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Custo médio"
                name="custo_medio"
                type="number"
                value={formData.custo_medio || ''}
                onChange={handleChange}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Total Custo"
                name="total_custo"
                type="number"
                value={formData.total_custo || ''}
                onChange={handleChange}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Resultado do dia"
                name="resultado_dia"
                type="number"
                value={formData.resultado_dia || ''}
                onChange={handleChange}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="LN RENT ATIVO"
                name="ln_rent_ativo"
                type="number"
                value={formData.ln_rent_ativo || ''}
                onChange={handleChange}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="LENT RENT ATIVO 2"
                name="lent_rent_ativo_2"
                type="number"
                value={formData.ln_rent_ativo_2 || ''}
                onChange={handleChange}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="RENT FUND IBOV"
                name="rent_fund_ibov"
                type="number"
                value={formData.rent_fund_ibov || ''}
                onChange={handleChange}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="RENT FUND INDEX 1"
                name="rent_fund_index_1"
                type="number"
                value={formData.rent_fund_index_1 || ''}
                onChange={handleChange}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="RENT FUND INDEX 2"
                name="rent_fund_index_2"
                type="number"
                value={formData.rent_fund_index_2 || ''}
                onChange={handleChange}
                margin="normal"
                fullWidth
              />
            </Grid>
          </>
        );
      case 'saldo-tesouraria':
        return (
          <>
             <Grid item xs={12} sm={4}>
              <TextField
                label="Admin"
                name="adm"
                value={formData.adm || ''}
                onChange={handleChange}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Data Posição"
                name="data_posicao"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={formData.data_posicao || ''}
                onChange={handleChange}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Situação</InputLabel>
                <Select
                  value={formData.situacao || ''}
                  onChange={(e) => handleSelectChange(e.target.value)}
                  label="Situação"
                >
                  <MenuItem value="Mesclado">Mesclado</MenuItem>
                  <MenuItem value="Ñ Mesclado">Ñ Mesclado</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Descrição"
                name="descricao"
                value={formData.descricao || ''}
                onChange={handleChange}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Financeiro"
                name="financeiro"
                type="number"
                value={formData.financeiro || ''}
                onChange={handleChange}
                margin="normal"
                fullWidth
              />
            </Grid>
          </>  
        )
      default:
        return (
          <Typography variant="body1">Tipo de carteira não suportado</Typography>
        );
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={onRequestClose}
      aria-labelledby="edit-modal-title"
      aria-describedby="edit-modal-description"
    >
      <Box sx={style}>
        <Typography id="edit-modal-title" variant="h6" component="h2">
          Editar Carteira Diária
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Informações da Carteira</Typography>
            <Divider sx={{ mb: 2 }} />
          </Grid>

          {/* Renderiza os campos com base no tipo de carteira */}
          {renderFieldsByCarteira()}

        </Grid>

        <Box sx={{ mt: 2 }}>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Salvar Alterações
          </Button>
          <Button
            variant="outlined"
            color="error"
            onClick={onRequestClose}
            sx={{ ml: 2 }}
          >
            Cancelar
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default EditModalCarteiras;
