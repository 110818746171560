import React, { useEffect, useState } from 'react';
import { getCarteiraPassivo } from '../services/carteiraDiariaService';
import { getFundosGerenciados } from '../services/fundosGerenciadosService'; // Importar o serviço de Fundos Gerenciados
import { CarteiraDiariaPassivo } from '../types/carteiraDiaria';
import { FundosGerenciadosData } from '../types/fundosGerenciados'; // Importar o tipo Fundos Gerenciados
import { ColumnDef, SortingState } from '@tanstack/react-table';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import Modal from '@mui/material/Modal';
import CadastroAtivoForm from '../components/CadastroAtivoForm';
import SnackbarAlert from '../components/SnackbarAlert';
import TableCarteiraDiaria from '../components/TableCarteiraDiaria';

const CarteiraDiaria_Passivos: React.FC = () => {
  const [passivos, setCarteiraPassivos] = useState<CarteiraDiariaPassivo[]>([]);
  const [fundosGerenciados, setFundosGerenciados] = useState<FundosGerenciadosData[]>([]); // Estado para armazenar os fundos gerenciados
  const [loading, setLoading] = useState<boolean>(true);
  const [pageCount, setPageCount] = useState(0); // Contagem de páginas
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 }); // Estado para a paginação
  const [sorting, setSorting] = useState<SortingState>([]); // Estado para a ordenação
  const [globalFilter, setGlobalFilter] = useState<string>(''); // Estado para busca global
  const [isCadastroModalOpen, setIsCadastroModalOpen] = useState<boolean>(false);
  const [dataFilter, setDataFilter] = useState<string>('');
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    message: '',
    severity: 'success' as 'success' | 'error',
  });

  // Função para buscar dados paginados do backend
  const fetchData = async (pageIndex: number, pageSize: number, sortBy: SortingState, globalFilter: string, dataFilter: string) => {
    setLoading(true);
    try {
      // Carrega os dados dos passivos de forma paginada
      const [passivoData, fundosGerenciadosData] = await Promise.all([
        getCarteiraPassivo(pageIndex, pageSize, sortBy, globalFilter, dataFilter), // Ajustado para aceitar paginação, ordenação e busca global
        getFundosGerenciados(), // Busca os dados dos fundos gerenciados
      ]);

      setCarteiraPassivos(passivoData.results); // Armazena os passivos no estado
      setFundosGerenciados(fundosGerenciadosData); // Armazena os fundos gerenciados no estado
      setPageCount(passivoData.totalPages); // Define o total de páginas baseado no retorno do backend
    } catch (error) {
      console.error('Erro ao buscar dados', error);
      setSnackbarProps({
        open: true,
        message: 'Erro ao buscar dados',
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(pagination.pageIndex, pagination.pageSize, sorting, globalFilter, dataFilter); // Busca os dados na mudança de paginação, ordenação ou filtro global
  }, [pagination.pageIndex, pagination.pageSize, sorting, globalFilter, dataFilter]); // Atualiza quando a página, ordenação ou o filtro global mudar

  const handleCadastroSuccess = () => {
    fetchData(pagination.pageIndex, pagination.pageSize, sorting, globalFilter, dataFilter); // Atualiza os dados após o cadastro
    setSnackbarProps({
      open: true,
      message: 'Cadastro realizado com sucesso!',
      severity: 'success',
    });
    setIsCadastroModalOpen(false);
  };

  // Função para mapear o ID do fundo para o nome
  const getFundoGerenciadoNome = (fundoId: any): string => {
    const fundo = fundosGerenciados.find(f => f.id === fundoId);
    return fundo ? fundo.razao_social : 'Fundo desconhecido';
  };

  // Define as colunas da tabela, incluindo a coluna "Fundo"
  const columns = React.useMemo<ColumnDef<CarteiraDiariaPassivo>[]>(() => [
    {
      header: 'Data Posição',
      accessorKey: 'data_posicao', // Mantém o valor original da data para a ordenação correta
      cell: (info) => {
        const rawDate = info.getValue() as string; // Garante que o valor é uma string no formato 'YYYY-MM-DD'
    
        // Dividimos a string da data no formato 'YYYY-MM-DD'
        const [year, month, day] = rawDate.split('-');
    
        // Retorna no formato desejado: dd/mm/aaaa
        return `${day}/${month}/${year}`;
      },
      enableSorting: true, // Mantém a ordenação pelo backend
    },
    {
      header: 'Fundo Gerenciado',
      accessorFn: (row) => getFundoGerenciadoNome(row.fundo),
      id: 'fundo__fantasia',
      enableSorting: true,
    },
    {
      header: 'Nome',
      accessorKey: 'nome',
    },
    {
      header: 'Valor Total',
      accessorKey: 'valor_total',
    },
    // Outras colunas, se necessário
  ], [fundosGerenciados]); // Adiciona `fundosGerenciados` como dependência para atualizar quando houver mudanças

  return (
    <div>
      <h2>Carteira Diária Passivos</h2>
      <div style={{ display: 'flex', justifyContent: 'space-between', float: 'right' }}>
        <IconButton onClick={() => setIsCadastroModalOpen(true)} color="primary">
          <AddIcon />
        </IconButton>
      </div>
      <TableCarteiraDiaria
        columns={columns}
        data={passivos}
        fetchData={fetchData}  // Passa o pageIndex, pageSize, sortBy e globalFilter
        pageCount={pageCount}
        pagination={pagination} // Adiciona o estado de paginação
        setPagination={setPagination} // Permite que a tabela altere a paginação
        sorting={sorting}
        setSorting={setSorting} // Adiciona suporte para ordenação
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter} // Adiciona suporte para busca global
        isLoading={loading}
        tipoCarteira="carteira-diaria-passivo"
      />
      <Modal open={isCadastroModalOpen} onClose={() => setIsCadastroModalOpen(false)}>
        <div>
          <CadastroAtivoForm
            isOpen={isCadastroModalOpen}
            onRequestClose={() => setIsCadastroModalOpen(false)}
            onCadastroSuccess={handleCadastroSuccess}
            setSnackbarProps={setSnackbarProps}
          />
        </div>
      </Modal>
      <SnackbarAlert
        open={snackbarProps.open}
        message={snackbarProps.message}
        severity={snackbarProps.severity}
        onClose={() => setSnackbarProps({ ...snackbarProps, open: false })}
      />
    </div>
  );
};

export default CarteiraDiaria_Passivos;
