import React, { useEffect, useState } from 'react';
import { getPassivos } from '../services/passivosService';
import { Passivo } from '../types/passivos';
import { ColumnDef, SortingState } from '@tanstack/react-table';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import Modal from '@mui/material/Modal';
import CadastroAtivoForm from '../components/CadastroAtivoForm';
import SnackbarAlert from '../components/SnackbarAlert';
import TablePassivos from '../components/TablePassivos';

const Renda_Variavel: React.FC = () => {
  const [passivos, setPassivo] = useState<Passivo[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [pageCount, setPageCount] = useState(0); // Contagem de páginas
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 }); // Estado para a paginação
  const [sorting, setSorting] = useState<SortingState>([]); // Estado para a ordenação
  const [globalFilter, setGlobalFilter] = useState<string>(''); // Filtro global
  const [isCadastroModalOpen, setIsCadastroModalOpen] = useState<boolean>(false);
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    message: '',
    severity: 'success' as 'success' | 'error',
  });

  // Função para buscar dados paginados do backend, incluindo ordenação e filtro global
  const fetchData = async (pageIndex: number, pageSize: number, sortBy: SortingState, globalFilter: string) => {
    setLoading(true);
    try {
      const passivosData = await getPassivos(pageIndex, pageSize, sortBy, globalFilter); // Ajustado para aceitar paginação e ordenação
      setPassivo(passivosData.results); // Armazena os passivos no estado
      setPageCount(passivosData.totalPages); // Define o total de páginas baseado no retorno do backend
    } catch (error) {
      console.error('Erro ao buscar dados', error);
      setSnackbarProps({
        open: true,
        message: 'Erro ao buscar dados',
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(pagination.pageIndex, pagination.pageSize, sorting, globalFilter); // Busca os dados na mudança de paginação, ordenação ou filtro global
  }, [pagination.pageIndex, pagination.pageSize, sorting, globalFilter]); // Atualiza quando a página, ordenação ou o tamanho da página mudam

  const handleCadastroSuccess = () => {
    fetchData(pagination.pageIndex, pagination.pageSize, sorting, globalFilter); // Atualiza os dados após o cadastro
    setSnackbarProps({
      open: true,
      message: 'Cadastro realizado com sucesso!',
      severity: 'success',
    });
    setIsCadastroModalOpen(false);
  };

  // Define as colunas da tabela
  const columns = React.useMemo<ColumnDef<Passivo>[]>(() => [
    {
      header: 'Conta',
      accessorKey: 'conta',
    },
    {
      header: 'Nome',
      accessorKey: 'descricao',
    },
    // Outras colunas, se necessário
  ], []);

  return (
    <div>
      <h2>Passivos</h2>
      <div style={{ display: 'flex', justifyContent: 'space-between', float: 'right' }}>
        <IconButton onClick={() => setIsCadastroModalOpen(true)} color="primary">
          <AddIcon />
        </IconButton>
      </div>
      <TablePassivos
        columns={columns}
        data={passivos}
        fetchData={fetchData}  // Passa pageIndex, pageSize, sorting e globalFilter
        pageCount={pageCount}
        pagination={pagination} // Adiciona o estado de paginação
        setPagination={setPagination} // Permite que a tabela altere a paginação
        sorting={sorting} // Estado da ordenação
        setSorting={setSorting} // Permite que a tabela altere a ordenação
        globalFilter={globalFilter} // Valor do filtro global
        setGlobalFilter={setGlobalFilter} // Função para alterar o filtro global
        isLoading={loading}
      />
      <Modal open={isCadastroModalOpen} onClose={() => setIsCadastroModalOpen(false)}>
        <div>
          <CadastroAtivoForm
            isOpen={isCadastroModalOpen}
            onRequestClose={() => setIsCadastroModalOpen(false)}
            onCadastroSuccess={handleCadastroSuccess}
            setSnackbarProps={setSnackbarProps}
          />
        </div>
      </Modal>
      <SnackbarAlert
        open={snackbarProps.open}
        message={snackbarProps.message}
        severity={snackbarProps.severity}
        onClose={() => setSnackbarProps({ ...snackbarProps, open: false })}
      />
    </div>
  );
};

export default Renda_Variavel;
