import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import logo from '../assets/img/logo_main.png';
import './Sidebar.css';

const Sidebar: React.FC = () => {
  const [openAtivos, setOpenAtivos] = useState(false);
  const [openCarteiraDiaria, setOpenCarteiraDiaria] = useState(false);
  const [openCadastroRegras, setOpenCadastroRegras] = useState(false);

  const handleClickAtivos = () => {
    setOpenAtivos(!openAtivos);
  };

  const handleClickCarteirasDiarias = () => {
    setOpenCarteiraDiaria(!openCarteiraDiaria)
  };

  const handleClickCadastroRegras = () => {
    setOpenCadastroRegras(!openCadastroRegras);
  };

  return (
    <aside>
      <img src={logo} alt="Logo" className="logo" />
      <List component="nav">
        <ListItem button component={Link} to="/">
          <ListItemText primary="Dashboard" style={{ color: '#e2e2e2' }} />
        </ListItem>
        <ListItem button component={Link} to="/fundos_gerenciados">
          <ListItemText primary="Fundos Gerenciados" style={{ color: '#e2e2e2' }} />
        </ListItem>
        {/* <ListItem button component={Link} to="/cadastro_ativos">
          <ListItemText primary="Cadastro de Ativos" style={{ color: '#e2e2e2' }} />
        </ListItem> */}
        {/* <ListItem button onClick={handleClickCadastroRegras}>
          <ListItemText primary="Cadastro de Regras" style={{ color: '#e2e2e2' }} />
          {openCadastroRegras ? (
            <ArrowDropDownIcon style={{ color: '#e2e2e2' }} />
          ) : (
            <ArrowRightIcon style={{ color: '#e2e2e2' }} />
          )}
        </ListItem>
        <Collapse in={openCadastroRegras} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button component={Link} to="/cadastro_regras/cvm" sx={{ pl: 4 }}>
              <ListItemText primary="CVM" style={{ color: '#e2e2e2' }} />
            </ListItem>
            <ListItem button component={Link} to="/cadastro_regras/regulamento" sx={{ pl: 4 }}>
              <ListItemText primary="Regulamento" style={{ color: '#e2e2e2' }}/>
            </ListItem>
          </List>
        </Collapse> */}
        <ListItem button onClick={handleClickCarteirasDiarias}>
          <ListItemText primary="Carteira Diária" style={{ color: '#e2e2e2' }} />
          {openCarteiraDiaria ? (
            <ArrowDropDownIcon style={{ color: '#e2e2e2' }} />
          ) : (
            <ArrowRightIcon style={{ color: '#e2e2e2' }} />
          )}
        </ListItem>
        <Collapse in={openCarteiraDiaria} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button component={Link} to="/carteira_diaria/" sx={{ pl: 4 }}>
              <ListItemText primary="Carteira" style={{ color: '#e2e2e2' }} />
            </ListItem>
            <ListItem button component={Link} to="/carteira_diaria/resumo_diario" sx={{ pl: 4 }}>
              <ListItemText primary="Resumo Diário" style={{ color: '#e2e2e2' }} />
            </ListItem>
            <ListItem button component={Link} to="/carteira_diaria/ativos" sx={{ pl: 4 }}>
              <ListItemText primary="Ativos" style={{ color: '#e2e2e2' }}/>
            </ListItem>
            <ListItem button component={Link} to="/carteira_diaria/passivos" sx={{ pl: 4 }}>
              <ListItemText primary="Passivos" style={{ color: '#e2e2e2' }}/>
            </ListItem>
            {/* <ListItem button component={Link} to="/carteira_diaria/boletagem" sx={{ pl: 4 }}>
              <ListItemText primary="Boletagem Pré-Trade" style={{ color: '#e2e2e2' }}/>
            </ListItem> */}
            <ListItem button component={Link} to="/carteira_diaria/patrimonio" sx={{ pl: 4 }}>
              <ListItemText primary="Patrimônio Líquido" style={{ color: '#e2e2e2' }}/>
            </ListItem>
            <ListItem button component={Link} to="/carteira_diaria/saldotesouraria" sx={{ pl: 4 }}>
              <ListItemText primary="Caixa e/ou Tesouraria" style={{ color: '#e2e2e2' }}/>
            </ListItem>
          </List>
        </Collapse>
        <ListItem button onClick={handleClickAtivos}>
          <ListItemText primary="Ativos" style={{ color: '#e2e2e2' }}/>
          {openAtivos ? (
            <ArrowDropDownIcon style={{ color: '#e2e2e2' }} />
          ) : (
            <ArrowRightIcon style={{ color: '#e2e2e2' }} />
          )}
        </ListItem>
        <Collapse in={openAtivos} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button component={Link} to="/ativos/fundos_investimento" sx={{ pl: 4 }}>
              <ListItemText primary="Fundos de Investimento" style={{ color: '#e2e2e2' }}/>
            </ListItem>
            <ListItem button component={Link} to="/ativos/rendafixa_titulo_publico" sx={{ pl: 4 }}>
              <ListItemText primary="Renda Fixa - Título Público" style={{ color: '#e2e2e2' }}/>
            </ListItem>
            <ListItem button component={Link} to="/ativos/compromissada" sx={{ pl: 4 }}>
              <ListItemText primary="Compromissada" style={{ color: '#e2e2e2' }}/>
            </ListItem>
            <ListItem button component={Link} to="/ativos/rendafixa_bancario" sx={{ pl: 4 }}>
              <ListItemText primary="Renda Fixa - Bancario" style={{ color: '#e2e2e2' }}/>
            </ListItem>
            <ListItem button component={Link} to="/ativos/rendafixa_corporativo" sx={{ pl: 4 }}>
              <ListItemText primary="Renda Fixa - Corporativo" style={{ color: '#e2e2e2' }}/>
            </ListItem>
            <ListItem button component={Link} to="/ativos/rendafixa_securitizado" sx={{ pl: 4 }}>
              <ListItemText primary="Renda Fixa - Securitizado" style={{ color: '#e2e2e2' }}/>
            </ListItem>
            <ListItem button component={Link} to="/ativos/renda_variavel" sx={{ pl: 4 }}>
              <ListItemText primary="Renda Variável" style={{ color: '#e2e2e2' }} /> 
            </ListItem>
            <ListItem button component={Link} to="/ativos/adr" sx={{ pl: 4 }}>
              <ListItemText primary="ADR e Ações Offshore" style={{ color: '#e2e2e2' }}/>
            </ListItem>
          </List>
        </Collapse>
        <ListItem button component={Link} to="/passivos">
          <ListItemText primary="Passivos" style={{ color: '#e2e2e2' }}/>
        </ListItem>
        
      </List>
    </aside>
  );
};

export default Sidebar;