import React, { useEffect, useState } from 'react';
import Table from '../components/Table';
import Spinner from '../components/Spinner';
import { getFundosGerenciados } from '../services/fundosGerenciadosService';
import { FundosGerenciadosData } from '../types/fundosGerenciados';
import { ColumnDef } from '@tanstack/react-table';
import CreateModal from '../components/CreateFundoGerenciadoModal';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';

const FundosGerenciados: React.FC = () => {
  const [data, setData] = useState<FundosGerenciadosData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isCreateModalOpen, setCreateModalOpen] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getFundosGerenciados();
        setData(result);
      } catch (error) {
        console.error('Error fetching data', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleCreateModalClose = () => {
    setCreateModalOpen(false);
  };

  const handleNewFundoSave = (newFundo: FundosGerenciadosData) => {
    setData([...data, newFundo]);
  };

  const columns = React.useMemo<ColumnDef<FundosGerenciadosData>[]>(
    () => [
      {
        header: 'CNPJ',
        accessorKey: 'cnpj',
      },
      {
        header: 'Dados Preenchidos (%)',
        accessorKey: 'proporcao_campos_preenchidos',
        cell: ({ getValue }) => {
          const value = getValue() as number;
          return `${Math.round(value * 100)}%`; // Arredonda e exibe como porcentagem
        },
      },
      {
        header: 'Nome Fantasia',
        accessorKey: 'fantasia',
      },
      {
        header: 'Classificação CVM',
        accessorKey: 'classificacao_cvm',
      },
      {
        header: 'Segmento(Gestora)',
        accessorKey: 'segmento_gestora',
      },
      {
        header: 'Tipo Ativo(Gestora)',
        accessorKey: 'tipo_ativo_gestora',
      },
      // Adicione mais colunas conforme necessário
    ],
    []
  );

  if (loading) {
    return <Spinner />;
  }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h2>Fundos Gerenciados</h2>
        <IconButton onClick={() => setCreateModalOpen(true)} color="primary">
          <AddIcon />
        </IconButton>
      </div>
      <Table columns={columns} data={data} />
      {isCreateModalOpen && (
        <CreateModal
          isOpen={isCreateModalOpen}
          onRequestClose={handleCreateModalClose}
          onSave={handleNewFundoSave}
        />
      )}
    </div>
  );
};

export default FundosGerenciados;