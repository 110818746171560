import React, { useState } from 'react';
import {
  Box,
  Paper,
  Link,
  Button,
} from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import HistoryIcon from '@mui/icons-material/History';
import IncluirBoletaModal from './IncluirBoletaModal'; // Modal separado

const IncluirTabContent: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const rows = [
    {
      produto: 'Operações B3',
      acao: 'Incluir Boleta',
      historico: 'Histórico',
    },
    {
      produto: 'Bolsa',
      acao: 'Subir Excel (boleta em lote)',
      historico: 'Histórico',
    },
    {
      produto: 'Cotas de Fundos',
      acao: 'Incluir Boletagem Fundos',
      historico: 'Histórico',
    },
  ];

  const handleActionClick = (acao: string) => {
    if (acao === 'Incluir Boleta') {
      setIsModalOpen(true);
    }
  };

  return (
    <>
      <Paper elevation={3} sx={{ overflow: 'hidden' }}>
        <table
          style={{
            width: '100%',
            borderCollapse: 'collapse',
            tableLayout: 'fixed',
          }}
        >
          <colgroup>
            <col style={{ width: '30%' }} />
            <col style={{ width: '50%' }} />
            <col style={{ width: '20%' }} />
          </colgroup>
          <thead>
            <tr>
              <th
                colSpan={3}
                style={{
                  backgroundColor: '#002855',
                  color: 'white',
                  fontWeight: 'bold',
                  padding: '10px',
                  textAlign: 'left',
                }}
              >
                Produtos Brasil
              </th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index) => (
              <tr
                key={index}
                style={{
                  backgroundColor: index % 2 === 0 ? '#f9f9f9' : 'white',
                }}
              >
                <td
                  style={{
                    fontWeight: 'bold',
                    padding: '10px',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {row.produto}
                </td>
                <td
                  style={{
                    padding: '10px',
                  }}
                >
                  <Box display="flex" alignItems="center" gap={1}>
                    <UploadFileIcon color="primary" />
                    <Link
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        handleActionClick(row.acao);
                      }}
                      style={{
                        textDecoration: 'none',
                        color: '#007bff',
                        fontWeight: 'bold',
                      }}
                    >
                      {row.acao}
                    </Link>
                  </Box>
                </td>
                <td
                  style={{
                    textAlign: 'right',
                    padding: '10px',
                  }}
                >
                  <Button
                    variant="text"
                    startIcon={<HistoryIcon />}
                    sx={{
                      color: '#002855',
                      fontWeight: 'bold',
                      '&:hover': { backgroundColor: '#eaeaea' },
                    }}
                  >
                    {row.historico}
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Paper>

      {/* Modal separado */}
      <IncluirBoletaModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
      />
    </>
  );
};

export default IncluirTabContent;
