import React from 'react';

interface Column {
  header: string; // Título da coluna
  accessor: string; // Nome da chave no objeto `data`
  isNumeric?: boolean; // Define se o valor é numérico
  isCurrency?: boolean; // Define se o valor é monetário
}

interface TableProps {
  columns: Column[];
  data: any[];
  title?: string; // Título opcional da tabela
}

const TableResumoDiario: React.FC<TableProps> = ({ columns, data, title }) => {
  // Função para formatar valores monetários
  const formatCurrency = (value: number) => {
    if (isNaN(value)) return value; // Retorna valor bruto se não for numérico
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  };

  // Função para formatar números
  const formatNumber = (value: number) => {
    if (isNaN(value)) return value; // Retorna valor bruto se não for numérico
    return value.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
  };


  return (
    <div style={{ marginTop: '20px' }}>
      {title && <h3>{title}</h3>}
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            {columns.map((column) => (
              <th
                key={column.accessor}
                style={{
                  borderBottom: '2px solid #ddd',
                  textAlign: column.isNumeric ? 'right' : 'left',
                  padding: '10px',
                }}
              >
                {column.header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ? (
            data.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {columns.map((column) => {
                  const cellValue = row[column.accessor];
                  return (
                    <td
                      key={column.accessor}
                      style={{
                        padding: '10px',
                        borderBottom: '1px solid #ddd',
                      }}
                    >
                      {column.isCurrency
                        ? formatCurrency(Number(cellValue)) // Garante que o valor seja numérico
                        : column.isNumeric
                        ? formatNumber(Number(cellValue)) // Garante que o valor seja numérico
                        : cellValue}
                    </td>
                  );
                })}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={columns.length} style={{ textAlign: 'center', padding: '20px' }}>
                Nenhum dado encontrado.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TableResumoDiario;
