// pages/LoginPage.tsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { authService } from '../services/authService';
import { Container, Box, TextField, Button, Typography, Alert } from '@mui/material';
import './LoginPage.css'; // Importa o CSS separado
import logo_home from '../assets/img/logo_home.png';

const LoginPage: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await authService.login(email, password);
      navigate('/');  // Redireciona para a página principal após login
    } catch (err) {
      setError('Falha no login. Verifique suas credenciais.');
    }
  };

  return (
    <Container maxWidth="xs">
      <Box className="login-container">
        {/* Área para o logo */}
        <img src={logo_home} alt="Logo" className='img-cut' />
        <Box component="form" onSubmit={handleLogin} noValidate className="login-form">
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Senha"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {error && <Alert severity="error" className="login-error">{error}</Alert>}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            className="login-button"
          >
            Entrar
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default LoginPage;
