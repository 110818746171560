import React, { useState, useEffect } from 'react';
import { Box, Button, Modal, TextField, Typography, Grid, FormControlLabel, Switch, Divider, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import logo from '../../assets/img/logo.png';
import { updateAtivo, getCondominios, getDistribuicoes, getTributacoes, getStatus, getTiposAtivo, getNacionalidades, getBenchmarks } from '../../services/ativosService'; // Importe o serviço genérico

interface EditModalAtivosProps {
  isOpen: boolean;
  onRequestClose: () => void;
  rowData: any;
  segmentoNome?: any;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxWidth: '1500px',
  maxHeight: '80vh',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
};

const logoStyle = {
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: '20px',
  width: '160px',
};

const EditModalAtivos: React.FC<EditModalAtivosProps> = ({ isOpen, onRequestClose, rowData, segmentoNome }) => {
  const [formData, setFormData] = useState(rowData);
  const [condominios, setCondominios] = useState([]);
  const [tiposAtivos, setTiposAtivos] = useState<any[]>([]);
  const [tributacoes, setTributacoes] = useState<any[]>([]);
  const [statusList, setStatusList] = useState<any[]>([]);
  const [distribuicoes, setDistribuicoes] = useState<any[]>([]);
  const [nacionalidades, setNacionalidades] = useState<any[]>([]);
  const [benchmarks, setBenchmarks] = useState<any[]>([]);

  // Sincroniza o estado do formData com o rowData que vem de TableAtivos
  useEffect(() => {
    setFormData(rowData);
  }, [rowData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [condominioData, tipoAtivoData, tributacaoData, statusData, distribuicaoData, nacionalidadeData, benchmarkData] = await Promise.all([
          getCondominios(),
          getTiposAtivo(),
          getTributacoes(),
          getStatus(),
          getDistribuicoes(),
          getNacionalidades(),
          getBenchmarks(),
        ]);
        setCondominios(condominioData);
        setTiposAtivos(tipoAtivoData);
        setTributacoes(tributacaoData);
        setStatusList(statusData);
        setDistribuicoes(distribuicaoData);
        setNacionalidades(nacionalidadeData);
        setBenchmarks(benchmarkData);
      } catch (error) {
        console.error('Erro ao carregar os dados:', error);
      }
    };

    fetchData(); // Carrega todos os dados necessários para os selects
  }, []);
  // Função para lidar com mudanças nos campos de texto
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  
  // @ts-ignore
  const handleSelectChange = (name: string) => (e: SelectChangeEvent) => {
    setFormData({
      ...formData,
      [name]: e.target.value,
    });
  };
  const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;  // 'checked' retorna o valor booleano do switch
    setFormData({
      ...formData,
      [name]: checked,  // Atualiza o valor booleano do campo correspondente no formData
    });
  };

  // Função para submeter os dados atualizados
  const handleSubmit = async () => {
    try {
      await updateAtivo(segmentoNome, formData.id, formData);  // Chama o serviço genérico de atualização
      onRequestClose(); // Fecha o modal após o sucesso
    } catch (error) {
      console.error('Erro ao atualizar o ativo:', error);
    }
  };

  // Renderiza campos dinamicamente com base na segmentoNome (fundo, renda-variavel, etc.)
  const renderFieldsBysegmentoNome = () => {
    switch (segmentoNome) {
      case 'fundo':
        return (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle1">Códigos Identificadores</Typography>
                <Divider sx={{ mb: 2 }} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Nome"
                  name="nome"
                  value={formData.nome || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              {/* Identificadores */}
              <Grid item xs={12} sm={4}>
                <TextField
                  label="CNPJ"
                  name="cnpj"
                  value={formData.cnpj || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Razão Social"
                  name="razao_social"
                  value={formData.razao_social || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Nome Fantasia"
                  name="fantasia"
                  value={formData.fantasia || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Código ANBIMA"
                  name="anbima"
                  value={formData.anbima || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Código CVM"
                  name="cvm"
                  value={formData.cvm || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle1">Classificações</Typography>
                <Divider sx={{ mb: 2 }} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Classificação CVM"
                  name="classificacao_cvm"
                  value={formData.classificacao_cvm || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Classificação ANBIMA"
                  name="classificacao_anbima"
                  value={formData.classificacao_anbima || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Classificação de Risco Gestora"
                  name="risco_gestora"
                  value={formData.risco_gestora || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Número de Risco Mont"
                  name="numero_risco_mont"
                  value={formData.numero_risco_mont || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Tipo Ativo</InputLabel>
                  <Select
                    value={formData.tipo_ativo || ''}
                    onChange={handleSelectChange('tipo_ativo')}
                    label="TipoAtivo"
                  >
                    {tiposAtivos.map((tipo_ativo) => (
                      <MenuItem key={tipo_ativo.id} value={tipo_ativo.id}>
                        {tipo_ativo.nome}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Nacionalidade</InputLabel>
                  <Select
                    value={formData.nacionalidade || ''}
                    onChange={handleSelectChange('nacionalidade')}
                    label="TipoAtivo"
                  >
                    {nacionalidades.map((nacionalidade) => (
                      <MenuItem key={nacionalidade.id} value={nacionalidade.id}>
                        {nacionalidade.origem}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle1">Remuneração</Typography>
                <Divider sx={{ mb: 2 }} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Taxa de Administração"
                  name="taxa_administracao"
                  type="number"
                  value={formData.taxa_administracao || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                  inputProps={{
                    step: 0.01,
                    min: 0,
                    max: 100,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Taxa de Performance"
                  name="taxa_performance"
                  type="number"
                  value={formData.taxa_performance || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                  inputProps={{
                    step: 0.01,
                    min: 0,
                    max: 100,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Benchmark</InputLabel>
                  <Select
                    value={formData.benchmark || ''}
                    onChange={handleSelectChange('benchmark')}
                    label="TipoAtivo"
                  >
                    {benchmarks.map((benchmark) => (
                      <MenuItem key={benchmark.id} value={benchmark.id}>
                        {benchmark.nome}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Referência Benchmark"
                  name="referencia_benchmark"
                  value={formData.referencia_benchmark || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Taxa de Custódia"
                  name="taxa_custodia"
                  value={formData.taxa_custodia || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                  type="number"
                  inputProps={{
                    step: 0.01,
                    min: 0,
                    max: 100,
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle1">Distribuição</Typography>
                <Divider sx={{ mb: 2 }} />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Condomínio</InputLabel>
                  <Select
                    value={formData.condominio || ''}
                    onChange={handleSelectChange('condominio')}
                    label="Condomínio"
                  >
                    {condominios.map((condominio: any) => (
                      <MenuItem key={condominio.id} value={condominio.id}>
                        {condominio.tipo}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Status</InputLabel>
                  <Select
                    value={formData.status || ''}
                    onChange={handleSelectChange('status')}
                    label="Status"
                  >
                    {statusList.map((status: any) => (
                      <MenuItem key={status.id} value={status.id}>
                        {status.estado}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Tributação</InputLabel>
                  <Select
                    value={formData.status || ''}
                    onChange={handleSelectChange('tributacao')}
                    label="Tributação"
                  >
                    {tributacoes.map((tributacao: any) => (
                      <MenuItem key={tributacao.id} value={tributacao.id}>
                        {tributacao.tipo}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Data 1° Aporte"
                  name="data_primeiro_aporte"
                  type="date"
                  value={formData.data_primeiro_aporte || ''}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Código Mont"
                  name="codigo_mont"
                  value={formData.codigo_mont || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Descrição da Carteira"
                  name="descricao_carteira"
                  value={formData.descricao_carteira || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                />
              </Grid>
            {/* Campos para Condominio Aberto = 1 */}
            {formData.condominio === 1 && ( 
          <>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Captação"
                  name="captacao"
                  value={formData.captacao || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Cotização Aplicação"
                  name="cotizacao_aplicacao"
                  value={formData.cotizacao_aplicacao || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Cotização Resgate"
                  name="cotizacao_resgate"
                  value={formData.cotizacao_resgate || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Liquidação Resgate"
                  name="liquidacao_resgate"
                  value={formData.liquidacao_resgate || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Aplicação Inicial Mínima (R$)"
                  name="aplicacao_inicial_minima"
                  value={formData.aplicacao_inicial_minima || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Movimentação Mínima (R$)"
                  name="movimentacao_minima"
                  value={formData.movimentacao_minima || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Saldo Mínimo (R$)"
                  name="saldo_minimo"
                  value={formData.saldo_minimo || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                />
              </Grid>
            </>
          )}
          {formData.condominio === 2 && (
            <>
              {/* Condomínio Fechado */}
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Com Subclasse</InputLabel>
                  <Select
                    value={formData.com_subclasse ? 'Sim' : 'Não'} // Define o valor com base no estado booleano
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        com_subclasse: e.target.value === 'Sim', // Atualiza o estado com true para "Sim" e false para "Não"
                      });
                    }}
                    label="Com Subclasse"
                  >
                    <MenuItem value="Sim">Sim</MenuItem>
                    <MenuItem value="Não">Não</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Data Resgate"
                  name="data_resgate"
                  type="date"
                  value={formData.data_resgate || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Data Liquidação"
                  name="data_liquidacao"
                  type="date"
                  value={formData.data_liquidacao || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Aplicação Mínima (R$)"
                  name="aplicacao_minima"
                  value={formData.aplicacao_minima || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                />
              </Grid>
            </>
          )}
            </Grid>
          
          </>
        );
      case 'renda-fixa-titulos-publicos':
        return (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Nome"
                  name="nome"
                  value={formData.nome || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Código ISIN"
                  name="codigo_isin"
                  value={formData.codigo_isin || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Indexador"
                  name="indexador"
                  value={formData.indexador || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Data de Vencimento"
                  name="data_vencimento"
                  type="date"
                  value={formData.data_vencimento || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Taxa"
                  name="taxa"
                  value={formData.taxa || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                  type="number"
                  inputProps={{
                    step: 0.01,
                    min: 0,
                    max: 100,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Tipo Ativo</InputLabel>
                  <Select
                    value={formData.tipo_ativo || ''}
                    onChange={handleSelectChange('tipo_ativo')}
                    label="TipoAtivo"
                  >
                    {tiposAtivos.map((tipo_ativo) => (
                      <MenuItem key={tipo_ativo.id} value={tipo_ativo.id}>
                        {tipo_ativo.nome}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </>
        );
      case 'compromissada':
        return (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Nome"
                  name="nome"
                  value={formData.nome || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Código ISIN"
                  name="codigo_isin"
                  value={formData.codigo_isin || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Indexador"
                  name="indexador"
                  value={formData.indexador || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Data de Vencimento"
                  name="data_vencimento"
                  type="date"
                  value={formData.data_vencimento || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Taxa"
                  name="taxa"
                  value={formData.taxa || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                  type="number"
                  inputProps={{
                    step: 0.01,
                    min: 0,
                    max: 100,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Tipo Ativo</InputLabel>
                  <Select
                    value={formData.tipo_ativo || ''}
                    onChange={handleSelectChange('tipo_ativo')}
                    label="TipoAtivo"
                  >
                    {tiposAtivos.map((tipo_ativo) => (
                      <MenuItem key={tipo_ativo.id} value={tipo_ativo.id}>
                        {tipo_ativo.nome}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </>
        );
      case 'renda-fixa-bancario':
        return (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Nome"
                  name="nome"
                  value={formData.nome || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Código ISIN"
                  name="codigo_isin"
                  value={formData.codigo_isin || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Nome Título"
                  name="nome_titulo"
                  value={formData.nome_titulo || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Instituição Emitente"
                  name="instituicao_emitente"
                  value={formData.instituicao_emitente || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Descrição do Ativo"
                  name="descricao_ativo"
                  value={formData.descricao_ativo || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Indexador"
                  name="indexador"
                  value={formData.indexador || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Data de Vencimento"
                  name="data_vencimento"
                  type="date"
                  value={formData.data_vencimento || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Taxa"
                  name="taxa"
                  value={formData.taxa || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                  type="number"
                  inputProps={{
                    step: 0.01,
                    min: 0,
                    max: 100,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Tipo Ativo</InputLabel>
                  <Select
                    value={formData.tipo_ativo || ''}
                    onChange={handleSelectChange('tipo_ativo')}
                    label="TipoAtivo"
                  >
                    {tiposAtivos.map((tipo_ativo) => (
                      <MenuItem key={tipo_ativo.id} value={tipo_ativo.id}>
                        {tipo_ativo.nome}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </>
        );
      case 'renda-fixa-corporativo':
        return (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Nome"
                  name="nome"
                  value={formData.nome || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Código ISIN"
                  name="codigo_isin"
                  value={formData.codigo_isin || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Ticker B3"
                  name="ticker_b3"
                  value={formData.ticker_b3 || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Nome Título"
                  name="nome_titulo"
                  value={formData.nome_titulo || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Instituição Emitente"
                  name="instituicao_emitente"
                  value={formData.instituicao_emitente || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Descrição do Ativo"
                  name="descricao_ativo"
                  value={formData.descricao_ativo || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Indexador"
                  name="indexador"
                  value={formData.indexador || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Data de Vencimento"
                  name="data_vencimento"
                  type="date"
                  value={formData.data_vencimento || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Taxa"
                  name="taxa"
                  value={formData.taxa || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                  type="number"
                  inputProps={{
                    step: 0.01,
                    min: 0,
                    max: 100,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Tipo Ativo</InputLabel>
                  <Select
                    value={formData.tipo_ativo || ''}
                    onChange={handleSelectChange('tipo_ativo')}
                    label="TipoAtivo"
                  >
                    {tiposAtivos.map((tipo_ativo) => (
                      <MenuItem key={tipo_ativo.id} value={tipo_ativo.id}>
                        {tipo_ativo.nome}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </>
        );
      case 'renda-fixa-securitizado':
        return (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Nome"
                  name="nome"
                  value={formData.nome || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Código ISIN"
                  name="codigo_isin"
                  value={formData.codigo_isin || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Ticker B3"
                  name="ticker_b3"
                  value={formData.ticker_b3 || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Nome Título"
                  name="nome_titulo"
                  value={formData.nome_titulo || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Instituição Emitente"
                  name="instituicao_emitente"
                  value={formData.instituicao_emitente || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Característica"
                  name="caracteristica"
                  value={formData.caracteristica || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Descrição do Ativo"
                  name="descricao_ativo"
                  value={formData.descricao_ativo || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Indexador"
                  name="indexador"
                  value={formData.indexador || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Data de Vencimento"
                  name="data_vencimento"
                  type="date"
                  value={formData.data_vencimento || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Taxa"
                  name="taxa"
                  value={formData.taxa || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                  type="number"
                  inputProps={{
                    step: 0.01,
                    min: 0,
                    max: 100,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Tipo Ativo</InputLabel>
                  <Select
                    value={formData.tipo_ativo || ''}
                    onChange={handleSelectChange('tipo_ativo')}
                    label="TipoAtivo"
                  >
                    {tiposAtivos.map((tipo_ativo) => (
                      <MenuItem key={tipo_ativo.id} value={tipo_ativo.id}>
                        {tipo_ativo.nome}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </>
        );
      case 'renda-variavel':
        return (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Nome"
                  name="nome"
                  value={formData.nome || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Código ISIN"
                  name="codigo_isin"
                  value={formData.codigo_isin || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Ticker B3"
                  name="ticker_b3"
                  value={formData.ticker_b3 || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Descrição do Ticker B3"
                  name="descricao_ticker"
                  value={formData.descricao_ticker || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Empresa Nome CVM"
                  name="empresa_nome_cvm"
                  value={formData.empresa_nome_cvm || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Tipo Ativo</InputLabel>
                  <Select
                    value={formData.tipo_ativo || ''}
                    onChange={handleSelectChange('tipo_ativo')}
                    label="TipoAtivo"
                  >
                    {tiposAtivos.map((tipo_ativo) => (
                      <MenuItem key={tipo_ativo.id} value={tipo_ativo.id}>
                        {tipo_ativo.nome}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </>
        );
      case 'adr':
        return (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Nome"
                  name="nome"
                  value={formData.nome || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Papel"
                  name="papel"
                  value={formData.papel || ''}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Tipo Ativo</InputLabel>
                  <Select
                    value={formData.tipo_ativo || ''}
                    onChange={handleSelectChange('tipo_ativo')}
                    label="TipoAtivo"
                  >
                    {tiposAtivos.map((tipo_ativo) => (
                      <MenuItem key={tipo_ativo.id} value={tipo_ativo.id}>
                        {tipo_ativo.nome}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </>
        )
      default:
        return <Typography>Tipo de ativo não suportado.</Typography>;
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={onRequestClose}
      aria-labelledby="edit-modal-title"
      aria-describedby="edit-modal-description"
    >
      <Box
        sx={style}
      >
        <img src={logo} alt="Logo" style={logoStyle} />
        <Typography id="edit-modal-title" variant="h6" component="h2">
          Editar {segmentoNome.charAt(0).toUpperCase() + segmentoNome.slice(1)}
        </Typography>

        {/* Renderiza os campos com base na segmentoNome */}
        {renderFieldsBysegmentoNome()}

        <Box sx={{ mt: 2 }}>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Salvar Alterações
          </Button>
          <Button
            variant="outlined"
            color="error"
            onClick={onRequestClose}
            sx={{ ml: 2 }}
          >
            Cancelar
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default EditModalAtivos;
