import api from './api'; // Importa a instância configurada do axios
import { User } from '../types/user'; // Importa o tipo de dados

// **GET**: Lista todos os usuários
export const getUsers = async (): Promise<User[]> => {
  try {
    const response = await api.get<{ results: User[] }>('/core/users/');
    return response.data.results;
  } catch (error) {
    console.error('Erro ao obter usuários:', error);
    throw new Error('Não foi possível obter a lista de usuários.');
  }
};

export const toggleUserActive = async (id: number, isActive: boolean): Promise<User> => {
  const response = await api.patch<User>(`/core/users/${id}/`, { is_active: isActive });
  return response.data;
};

export const getUserById = async (id: number): Promise<User> => {
  const response = await api.get<User>(`/core/users/${id}/`);
  return response.data;
};

export const createUser = async (userData: Partial<User>): Promise<User> => {
  const response = await api.post<User>('/core/users/', userData);
  return response.data;
};

export const updateUser = async (id: number, userData: Partial<User>): Promise<User> => {
  const response = await api.put<User>(`/core/users/${id}/`, userData);
  return response.data;
};

export const deleteUser = async (id: number): Promise<void> => {
  await api.delete(`/core/users/${id}/`);
}