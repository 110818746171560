import React from 'react';
import { Grid, Typography, Box, Card, CardContent, Divider, ListItemText, ListItem, List } from '@mui/material';
import { ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import AtivosPieChart from './AtivosPieChart';

const AtivoSection: React.FC = () => {
  return (
    <Box mb={4}>
      <Typography variant="h6" fontWeight="bold" color="#002855" mb={2}>
        Ativo
      </Typography>
      <Grid container spacing={3}>
        {/* Cálculo de Cotas */}
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ height: '100%' }}>
            <AtivosPieChart />
          </Card>
        </Grid>

        {/* Caixa Online */}
        <Grid item xs={12} sm={6} md={3}>
            <Card sx={{ height: '100%' }}>
                <CardContent>
                <Typography variant="subtitle1" fontWeight="bold" textAlign="center" gutterBottom>
                    Caixa Online
                </Typography>
                <Divider sx={{ my: 1 }} />
                <Box display="flex" flexDirection="column" gap={2}>
                    {/* Contas Positivas */}
                    <Box display="flex" alignItems="center" gap={1} sx={{ border: '1px solid #E0E0E0', borderRadius: '8px', padding: '8px' }}>
                    <Box sx={{ width: 8, height: 32, backgroundColor: '#4CAF50', borderRadius: '4px' }} />
                    <Box>
                        <Typography variant="body2" fontWeight="bold">
                        Contas positivas
                        </Typography>
                        <Box display="flex" alignItems="center" gap={0.5}>
                        <Typography variant="h6" fontWeight="bold">
                            0
                        </Typography>
                        <Typography variant="body2">/ R$ 0,00</Typography>
                        </Box>
                    </Box>
                    </Box>

                    {/* Contas Zeradas */}
                    <Box display="flex" alignItems="center" gap={1} sx={{ border: '1px solid #E0E0E0', borderRadius: '8px', padding: '8px' }}>
                    <Box sx={{ width: 8, height: 32, backgroundColor: '#FFC107', borderRadius: '4px' }} />
                    <Box>
                        <Typography variant="body2" fontWeight="bold">
                        Contas zeradas
                        </Typography>
                        <Box display="flex" alignItems="center" gap={0.5}>
                        <Typography variant="h6" fontWeight="bold">
                            0
                        </Typography>
                        <Typography variant="body2">/ R$ 0,00</Typography>
                        </Box>
                    </Box>
                    </Box>

                    {/* Contas Negativas */}
                    <Box display="flex" alignItems="center" gap={1} sx={{ border: '1px solid #E0E0E0', borderRadius: '8px', padding: '8px' }}>
                    <Box sx={{ width: 8, height: 32, backgroundColor: '#F44336', borderRadius: '4px' }} />
                    <Box>
                        <Typography variant="body2" fontWeight="bold">
                        Contas negativas
                        </Typography>
                        <Box display="flex" alignItems="center" gap={0.5}>
                        <Typography variant="h6" fontWeight="bold">
                            0
                        </Typography>
                        <Typography variant="body2">/ R$ 0,00</Typography>
                        </Box>
                    </Box>
                    </Box>
                </Box>
                </CardContent>
            </Card>
        </Grid>

        {/* Portfólios Investidos Pendentes */}
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ height: '100%' }}>
            <CardContent>
            <Typography variant="subtitle1" fontWeight="bold" textAlign="center" gutterBottom>
              Portfólios Investidos Pendentes
            </Typography>
            <Divider sx={{ my: 1 }} />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                width: '100%',
                height: 250,
              }}
            >
              {/* Outer Circle */}
              <Box
                sx={{
                  width: 250,
                  height: 200,
                  borderRadius: '50%',
                  backgroundColor: '#B3D9FF',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'flex-start', // Move o texto para cima
                  paddingTop: '20px', // Adiciona espaçamento extra para ajustar o texto
                  position: 'relative',
                }}
              >
                <Box sx={{ textAlign: 'center', zIndex: 1 }}>
                  <Typography variant="h4" fontWeight="bold">
                    0
                  </Typography>
                  <Typography variant="subtitle2" sx={{ fontSize: '0.9rem' }}>
                    PIs pendentes
                  </Typography>
                </Box>
              </Box>

              {/* Inner Circle */}
              <Box
                sx={{
                  position: 'absolute',
                  top: '60%', // Ajusta o círculo interno mais para baixo
                  width: 120,
                  height: 120,
                  borderRadius: '50%',
                  backgroundColor: '#002855',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'white',
                  zIndex: 2,
                  transform: 'translateY(-30%)', // Ajusta a centralização vertical para balancear o posicionamento
                }}
              >
                <Box sx={{ textAlign: 'center', px: 1 }}>
                  <Typography variant="h4" fontWeight="bold">
                    0
                  </Typography>
                  <Typography variant="subtitle2" fontSize="0.8rem">
                    Fundos com PIs pendentes
                  </Typography>
                </Box>
              </Box>
            </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Eventos Corporativos */}
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ height: '100%' }}>
            <CardContent>
              <Typography variant="subtitle1" fontWeight="bold" textAlign="center" gutterBottom>
                Eventos Corporativos
              </Typography>
              <Divider sx={{ my: 1 }} />
              {/* Barra de status */}
              <Box
                sx={{
                  backgroundColor: '#FFC107',
                  padding: '5px',
                  borderRadius: 1,
                  textAlign: 'center',
                }}
              >
                <Typography variant="body2" fontWeight="bold">
                  0 eventos pendentes
                </Typography>
              </Box>
              {/* Gráfico */}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'relative',
                  mt: 2,
                }}
              >
                <ResponsiveContainer width={200} height={200}>
                  <PieChart>
                    <Pie
                      data={[
                        { name: 'Subscrição', value: 0, color: '#1565C0' },
                        { name: 'Sobras', value: 0, color: '#4CAF50' },
                      ]}
                      cx="50%"
                      cy="50%"
                      innerRadius={50}
                      outerRadius={80}
                      paddingAngle={2}
                      dataKey="value"
                      label={({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
                        const RADIAN = Math.PI / 180;
                        const radius = innerRadius + (outerRadius - innerRadius) * 1.2;
                        const x = cx + radius * Math.cos(-midAngle * RADIAN);
                        const y = cy + radius * Math.sin(-midAngle * RADIAN);

                        return (
                          percent > 0 && (
                            <text
                              x={x}
                              y={y}
                              fill="black"
                              textAnchor={x > cx ? 'start' : 'end'}
                              dominantBaseline="central"
                              fontSize={10}
                            >
                              {`${(percent * 100).toFixed(0)}%`}
                            </text>
                          )
                        );
                      }}
                    >
                      {[
                        { name: 'Subscrição', value: 0, color: '#1565C0' },
                        { name: 'Sobras', value: 0, color: '#4CAF50' },
                      ].map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
                {/* Texto no centro */}
                <Box
                  position="absolute"
                  top="65%"
                  left="50%"
                  sx={{
                    transform: 'translate(-50%, -50%)',
                    textAlign: 'center',
                  }}
                >
                  <Typography variant="body2">Total</Typography>
                  <Typography variant="h6" fontWeight="bold">
                    0
                  </Typography>
                </Box>
              </Box>
              {/* Legenda */}
              <List sx={{ mt: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {[
                  { name: 'Subscrição', value: 0, color: '#1565C0' },
                  { name: 'Sobras', value: 0, color: '#4CAF50' },
                ].map((item, index) => (
                  <ListItem key={index} disableGutters>
                    <Box
                      sx={{
                        width: 12,
                        height: 12,
                        backgroundColor: item.color,
                        borderRadius: '50%',
                        mr: 1,
                      }}
                    />
                    <ListItemText
                      primary={
                        <Typography variant="body2" fontWeight="bold">
                          {item.name}
                        </Typography>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AtivoSection;
