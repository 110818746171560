import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import logo from '../../assets/img/logo.png';
import { getFundosGerenciados } from '../../services/fundosGerenciadosService'; // Serviço para buscar fundos

interface DetailModalCarteirasProps {
  isOpen: boolean;
  onRequestClose: () => void;
  rowData?: any;
  tipoCarteira?: string;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxWidth: '1500px',
  maxHeight: '80vh',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
};

const logoStyle = {
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: '20px',
  width: '160px',
};

// Função para renderizar seções de dados
const renderSection = (title: string, fields: { label: string, value: any }[]) => (
  <>
    <Typography variant="h6">
      <strong>{title}</strong>
    </Typography>
    <Divider sx={{ my: 1 }} />
    <Grid container spacing={2} sx={{ mb: 4 }}>
      {fields.map((field, index) => (
        <Grid item xs={4} key={index}>
          <Typography variant="body2" gutterBottom>
            <strong>{field.label}:</strong> {field.value || 'N/A'}
          </Typography>
        </Grid>
      ))}
    </Grid>
  </>
);

const DetailModalCarteiras: React.FC<DetailModalCarteirasProps> = ({ isOpen, onRequestClose, rowData, tipoCarteira }) => {
  const [fundosGerenciados, setFundosGerenciados] = useState<{ id: number; fantasia: string }[]>([]);

  useEffect(() => {
    if (isOpen) {
      getFundosGerenciados().then(setFundosGerenciados); // Buscando os fundos gerenciados
    }
  }, [isOpen]);

  const getNomeFundoById = (id: number, list: { id: number; fantasia: string }[]) => {
    const fundo = list.find(f => f.id === id);
    return fundo ? fundo.fantasia : 'Fundo desconhecido';
  };

  const getTipoCarteira = () => {
    switch (tipoCarteira) {
      case 'carteira-diaria-ativos':
        return [
          { label: 'Nome', value: rowData?.nome },
          { label: 'Admin', value: rowData?.adm },
          { label: 'Situação', value: rowData?.situacao },
          { label: 'Código 1', value: rowData?.codigo1 },
          { label: 'Código 2', value: rowData?.codigo2 },
          { label: 'Data Posição', value: rowData?.data_posicao },
          { label: 'Quantidade', value: rowData?.quantidade },
          { label: 'Valor por Unidade', value: rowData?.valor_por_unidade },
          { label: 'Valor Total', value: rowData?.valor_total },
          { label: 'Valor de Proventos', value: rowData?.proventos },
          { label: 'Custo Médio', value: rowData?.custo_medio },
          { label: 'Resultado do Dia', value: rowData?.resultado_dia },
          { label: 'LN RENT Ativo', value: rowData?.ln_rent_ativo },
          { label: 'LN RENT Ativo 2', value: rowData?.ln_rent_ativo_2 },
          { label: 'RENT FUNDO IBOV', value: rowData?.rent_fund_ibov },
          { label: 'RENT FUNDO Index 1', value: rowData?.rent_fund_index_1 },
          { label: 'RENT FUNDO Index 2', value: rowData?.rent_fund_index_2 },
          { label: 'Fundo Associado', value: getNomeFundoById(rowData?.fundo, fundosGerenciados) },
        ];

      case 'carteira-diaria-passivo':
        return [
          { label: 'Nome', value: rowData?.nome},
          { label: 'Categoria', value: rowData?.categoria},
          { label: 'Data Posição', value: rowData?.data_posicao},
          { label: 'Valor Total(R$)', value: rowData?.valor_total},
          { label: 'Situação', value: rowData?.situacao},
          { label: 'Fundo Associado', value: getNomeFundoById(rowData?.fundo, fundosGerenciados) },
        ];

      case 'carteira-diaria-patrimonio-liquido':
        return [
          { label: 'Fundo Associado', value: getNomeFundoById(rowData?.fundo, fundosGerenciados) },
          { label: 'Data Posição', value: rowData?.data_posicao },
          { label: 'Patrimônio Líquido(CALC)', value: rowData?.patrimonio_liquido_calculado },
          { label: 'Quantidade', value: rowData?.quantidade },
          { label: 'Valor por Unidade', value: rowData?.valor_por_unidade },
          { label: 'Valor Total(API)', value: rowData?.patrimonio_liquido_api },
          { label: 'Valor de Proventos', value: rowData?.proventos },
          { label: 'Custo Médio', value: rowData?.custo_medio },
          { label: 'Resultado do Dia', value: rowData?.resultado_dia },
          { label: 'LN RENT Ativo', value: rowData?.ln_rent_ativo },
          { label: 'LN RENT Ativo 2', value: rowData?.ln_rent_ativo_2 },
          { label: 'RENT FUNDO IBOV', value: rowData?.rent_fund_ibov },
          { label: 'RENT FUNDO Index 1', value: rowData?.rent_fund_index_1 },
          { label: 'RENT FUNDO Index 2', value: rowData?.rent_fund_index_2 },
        ];

        case 'saldo-tesouraria':
          return [
            { label: 'Descrição', value: rowData?.descricao},
            { label: 'Admin', value: rowData?.adm},
            { label: 'Data Posição', value: rowData?.data_posicao},
            { label: 'Valor Total(R$)', value: rowData?.financeiro},
            { label: 'Situação', value: rowData?.situacao},
            { label: 'Fundo Associado', value: getNomeFundoById(rowData?.fundo, fundosGerenciados) },
          ]
    }
    
    return [{ label: 'Informações', value: 'Não disponíveis para este segmento' }];
  };

  return (
    <Modal open={isOpen} onClose={onRequestClose}>
      <Box sx={style}>
        <img src={logo} alt="Logo" style={logoStyle} />
        
        {/* Renderizando os campos da carteira */}
        {renderSection('Detalhes da Carteira', getTipoCarteira())}

        <Button onClick={onRequestClose} variant="contained" sx={{ mt: 2 }}>
          Fechar
        </Button>
      </Box>
    </Modal>
  );
};

export default DetailModalCarteiras;
