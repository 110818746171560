import React from 'react';
import { Box } from '@mui/material';
import AtivoSection from './AtivoSection';
import PassivoSection from './PassivoSection';
import RequisicaoSection from './RequisicaoSection';

const Home: React.FC = () => (
  <Box p={3}>
    {/* Ativo Section */}
    <AtivoSection />

    {/* Passivo Section */}
    <Box mt={4}>
      <PassivoSection />
    </Box>

    {/* Requisições Section */}
    <Box mt={4}>
      <RequisicaoSection />
    </Box>
    {/* Outras seções como Requisições podem ser adicionadas aqui */}
  </Box>
);

export default Home;
