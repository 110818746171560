import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { Card, CardContent, Typography, Box, Divider, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';

const data = [
  { name: 'Liberados', value: 5, color: '#4CAF50' },
  { name: 'Pendentes', value: 1, color: '#FFC107' },
  { name: 'Aguardando aprovação', value: 0, color: '#2196F3' },
];

const COLORS = data.map((item) => item.color);

const AtivosPieChart: React.FC = () => {
  return (
    <Card sx={{ height: '100%' }}>
      <CardContent>
        <Typography variant="subtitle1" fontWeight="bold" textAlign="center" gutterBottom>
          Cálculo de Cotas
        </Typography>
        <Divider sx={{ my: 1 }} />
        <Box position="relative" display="flex" justifyContent="center" alignItems="center">
          <ResponsiveContainer width="100%" height={250}>
            <PieChart>
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                innerRadius={50}
                outerRadius={80}
                fill="#8884d8"
                paddingAngle={2}
                dataKey="value"
                label={({ cx, cy, midAngle, innerRadius, outerRadius, value }) => {
                  const RADIAN = Math.PI / 180;
                  const radius = outerRadius + 20;
                  const x = cx + radius * Math.cos(-midAngle * RADIAN);
                  const y = cy + radius * Math.sin(-midAngle * RADIAN);

                  return (
                    <text
                      x={x}
                      y={y}
                      fill="black"
                      textAnchor={x > cx ? 'start' : 'end'}
                      dominantBaseline="central"
                      fontSize={12}
                    >
                      {value}
                    </text>
                  );
                }}
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index]} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>

          {/* Texto centralizado no gráfico */}
          <Box
            position="absolute"
            top="50%"
            left="50%"
            sx={{
              transform: 'translate(-50%, -50%)',
              textAlign: 'center',
            }}
          >
            <Typography variant="h6" fontWeight="bold">
              Total
            </Typography>
            <Typography variant="h4" fontWeight="bold">
              6
            </Typography>
            <Typography variant="subtitle2">fundos</Typography>
          </Box>
        </Box>
        <Divider sx={{ mt: 1 }} />

        {/* Legenda em formato de lista */}
        <List>
          {data.map((item) => (
            <ListItem key={item.name} style={{ display: 'flex', padding: 0 }}>
              <ListItemIcon>
                <Box
                  sx={{
                    width: 12,
                    height: 12,
                    backgroundColor: item.color,
                    borderRadius: '50%',
                  }}
                />
              </ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

export default AtivosPieChart;
