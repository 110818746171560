import React from 'react';
import { Grid, Card, CardContent, Typography, Box, Divider, List, ListItem, ListItemText } from '@mui/material';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

// Dados mockados para o gráfico "Requisições em andamento"
const requisicoesData = [
  { name: 'Contas / BTG Cayman', value: 1, color: '#1565C0' },
];

const RequisicaoSection: React.FC = () => {
  return (
    <Box>
    <Typography variant="h6" fontWeight="bold" color="#002855" gutterBottom>
        Requisições
    </Typography>
    <Grid container spacing={2} mt={2}>
      {/* Requisições em andamento */}
      <Grid item xs={12} sm={6}>
        <Card sx={{ height: '100%' }}>
          <CardContent>
            <Typography variant="subtitle1" fontWeight="bold" textAlign="center" gutterBottom>
              Requisições em Andamento
            </Typography>
            <Divider sx={{ my: 1 }} />
            <Box display="flex" justifyContent="center" alignItems="center" mt={2} position="relative">
              <ResponsiveContainer width={200} height={200}>
                <PieChart>
                  <Pie
                    data={requisicoesData}
                    cx="50%"
                    cy="50%"
                    innerRadius={50}
                    outerRadius={80}
                    paddingAngle={2}
                    dataKey="value"
                  >
                    {requisicoesData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
              {/* Texto central no gráfico */}
              <Box
                position="absolute"
                top="50%"
                left="50%"
                sx={{
                  transform: 'translate(-50%, -50%)',
                  textAlign: 'center',
                }}
              >
                <Typography variant="h6" fontWeight="bold">
                  1
                </Typography>
                <Typography variant="subtitle2" fontWeight="bold">
                  Total
                </Typography>
              </Box>
            </Box>
            {/* Legenda */}
            <List sx={{ mt: 2 }}>
              {requisicoesData.map((item, index) => (
                <ListItem key={index} disableGutters>
                  <Box
                    sx={{
                      width: 12,
                      height: 12,
                      backgroundColor: item.color,
                      borderRadius: '50%',
                      mr: 1,
                    }}
                  />
                  <ListItemText primary={item.name} />
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Card>
      </Grid>

      {/* Ações pendentes */}
      <Grid item xs={12} sm={6}>
        <Card sx={{ height: '100%' }}>
          <CardContent>
            <Typography variant="subtitle1" fontWeight="bold" textAlign="center" gutterBottom>
              Ações Pendentes
            </Typography>
            <Divider sx={{ my: 1 }} />
            <Box
              sx={{
                backgroundColor: '#FFC107',
                padding: '8px',
                borderRadius: 1,
                textAlign: 'center',
              }}
            >
              <Typography variant="body2" fontWeight="bold">
                1 ação pendente
              </Typography>
            </Box>
            <Box mt={2}>
              <Typography variant="subtitle2" fontWeight="bold">
                BERESIT FIM CP / Abertura
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Evento em análise
              </Typography>
            </Box>
            {/* Paginação */}
            <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
              <Typography variant="body2" sx={{ mx: 1 }}>
                {'<'}
              </Typography>
              <Box
                sx={{
                  width: 10,
                  height: 10,
                  backgroundColor: '#1565C0',
                  borderRadius: '50%',
                  mx: 1,
                }}
              />
              <Typography variant="body2" sx={{ mx: 1 }}>
                {'>'}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
    </Box>
  );
};

export default RequisicaoSection;
