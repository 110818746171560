import api from './api';
import { FundoInvestimento, RendaFixaBancario, RendaFixaTituloPublico, Segmento, TipoAtivo, ADR, Compromissada, RendaFixaCorporativo, RendaVariavel, RendaFixaSecuritizado } from '../types/ativos';

// Função para obter a lista de segmentos
export const getSegmentos = async () => {
  try {
    const response = await api.get<{ results: Segmento[] }>('/ativos/segmento/');
    return response.data.results;
  } catch (error) {
    console.error('Erro ao buscar segmentos:', error);
    throw error;
  }
};

export const createSegmento = async (novoSegmento: Omit<Segmento, 'id'>): Promise<Segmento> => {
  try {
    const response = await api.post('/ativos/segmento/', novoSegmento);
    return response.data; // Retorna o segmento criado
  } catch (error) {
    console.error('Erro ao criar segmento:', error);
    throw error; // Repassa o erro para ser tratado no componente
  }
};

export const getAtivos = async () => {
  try {
    const response = await api.get<{ results: { id: number; nome: string }[] }>('/ativos/ativos/');
    return response.data.results;
  } catch (error) {
    console.error('Erro ao buscar ativos:', error);
    throw error;
  }
};

// Função para obter a lista de tipos de ativo
export const getTiposAtivo = async () => {
  try {
    const response = await api.get<{ results: TipoAtivo[] }>('/ativos/tipo-ativo/');
    return response.data.results;
  } catch (error) {
    console.error('Erro ao buscar tipos de ativo:', error);
    throw error;
  }
};

export const createTipoAtivo = async (novoTipoAtivo: { nome: string; segmento_id: number }): Promise<TipoAtivo> => {
  try {
    const response = await api.post('/ativos/tipo-ativo/', novoTipoAtivo);
    return response.data;
  } catch (error) {
    console.error('Erro ao criar tipo de ativo:', error);
    throw error;
  }
};

// Função para obter a lista de benchmarks
export const getBenchmarks = async () => {
  try {
    const response = await api.get('/core/benchmarks/');
    return response.data.results;
  } catch (error) {
    console.error('Erro ao buscar benchmarks:', error);
    throw error;
  }
};

// Função para obter a lista de tributações
export const getTributacoes = async () => {
  try {
    const response = await api.get('/core/tributacoes/');
    return response.data.results;
  } catch (error) {
    console.error('Erro ao buscar tributações:', error);
    throw error;
  }
};

// Função para obter a lista de nacionalidades
export const getNacionalidades = async () => {
  try {
    const response = await api.get('/core/nacionalidades/');
    return response.data.results;
  } catch (error) {
    console.error('Erro ao buscar nacionalidades:', error);
    throw error;
  }
};

// Função para obter a lista de condomínios
export const getCondominios = async () => {
  try {
    const response = await api.get('/core/condominios/');
    return response.data.results;
  } catch (error) {
    console.error('Erro ao buscar condomínios:', error);
    throw error;
  }
};

// Função para obter a lista de distribuições
export const getDistribuicoes = async () => {
  try {
    const response = await api.get('/core/distribuicoes/');
    return response.data.results;
  } catch (error) {
    console.error('Erro ao buscar distribuições:', error);
    throw error;
  }
};

// Função para obter a lista de captações
export const getCaptacoes = async () => {
  try {
    const response = await api.get('/core/captacoes/');
    return response.data.results;
  } catch (error) {
    console.error('Erro ao buscar captações:', error);
    throw error;
  }
};

// Função para obter a lista de status
export const getStatus = async () => {
  try {
    const response = await api.get('/core/status/');
    return response.data.results;
  } catch (error) {
    console.error('Erro ao buscar status:', error);
    throw error;
  }
};

// Função para cadastrar um ativo
export const cadastrarAtivo = async (ativo: FundoInvestimento ) => {
  try {
    const response = await api.post('/ativos/ativo-create/', ativo);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getFundosInvestimento = async (
  pageIndex: number,
  pageSize: number = 100,
  sortBy: any[] = [],
  globalFilter: string = ''
) => {
  try {
    const sortParam = sortBy.length
      ? `${sortBy.map(sort => (sort.desc ? '-' : '') + sort.id).join(',')}` // Ajusta a ordenação
      : '';

    const searchParam = globalFilter ? `&search=${globalFilter}` : '';

    const response = await api.get<{ results: FundoInvestimento[], count: number }>(
      `/ativos/fundos/?page=${pageIndex + 1}&page_size=${pageSize}&ordering=${sortParam}${searchParam}`
    );

    return {
      results: response.data.results,
      totalPages: Math.ceil(response.data.count / pageSize),
    };
  } catch (error) {
    throw error;
  }
};

export const updateAtivo = async (segmentoNome: string, id: number, data: any) => {
  try {
    // Realiza a requisição PATCH para atualizar o ativo específico
    const response = await api.patch(`/ativos/${segmentoNome}/${id}/`, data);

    // Retorna os dados da resposta em caso de sucesso
    return response.data;
  } catch (error) {
    // Em caso de erro, lança uma exceção para tratamento no componente
    throw error;
  }
};

export const getRendaFixaTitulosPublicos = async (
  pageIndex: number,
  pageSize: number = 100,
  sortBy: any[] = [],
  globalFilter: string = ''
) => {
  try {
    const sortParam = sortBy.length
      ? `${sortBy.map(sort => (sort.desc ? '-' : '') + sort.id).join(',')}` // Ajusta a ordenação
      : '';

    const searchParam = globalFilter ? `&search=${globalFilter}` : '';

    const response = await api.get<{ results: RendaFixaTituloPublico[], count: number }>(
      `/ativos/renda-fixa-titulos-publicos/?page=${pageIndex + 1}&page_size=${pageSize}&ordering=${sortParam}${searchParam}`
    );

    return {
      results: response.data.results,
      totalPages: Math.ceil(response.data.count / pageSize),
    };
  } catch (error) {
    throw error;
  }
};

export const getCompromissadas = async (
  pageIndex: number,
  pageSize: number = 100,
  sortBy: any[] = [],
  globalFilter: string = ''
) => {
  try {
    const sortParam = sortBy.length
      ? `${sortBy.map(sort => (sort.desc ? '-' : '') + sort.id).join(',')}` // Ajusta a ordenação
      : '';

    const searchParam = globalFilter ? `&search=${globalFilter}` : '';

    const response = await api.get<{ results: Compromissada[], count: number }>(
      `/ativos/compromissada/?page=${pageIndex + 1}&page_size=${pageSize}&ordering=${sortParam}${searchParam}`
    );

    return {
      results: response.data.results,
      totalPages: Math.ceil(response.data.count / pageSize),
    };
  } catch (error) {
    throw error;
  }
};

export const getRendaFixaBancario = async (
  pageIndex: number,
  pageSize: number = 100,
  sortBy: any[] = [],
  globalFilter: string = ''
) => {
  try {
    const sortParam = sortBy.length
      ? `${sortBy.map(sort => (sort.desc ? '-' : '') + sort.id).join(',')}` // Ajusta a ordenação
      : '';

    const searchParam = globalFilter ? `&search=${globalFilter}` : '';

    const response = await api.get<{ results: RendaFixaBancario[], count: number }>(
      `/ativos/renda-fixa-bancario/?page=${pageIndex + 1}&page_size=${pageSize}&ordering=${sortParam}${searchParam}`
    );

    return {
      results: response.data.results,
      totalPages: Math.ceil(response.data.count / pageSize),
    };
  } catch (error) {
    throw error;
  }
};

export const getRendaFixaCorporativo = async (
  pageIndex: number,
  pageSize: number = 100,
  sortBy: any[] = [],
  globalFilter: string = ''
) => {
  try {
    const sortParam = sortBy.length
      ? `${sortBy.map(sort => (sort.desc ? '-' : '') + sort.id).join(',')}` // Ajusta a ordenação
      : '';

    const searchParam = globalFilter ? `&search=${globalFilter}` : '';

    const response = await api.get<{ results: RendaFixaCorporativo[], count: number }>(
      `/ativos/renda-fixa-corporativo/?page=${pageIndex + 1}&page_size=${pageSize}&ordering=${sortParam}${searchParam}`
    );

    return {
      results: response.data.results,
      totalPages: Math.ceil(response.data.count / pageSize),
    };
  } catch (error) {
    throw error;
  }
};

export const getRendaFixaSecuritizado = async (
  pageIndex: number,
  pageSize: number = 100,
  sortBy: any[] = [],
  globalFilter: string = ''
) => {
  try {
    const sortParam = sortBy.length
      ? `${sortBy.map(sort => (sort.desc ? '-' : '') + sort.id).join(',')}` // Ajusta a ordenação
      : '';

    const searchParam = globalFilter ? `&search=${globalFilter}` : '';

    const response = await api.get<{ results: RendaFixaSecuritizado[], count: number }>(
      `/ativos/renda-fixa-securitizado/?page=${pageIndex + 1}&page_size=${pageSize}&ordering=${sortParam}${searchParam}`
    );

    return {
      results: response.data.results,
      totalPages: Math.ceil(response.data.count / pageSize),
    };
  } catch (error) {
    throw error;
  }
};

export const getRendaVariavel = async (
  pageIndex: number,
  pageSize: number = 100,
  sortBy: any[] = [],
  globalFilter: string = ''
) => {
  try {
    const sortParam = sortBy.length
      ? `${sortBy.map(sort => (sort.desc ? '-' : '') + sort.id).join(',')}` // Ajusta a ordenação
      : '';

    const searchParam = globalFilter ? `&search=${globalFilter}` : '';

    const response = await api.get<{ results: RendaVariavel[], count: number }>(
      `/ativos/renda-variavel/?page=${pageIndex + 1}&page_size=${pageSize}&ordering=${sortParam}${searchParam}`
    );

    return {
      results: response.data.results,
      totalPages: Math.ceil(response.data.count / pageSize),
    };
  } catch (error) {
    throw error;
  }
};

export const getADR = async (
  pageIndex: number,
  pageSize: number = 100,
  sortBy: any[] = [],
  globalFilter: string = ''
) => {
  try {
    const sortParam = sortBy.length
      ? `${sortBy.map(sort => (sort.desc ? '-' : '') + sort.id).join(',')}` // Ajusta a ordenação
      : '';

    const searchParam = globalFilter ? `&search=${globalFilter}` : '';

    const response = await api.get<{ results: ADR[], count: number }>(
      `/ativos/adr/?page=${pageIndex + 1}&page_size=${pageSize}&ordering=${sortParam}${searchParam}`
    );

    return {
      results: response.data.results,
      totalPages: Math.ceil(response.data.count / pageSize),
    };
  } catch (error) {
    throw error;
  }
};

export const getOpcoesDescricao = async () => {
  try {
    const response = await api.get<{ results: { id: number; descricao: string }[] }>('/ativos/opcoes_descricao/');
    return response.data.results;
  } catch (error) {
    console.error('Erro ao buscar opções de descrição:', error);
    throw error;
  }
};

// Função para criar uma nova opção de descrição
export const createOpcaoDescricao = async (descricao: string) => {
  try {
    const response = await api.post('/ativos/opcoes_descricao/', { descricao });
    return response.data;
  } catch (error) {
    console.error('Erro ao criar nova opção de descrição:', error);
    throw error;
  }
};

// Função para excluir uma opção de descrição
export const deleteOpcaoDescricao = async (id: number) => {
  try {
    await api.delete(`/ativos/opcoes_descricao/${id}/`);
  } catch (error) {
    console.error(`Erro ao excluir opção de descrição com ID ${id}:`, error);
    throw error;
  }
};

export const getPerfisEmpresa = async () => {
  try {
    const response = await api.get<{ results: { id: number; descricao: string }[] }>('/ativos/perfis_empresa/');
    return response.data.results;
  } catch (error) {
    console.error('Erro ao buscar opções de descrição:', error);
    throw error;
  }
};

// Função para criar uma nova opção de descrição
export const createPerfisEmpresa = async (descricao: string) => {
  try {
    const response = await api.post('/ativos/perfis_empresa/', { descricao });
    return response.data;
  } catch (error) {
    console.error('Erro ao criar nova opção de descrição:', error);
    throw error;
  }
};

// Função para excluir uma opção de descrição
export const deletePerfisEmpresa = async (id: number) => {
  try {
    await api.delete(`/ativos/perfis_empresa/${id}/`);
  } catch (error) {
    console.error(`Erro ao excluir opção de descrição com ID ${id}:`, error);
    throw error;
  }
};

export const saveFundoInvestimentoRegras = async (fundoId: number, data: any) => {
  try {
    const response = await api.put(`/ativos/fundo_regras/${fundoId}/`, data);
    return response.data;
  } catch (error) {
    console.error('Erro ao salvar as regras do fundo:', error);
    throw error;
  }
};
// Função para atualizar regras de fundo de investimento existente
export const atualizarFundoRegras = async (id: number, dados: any) => {
  try {
    const response = await api.put(`/ativos/fundo_regras/${id}/`, dados);
    return response.data;
  } catch (error) {
    console.error('Erro ao atualizar fundo de investimento regras:', error);
    throw error;
  }
};

export const getFundoInvestimentoRegras = async (fundoId:number) => {
  try {
    const response = await api.get(`/ativos/fundo_regras/${fundoId}/`);
    return response.data;
  } catch (error) {
    console.error('Erro ao buscar regras do fundo de investimento:', error);
    throw error;
  }
};

export const getDescricaoAtivo = async () => {
  try {
    const response = await api.get<{ results: { id: number; descricao: string }[] }>('/ativos/descricao_ativo/');
    return response.data.results;
  } catch (error) {
    console.error('Erro ao buscar opções de descrição:', error);
    throw error;
  }
};

// Função para criar uma nova opção de descrição
export const createDescricaoAtivo = async (descricao: string) => {
  try {
    const response = await api.post('/ativos/descricao_ativo/', { descricao });
    return response.data;
  } catch (error) {
    console.error('Erro ao criar nova opção de descrição:', error);
    throw error;
  }
};

export const saveLegislacaoAtivos = async (legislacaoId: number, data: any) => {
  try {
    const response = await api.put(`/ativos/legislacao_ativos/${legislacaoId}/`, data);
    return response.data;
  } catch (error) {
    console.error('Erro ao salvar a legislação de ativos:', error);
    throw error;
  }
};

export const getLegislacaoAtivos = async (ativoId: number) => {
  try {
    const response = await api.get(`/ativos/legislacao_ativos/${ativoId}/`);
    return response.data;
  } catch (error) {
    console.error('Erro ao buscar as informações da legislação do ativo:', error);
    throw error;
  }
};


export const getResumoDiario = async (dataInicio: string, dataFim: string) => {
  try {
    const response = await api.get('/ativos/resumo_diario/', {
      params: { data_inicio: dataInicio, data_fim: dataFim },
    });
    return response.data;
  } catch (error) {
    console.error('Erro ao buscar o resumo diário:', error);
    throw error;
  }
};