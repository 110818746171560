import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Grid,
  Select,
  MenuItem,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoIcon from '@mui/icons-material/Info';
import ButtonBase from '@mui/material/ButtonBase';
import InputAdornment from '@mui/material/InputAdornment';
interface IncluirBoletaModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

const TITLES = ['Renda Variável', 'Alocação em Cotas de Fundos', 'Renda Fixa'];


const IncluirBoletaModal: React.FC<IncluirBoletaModalProps> = ({ isOpen, onRequestClose }) => {
  const [selectedTitleIndex, setSelectedTitleIndex] = useState(0);
  const [quantidade, setQuantidade] = useState<number | ''>('');
  const [preco, setPreco] = useState<string>('');
  const [volumeFinanceiro, setVolumeFinanceiro] = useState<string>('');
  const [valorPermitido, setValorPermitido] = useState<string>('');
  
  const [statuses, setStatuses] = useState([
    { text: 'Produto aprovado', status: 'approved', tooltip: '' }, // Produto não possui tooltip
    { text: 'Volume Financeiro', status: 'approved', tooltip:
      <>
      <b>Volume financeiro não aprovado.</b><br />
      <br />
      <b>Compra: ITSA4</b><br />
      <br />
      PL: 7.412.441,027<br />
      Máximo: 370.622,05<br />
      </>
    
    },
    {
      text: 'Regulamento',
      status: 'approved',
      tooltip: (
        <>
          Regulamento:<br /> 
          Artigo 5: aplicações do FUNDO em cotas de fundos de investimento: &gt;=95
        </>
      ),
    },
    {
      text: 'Regra CVM',
      status: 'approved',
      tooltip: (
        <>
          RCVM175, ANEXO NORMATIVO I: Artigo 2, VI:<br />
          <br />
          Patrimônio líquido em cotas de outras classes. &gt;=95%
          <br />
        </>
      ),
    },
  ]);

  const formatToBrazilianDecimal = (value: number): string => {
    return value.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const handlePrecoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    // Permitir apenas números, pontos, vírgulas
    const formattedValue = inputValue.replace(/[^0-9,]/g, '');

    // Substituir vírgulas por pontos para cálculos internos
    const numericValue = parseFloat(formattedValue.replace(',', '.'));

    setPreco(formattedValue);

    // Atualizar o volume financeiro
    if (quantidade && !isNaN(numericValue)) {
      const volume = quantidade * numericValue;
      setVolumeFinanceiro(formatToBrazilianDecimal(volume));
    } else {
      setVolumeFinanceiro('');
    }
  };

  const handleQuantidadeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const numericValue = inputValue === '' ? '' : parseInt(inputValue, 10);

    setQuantidade(numericValue);

    // Atualizar o volume financeiro
    if (numericValue && preco) {
      const numericPreco = parseFloat(preco.replace(',', '.'));
      if (!isNaN(numericPreco)) {
        const volume = numericValue * numericPreco;
        setVolumeFinanceiro(formatToBrazilianDecimal(volume));
      }
    } else {
      setVolumeFinanceiro('');
    }
  };

  const handleValorPermitidoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    // Permitir apenas números, pontos e vírgulas
    const formattedValue = inputValue
      .replace(/[^0-9.,]/g, '')
      .replace(/\.(?=\d{3,})/g, ','); // Troca os pontos por vírgulas

    setValorPermitido(formattedValue);
  };

  const handleTitleClick = () => {
    setSelectedTitleIndex((prevIndex) => (prevIndex + 1) % TITLES.length);
    setQuantidade('');
    setPreco('');
    setVolumeFinanceiro('');
    setValorPermitido('');
  };

  const toggleStatus = (index: number) => {
    setStatuses((prevStatuses) =>
      prevStatuses.map((status, i) =>
        i === index
          ? {
              ...status,
              status:
                status.status === 'approved'
                  ? index === 0
                    ? 'warning'
                    : 'rejected'
                  : 'approved',
            }
          : status
      )
    );
  };

  const renderFields = () => {
    const title = TITLES[selectedTitleIndex];

    if (title === 'Renda Variável') {
      return (
        <Grid container spacing={2} mb={3}>
          <Grid item xs={12}>
            <Select fullWidth displayEmpty defaultValue="">
              <MenuItem value="" disabled>
              Selecione o Fundo
              </MenuItem>
              <MenuItem value="Fundo 1">AGRELO STOCK FC FIA</MenuItem>
              <MenuItem value="Fundo 2">EDDA INFLATION PROTECTION FIM</MenuItem>
              <MenuItem value="Fundo 3">TRIUMPH CREDIT HIGH YIELD FIC FIMCP</MenuItem>
              <MenuItem value="Fundo 4">BRUGES MID FC FIM</MenuItem>
              <MenuItem value="Fundo 5">EDDA INFLA PRO FIM</MenuItem>
              <MenuItem value="Fundo 6">GUALTALLARY FC FIM</MenuItem>
              <MenuItem value="Fundo 7">GS PATTON FC FIM IE</MenuItem>
              <MenuItem value="Fundo 8">FORTIS FIM</MenuItem>
              <MenuItem value="Fundo 9">CFM INVEST FIA IE</MenuItem>
              <MenuItem value="Fundo 10">KALYTERA PREV FC FIM</MenuItem>
              <MenuItem value="Fundo 11">KINGSTON C FC FIDC</MenuItem>
              <MenuItem value="Fundo 12">PWM SELEC FIC FIRF</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={3}>
            <TextField label="Código do Ativo" fullWidth />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="Quantidade"
              type="number"
              value={quantidade}
              onChange={handleQuantidadeChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="Preço"
              value={preco}
              onChange={handlePrecoChange}
              fullWidth
              InputProps={{
                startAdornment: <span style={{ marginRight: 8 }}>R$</span>,
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="Volume Financeiro"
              value={volumeFinanceiro}
              disabled
              fullWidth
              InputProps={{
                startAdornment: <span style={{ marginRight: 8 }}>R$</span>,
              }}
            />
          </Grid>
          <Grid item xs={3} sx={{ ml: 'auto' }}>
            <TextField
              label="Valor Excedente"
              value={valorPermitido}
              onChange={handleValorPermitidoChange}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ color: 'red' }}>-R$</span>
                  </InputAdornment>
                ),
                style: {
                  color: 'red',
                },
              }}
            />
          </Grid>
        </Grid>
      );
    }

    if (title === 'Alocação em Cotas de Fundos') {
      return (
        <Grid container spacing={2} mb={3}>
          <Grid item xs={12}>
            <Select fullWidth displayEmpty defaultValue="">
              <MenuItem value="" disabled>
                Selecione o Fundo
              </MenuItem>
              <MenuItem value="Fundo 1">TRIUMPH CREDIT HIGH YIELD FIC FIMCP</MenuItem>
              <MenuItem value="Fundo 2">EDDA INFLATION PROTECTION FIM</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={3}>
            <TextField label="CNPJ Fundo" type="text" fullWidth />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="Volume Financeiro"
              value={volumeFinanceiro}
              type="number"
              fullWidth
              InputProps={{
                startAdornment: <span style={{ marginRight: 8 }}>R$</span>,
              }}
            />
          </Grid>
          <Grid item xs={3} />
          <Grid item xs={3}>
            <TextField
              label="Valor Excedente"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ color: 'red' }}>-R$</span>
                  </InputAdornment>
                ),
                style: {
                  color: 'red',
                },
              }}
            />
          </Grid>
        </Grid>
      );
    }

    if (title === 'Renda Fixa') {
      return (
        <Grid container spacing={2} mb={3}>
          <Grid item xs={12}>
            <Select fullWidth displayEmpty defaultValue="">
              <MenuItem value="" disabled>
                Selecione o Fundo
              </MenuItem>
              <MenuItem value="Fundo 1">TRIUMPH CREDIT HIGH YIELD FIC FIMCP</MenuItem>
              <MenuItem value="Fundo 2">EDDA INFLATION PROTECTION FIM</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={3}>
            <TextField label="Código do ativo" type="text" fullWidth />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="Quantidade"
              value={quantidade}
              onChange={handleQuantidadeChange}
              type="number"
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="PU"
              onChange={handlePrecoChange}
              value={preco}
              fullWidth
              InputProps={{
                startAdornment: <span style={{ marginRight: 8 }}>R$</span>,
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="Volume Financeiro"
              value={volumeFinanceiro}
              disabled
              fullWidth
              InputProps={{
                startAdornment: <span style={{ marginRight: 8 }}>R$</span>,
              }}
            />
          </Grid>
          <Grid item xs={3} sx={{ ml: 'auto' }}>
            <TextField
              label="Valor Excedente"
              value={valorPermitido}
              onChange={handleValorPermitidoChange}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ color: 'red' }}>-R$</span>
                  </InputAdornment>
                ),
                style: {
                  color: 'red',
                },
              }}
            />
          </Grid>
        </Grid>
      );
    }

    return null;
  };

  return (
    <Dialog open={isOpen} onClose={onRequestClose} fullWidth maxWidth="lg">
      <DialogTitle>
        <Typography
          onClick={handleTitleClick}
          sx={{
            cursor: 'pointer',
            fontWeight: 'bold',
            textAlign: 'center',
            color: '#002855',
          }}
        >
          {TITLES[selectedTitleIndex]}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {renderFields()}
        <Typography variant="h6" mt={2} mb={1}>
          Status
        </Typography>
        <TableContainer>
          <Table>
            <TableBody>
              {statuses.map((status, index) => (
                <TableRow key={index}>
                  <TableCell style={{ width: '15%'}}>
                    <Typography>
                      {status.status === 'approved'
                        ? status.text
                        : index === 0
                        ? 'Produto reprovado'
                        : status.text.replace('aprovado', 'reprovado')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <ButtonBase onClick={() => toggleStatus(index)}>
                      {status.status === 'approved' && index === 0 && (
                        <CheckCircleIcon sx={{ color: 'green' }} />
                      )}
                      {status.status === 'approved' && index !== 0 && (
                        <CheckCircleIcon sx={{ color: 'green' }} />
                      )}
                      {status.status === 'warning' && index === 0 && (
                        <WarningIcon sx={{ color: 'orange' }} />
                      )}
                      {status.status === 'rejected' && (
                        <>
                          <ErrorOutlineIcon sx={{ color: 'red' }} />
                          {index !== 0 && (
                            <Tooltip title={status.tooltip} arrow>
                              <InfoIcon sx={{ color: 'gray', ml: 1 }} />
                            </Tooltip>
                          )}
                        </>
                      )}
                    </ButtonBase>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={onRequestClose} color="error">
          Cancelar
        </Button>
        <Button onClick={onRequestClose} color="primary" variant="contained">
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default IncluirBoletaModal;