import React, { useEffect, useState } from 'react';
import { getUsers, createUser, updateUser, deleteUser, toggleUserActive } from '../services/userService';
import { User } from '../types/user';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  Box,
  Switch,
  FormControlLabel,
  DialogContentText
} from '@mui/material';
import { Add, Delete, Edit, Check, Cancel } from '@mui/icons-material';
import { AxiosError } from 'axios'; // Importando AxiosError

interface ErrorResponse {
  [key: string]: string[]; // Permite chaves dinâmicas com mensagens de erro
}

const UserCRUD: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [newUser, setNewUser] = useState<Partial<User>>({
    email: '',
    name: '',
    is_active: true,
    is_staff: false,
    password: '',
  });
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [emailError, setEmailError] = useState(''); // Novo estado para erro de e-mail
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState<number | null>(null);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const usersData = await getUsers();
      setUsers(usersData);
    } catch (error) {
      console.error('Erro ao buscar usuários:', error);
    }
  };

  const handleOpenDialog = (user?: User) => {
    if (user) {
      setEditMode(true);
      setNewUser({
        email: user.email,
        name: user.name,
        is_active: user.is_active,
        is_staff: user.is_staff,
        password: '',
      });
      setConfirmPassword('');
      setSelectedUserId(user.id);
    } else {
      setEditMode(false);
      setNewUser({ email: '', name: '', is_active: true, is_staff: false, password: '' });
      setConfirmPassword('');
    }
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setSelectedUserId(null);
    setPasswordError('');
    setEmailError(''); // Limpa o erro de e-mail ao fechar o diálogo
  };

  const handleSaveUser = async () => {
    // Validação de e-mail (se necessário)
    if (!newUser.email) {
        setEmailError('O e-mail é obrigatório');
        return;
    } else {
        setEmailError(''); // Limpa o erro de e-mail se não houver problemas
    }

    // Validação de senha
    if (!editMode && (!newUser.password || newUser.password !== confirmPassword)) {
        setPasswordError('As senhas devem coincidir e não podem estar vazias');
        return;
    }

    try {
        const userToSave = { ...newUser };
        
        // Se estamos editando e a senha não foi alterada, removemos o campo
        if (editMode && !newUser.password) {
            delete userToSave.password;
        }

        if (editMode && selectedUserId) {
            await updateUser(selectedUserId, userToSave);
        } else {
            await createUser(userToSave);
        }

        fetchUsers();
        handleCloseDialog();
        setPasswordError('');
        setEmailError('');
    } catch (error) {
        const axiosError = error as AxiosError<ErrorResponse>;
        if (axiosError.response && axiosError.response.data) {
            const errors = axiosError.response.data;
            const errorMessages = Object.values(errors).flat();
            errorMessages.forEach((msg) => {
                if (msg.includes('email')) {
                    setEmailError(msg);
                } else {
                    setPasswordError(msg);
                }
            });
        } else {
            console.error('Erro ao salvar usuário:', error);
            setPasswordError('Erro ao salvar usuário.');
        }
    }
  };


  const handleToggleUserActive = async (id: number, isActive: boolean) => {
    try {
      await toggleUserActive(id, !isActive);
      fetchUsers();
    } catch (error) {
      console.error('Erro ao alterar status do usuário:', error);
    }
  };

  const handleStaffSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewUser({ ...newUser, is_staff: event.target.checked });
  };

  const handleOpenDeleteDialog = (id: number) => {
    setUserToDelete(id);
    setIsDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
    setUserToDelete(null);
  };

  const handleConfirmDelete = async () => {
    if (userToDelete !== null) {
      try {
        await deleteUser(userToDelete);
        fetchUsers();
        handleCloseDeleteDialog();
      } catch (error) {
        console.error('Erro ao deletar usuário:', error);
      }
    }
  };

  return (
    <Box p={3}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <h2>Gerenciamento de Usuários</h2>
        <Tooltip title="Adicionar Novo Usuário">
          <IconButton color="primary" onClick={() => handleOpenDialog()}>
            <Add />
          </IconButton>
        </Tooltip>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '30%', fontWeight: 'bold', color: 'white' }}>Email</TableCell>
              <TableCell sx={{ width: '30%', fontWeight: 'bold', color: 'white' }}>Nome</TableCell>
              <TableCell sx={{ width: '15%', fontWeight: 'bold', color: 'white' }}>Ativo</TableCell>
              <TableCell sx={{ width: '15%', fontWeight: 'bold', color: 'white' }}>Admin</TableCell>
              <TableCell sx={{ width: '20%', fontWeight: 'bold', color: 'white' }}>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.id}>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.name}</TableCell>
                <TableCell>{user.is_active ? 'Sim' : 'Não'}</TableCell>
                <TableCell>{user.is_staff ? 'Sim' : 'Não'}</TableCell>
                <TableCell>
                  <Tooltip title={user.is_active ? 'Desativar' : 'Ativar'}>
                    <IconButton onClick={() => handleToggleUserActive(user.id, user.is_active)} color={user.is_active ? 'error' : 'success'}>
                      {user.is_active ? <Cancel /> : <Check />}
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Editar">
                    <IconButton onClick={() => handleOpenDialog(user)} color="primary">
                      <Edit />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Deletar">
                    <IconButton onClick={() => handleOpenDeleteDialog(user.id)} color="primary">
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Dialog de Cadastro/Edição */}
      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>{editMode ? 'Editar Usuário' : 'Adicionar Novo Usuário'}</DialogTitle>
        <DialogContent>
          <TextField
            label="Email"
            value={newUser.email || ''}
            onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
            fullWidth
            margin="normal"
            error={!!emailError} // Adiciona um erro visual ao campo se houver erro
            helperText={emailError} // Exibe mensagem de erro específica para o e-mail
          />
          <TextField
            label="Nome"
            value={newUser.name || ''}
            onChange={(e) => setNewUser({ ...newUser, name: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Senha"
            type="password"
            value={newUser.password || ''}
            onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Confirme a Senha"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            fullWidth
            margin="normal"
            error={!!passwordError}
            helperText={passwordError}
          />
          <FormControlLabel
            control={
              <Switch
                checked={newUser.is_staff}
                onChange={handleStaffSwitchChange}
                color="primary"
              />
            }
            label={newUser.is_staff ? 'Usuário é admin' : 'Usuário não é admin'}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} sx={{ color: 'red' }}>
            Cancelar
          </Button>
          <Button onClick={handleSaveUser} color="primary">
            {editMode ? 'Salvar Alterações' : 'Adicionar'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog de Confirmação de Exclusão */}
      <Dialog open={isDeleteDialogOpen} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Confirmar Exclusão</DialogTitle>
        <DialogContent>
          <DialogContentText>Você tem certeza que deseja excluir este usuário?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} sx={{ color: 'red' }}>
            Cancelar
          </Button>
          <Button onClick={handleConfirmDelete} color="primary">
            Confirmar Exclusão
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default UserCRUD;
