import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import {
  FundosGerenciadosData,
  Tributacao,
  Condominio,
  Captacao,
  Status,
  Corretora,
  Subclass,
  FundoCorretora,
} from '../types/fundosGerenciados';
import {
  getTributacoes,
  getCondominios,
  getCaptacoes,
  getStatusList,
  getCorretoras,
} from '../services/fundosGerenciadosService';

interface EditModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  details: FundosGerenciadosData;
  onSave: (updatedDetails: FundosGerenciadosData) => void;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxWidth: '1500px',
  maxHeight: '80vh',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
};

const EditModal: React.FC<EditModalProps> = ({ isOpen, onRequestClose, details, onSave }) => {
  const [formFields, setFormFields] = useState<FundosGerenciadosData>(details);
  const [tributacoes, setTributacoes] = useState<Tributacao[]>([]);
  const [condominios, setCondominios] = useState<Condominio[]>([]);
  const [captacoes, setCaptacoes] = useState<Captacao[]>([]);
  const [statusList, setStatusList] = useState<Status[]>([]);
  const [corretoras, setCorretoras] = useState<Corretora[]>([]);
  const [fundosCorretoras, setFundosCorretoras] = useState<FundoCorretora[]>(details.fundos_corretoras || []);
  const [subclassesEnabled, setSubclassesEnabled] = useState<boolean>(formFields.subclasses || false);
  const [subclassesItems, setSubclassesItems] = useState<Subclass[]>(formFields.subclasse_items || []);

  // Estados para Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [tributacoesData, condominiosData, captacoesData, statusData, corretorasData] = await Promise.all([
          getTributacoes(),
          getCondominios(),
          getCaptacoes(),
          getStatusList(),
          getCorretoras(),
        ]);
        setTributacoes(tributacoesData);
        setCondominios(condominiosData);
        setCaptacoes(captacoesData);
        setStatusList(statusData);
        setCorretoras(corretorasData);
      } catch (error) {
        console.error('Erro ao carregar dados para os selects:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    setFormFields(details);
    setFundosCorretoras(details.fundos_corretoras || []);
  }, [details]);

  const handleFieldChange = (key: keyof FundosGerenciadosData, value: any) => {
    setFormFields((prevFields) => ({
      ...prevFields,
      [key]: value,
    }));
  };

  const handleAddCorretora = (corretoraId: number) => {
    const selectedCorretora = corretoras.find(c => c.id === corretoraId);
    if (selectedCorretora) {
      setFundosCorretoras([...fundosCorretoras, { id: 0, corretora: selectedCorretora.corretora, codigo_corretora: '', fundo: formFields.id } as FundoCorretora]);
    }
  };

  const handleCodigoCorretoraChange = (index: number, value: string) => {
    const updatedFundosCorretoras = [...fundosCorretoras];
    updatedFundosCorretoras[index].codigo_corretora = value;
    setFundosCorretoras(updatedFundosCorretoras);
  };

  const handleDeleteCorretora = (index: number) => {
    const updatedFundosCorretoras = fundosCorretoras.filter((_, i) => i !== index);
    setFundosCorretoras(updatedFundosCorretoras);
  
    // Atualizar o formFields para refletir as corretoras removidas
    setFormFields((prevFields) => ({
      ...prevFields,
      fundos_corretoras: updatedFundosCorretoras,
    }));
  };

  const handleAddSubclass = () => {
    setSubclassesItems([
      ...subclassesItems,
      { fundo: formFields.id, subclasse: '', serie: '' } as Subclass
    ]);
  };

  const handleSubclassChange = (index: number, key: keyof Subclass, value: any) => {
    const updatedSubclasses = subclassesItems.map((item, i) => 
      i === index ? { ...item, [key]: value } : item
    );
    setSubclassesItems(updatedSubclasses);
    handleFieldChange('subclasse_items', updatedSubclasses);
  };

  const handleDeleteSubclass = (index: number) => {
    const updatedSubclasses = subclassesItems.filter((_, i) => i !== index);
    setSubclassesItems(updatedSubclasses);
    handleFieldChange('subclasse_items', updatedSubclasses);
  };

  const handleSave = async () => {
    try {
      await onSave({ ...formFields, subclasses: subclassesEnabled, subclasse_items: subclassesItems, fundos_corretoras: fundosCorretoras });
      setSnackbarMessage('Dados salvos com sucesso!');
      setSnackbarSeverity('success');
    } catch (error) {
      setSnackbarMessage('Erro ao salvar os dados. Por favor, tente novamente.');
      setSnackbarSeverity('error');
    } finally {
      setSnackbarOpen(true);
      setTimeout(onRequestClose, 3000);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <Modal open={isOpen} onClose={onRequestClose}>
        <Box sx={style}>
          <Typography variant="h5" component="h2" sx={{ mb: 2 }}>
            Editar Fundo Gerenciado
          </Typography>
          <Divider sx={{ mb: 3 }} />

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">Códigos Identificadores</Typography>
              <Divider sx={{ mb: 2 }} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Nome Fantasia"
                value={formFields.fantasia || ''}
                onChange={(e) => handleFieldChange('fantasia', e.target.value)}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="CNPJ"
                value={formFields.cnpj || ''}
                onChange={(e) => handleFieldChange('cnpj', e.target.value)}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Razão Social"
                value={formFields.razao_social || ''}
                onChange={(e) => handleFieldChange('razao_social', e.target.value)}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Código ISIN"
                value={formFields.isin || ''}
                onChange={(e) => handleFieldChange('isin', e.target.value)}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Código ANBIMA"
                value={formFields.anbima || ''}
                onChange={(e) => handleFieldChange('anbima', e.target.value)}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Código GIIN"
                value={formFields.giin || ''}
                onChange={(e) => handleFieldChange('giin', e.target.value)}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Código Galgo"
                value={formFields.codigo_galgo || ''}
                onChange={(e) => handleFieldChange('codigo_galgo', e.target.value)}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Código CVM"
                value={formFields.cvm || ''}
                onChange={(e) => handleFieldChange('cvm', e.target.value)}
                fullWidth
                margin="normal"
              />
            </Grid>
            
            <Grid item xs={12}>
              <Typography variant="subtitle1">Classificações</Typography>
              <Divider sx={{ mb: 2 }} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Classificação CVM"
                value={formFields.classificacao_cvm || ''}
                onChange={(e) => handleFieldChange('classificacao_cvm', e.target.value)}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Classificação ANBIMA"
                value={formFields.classificacao_anbima || ''}
                onChange={(e) => handleFieldChange('classificacao_anbima', e.target.value)}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Perfil de Risco"
                value={formFields.perfil_risco || ''}
                onChange={(e) => handleFieldChange('perfil_risco', e.target.value)}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Classificação de Risco Mont"
                value={formFields.risco_gestora || ''}
                onChange={(e) => handleFieldChange('risco_gestora', e.target.value)}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Número de Risco Mont"
                value={formFields.numero_risco_mont || ''}
                onChange={(e) => handleFieldChange('numero_risco_mont', e.target.value)}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Segmento Gestora"
                value={formFields.segmento_gestora || ''}
                onChange={(e) => handleFieldChange('segmento_gestora', e.target.value)}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Tipo de Ativo Gestora"
                value={formFields.tipo_ativo_gestora || ''}
                onChange={(e) => handleFieldChange('tipo_ativo_gestora', e.target.value)}
                fullWidth
                margin="normal"
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">Remuneração</Typography>
              <Divider sx={{ mb: 2 }} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Taxa de Administração"
                type="number"
                value={formFields.taxa_administracao || ''}
                onChange={(e) => handleFieldChange('taxa_administracao', e.target.value)}
                fullWidth
                margin="normal"
                inputProps={{
                  step: 0.01,
                  min: 0,
                  max: 100,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Taxa de Performance"
                type="number"
                value={formFields.taxa_performance || ''}
                onChange={(e) => handleFieldChange('taxa_performance', e.target.value)}
                fullWidth
                margin="normal"
                inputProps={{
                  step: 0.01,
                  min: 0,
                  max: 100,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Benchmark"
                value={formFields.benchmark || ''}
                onChange={(e) => handleFieldChange('benchmark', e.target.value)}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Referência do Benchmark"
                value={formFields.referencia_benchmark || ''}
                onChange={(e) => handleFieldChange('referencia_benchmark', e.target.value)}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Taxa de Custódia"
                type="number"
                value={formFields.taxa_custodia || ''}
                onChange={(e) => handleFieldChange('taxa_custodia', e.target.value)}
                fullWidth
                margin="normal"
                inputProps={{
                  step: 0.01,
                  min: 0,
                  max: 100,
                }}
              />
            </Grid>
            
            <Grid item xs={12}>
              <Typography variant="subtitle1">Distribuição</Typography>
              <Divider sx={{ mb: 2 }} />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Condomínio</InputLabel>
                <Select
                  value={formFields.condominio?.id || ''}
                  onChange={(e) =>
                    handleFieldChange(
                      'condominio',
                      condominios.find((condominio) => condominio.id === Number(e.target.value))
                    )
                  }
                  label="Condomínio"
                >
                  {condominios.map((condominio) => (
                    <MenuItem key={condominio.id} value={condominio.id}>
                      {condominio.tipo}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Status</InputLabel>
                <Select
                  value={formFields.status?.id || ''}
                  onChange={(e) =>
                    handleFieldChange(
                      'status',
                      statusList.find((status) => status.id === Number(e.target.value))
                    )
                  }
                  label="Status"
                >
                  {statusList.map((status) => (
                    <MenuItem key={status.id} value={status.id}>
                      {status.estado}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Tributação</InputLabel>
                <Select
                  value={formFields.tributacao?.id || ''}
                  onChange={(e) =>
                    handleFieldChange(
                      'tributacao',
                      tributacoes.find((tributacao) => tributacao.id === Number(e.target.value))
                    )
                  }
                  label="Tributação"
                >
                  {tributacoes.map((tributacao) => (
                    <MenuItem key={tributacao.id} value={tributacao.id}>
                      {tributacao.tipo}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                  label="Data 1º Aporte"
                  type="date"
                  value={formFields.data_primeiro_aporte || ''}
                  onChange={(e) => handleFieldChange('data_primeiro_aporte', e.target.value)}
                  margin="normal"
                  fullWidth
                  InputLabelProps={{
                  shrink: true, // Isso garante que o label não sobreponha o valor da data
                  }}
              />
            </Grid>
          <Grid item xs={12} sm={3}>
          <TextField
              label="Código ISIN"
              value={formFields.codigo_isin || ''}
              onChange={(e) => handleFieldChange('codigo_isin', e.target.value)}
              fullWidth
              margin="normal"
          />
          </Grid>

            {/* Campos para Condominio Aberto */}
            {formFields.condominio?.tipo === 'Aberto' && (
              <>
                <Grid item xs={12} sm={3}>
                  <FormControl fullWidth margin="normal">
                  <InputLabel>Captação</InputLabel>
                  <Select
                      value={formFields.captacao?.id || ''}
                      onChange={(e) =>
                      handleFieldChange(
                          'captacao',
                          captacoes.find((captacao) => captacao.id === Number(e.target.value))
                      )
                      }
                      label="Tributação"
                  >
                      {captacoes.map((captacao) => (
                      <MenuItem key={captacao.id} value={captacao.id}>
                          {captacao.tipo}
                      </MenuItem>
                      ))}
                  </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="Cotização de Aplicação"
                    value={formFields.cotizacao_aplicacao || ''}
                    onChange={(e) => handleFieldChange('cotizacao_aplicacao', e.target.value)}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="Cotização de Resgate"
                    value={formFields.cotizacao_resgate || ''}
                    onChange={(e) => handleFieldChange('cotizacao_resgate', e.target.value)}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="Liquidação de Resgate"
                    value={formFields.liquidacao_resgate || ''}
                    onChange={(e) => handleFieldChange('liquidacao_resgate', e.target.value)}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="Aplicação Inicial Mínima"
                    value={formFields.aplicacao_inicial_minima || ''}
                    onChange={(e) => handleFieldChange('aplicacao_inicial_minima', e.target.value)}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="Movimentação Mínima"
                    value={formFields.movimentacao_minima || ''}
                    onChange={(e) => handleFieldChange('movimentacao_minima', e.target.value)}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="Saldo Mínimo"
                    value={formFields.saldo_minimo || ''}
                    onChange={(e) => handleFieldChange('saldo_minimo', e.target.value)}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth margin="normal">
                      <InputLabel id="paga-proventos-label">Paga Proventos?</InputLabel>
                      <Select
                      labelId="paga-proventos-label"
                      value={formFields.paga_proventos === true ? 'Sim' : formFields.paga_proventos === false ? 'Não' : ''}
                      onChange={(e) => handleFieldChange('paga_proventos', e.target.value === 'Sim')}
                      label="Paga Proventos?"
                      >
                      <MenuItem value="Sim">Sim</MenuItem>
                      <MenuItem value="Não">Não</MenuItem>
                      </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="Periodicidade"
                    value={formFields.periodicidade || ''}
                    onChange={(e) => handleFieldChange('periodicidade', e.target.value)}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
              </>
            )}

            {/* Campos para Condominio Fechado */}
            {formFields.condominio?.tipo === 'Fechado' && (
              <>
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="Código B3"
                    value={formFields.codigo_b3 || ''}
                    onChange={(e) => handleFieldChange('codigo_b3', e.target.value)}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="Cotização de Aplicação"
                    value={formFields.cotizacao_aplicacao || ''}
                    onChange={(e) => handleFieldChange('cotizacao_aplicacao', e.target.value)}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="Data de Resgate"
                    type="date"
                    value={formFields.data_resgate || ''}
                    onChange={(e) => handleFieldChange('data_resgate', e.target.value)}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Data de Liquidação"
                    type="date"
                    value={formFields.data_liquidacao || ''}
                    onChange={(e) => handleFieldChange('data_liquidacao', e.target.value)}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Aplicação Mínima"
                    value={formFields.aplicacao_minima || ''}
                    onChange={(e) => handleFieldChange('aplicacao_minima', e.target.value)}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                {/* Subclasses */}
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Subclasses</InputLabel>
                    <Select
                      value={subclassesEnabled ? 'Sim' : 'Não'}
                      onChange={(e) => {
                        const selectedValue = e.target.value === 'Sim';
                        setSubclassesEnabled(selectedValue);
                        if (!selectedValue) {
                          setSubclassesItems([]);
                        }
                      }}
                      label="Subclasses"
                    >
                      <MenuItem value="Sim">Sim</MenuItem>
                      <MenuItem value="Não">Não</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {subclassesEnabled && subclassesItems.map((subclass, index) => (
                  <Grid container spacing={2} key={index}>
                    <Grid item xs={12} sm={5}>
                      <TextField
                        label="Subclasse"
                        value={subclass.subclasse || ''}
                        onChange={(e) => handleSubclassChange(index, 'subclasse', e.target.value)}
                        fullWidth
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <TextField
                        label="Série"
                        value={subclass.serie || ''}
                        onChange={(e) => handleSubclassChange(index, 'serie', e.target.value)}
                        fullWidth
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <IconButton onClick={() => handleDeleteSubclass(index)} style={{ color: 'red' }}>
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
                {subclassesEnabled && (
                  <Grid item xs={12}>
                    <Button onClick={handleAddSubclass} variant="outlined" startIcon={<AddIcon />}>
                      Adicionar Subclasse
                    </Button>
                  </Grid>
                )}
              </>
            )}

            {/* Campos para Contas Definitivas */}
            <Grid item xs={12}>
              <Typography variant="subtitle1">Contas Definitivas</Typography>
              <Divider sx={{ mb: 2 }} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Conta SELIC"
                value={formFields.conta_selic || ''}
                onChange={(e) => handleFieldChange('conta_selic', e.target.value)}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Conta CETIP"
                value={formFields.codigo_cetip || ''}
                onChange={(e) => handleFieldChange('codigo_cetip', e.target.value)}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Conta CETIP Contraparte"
                value={formFields.conta_cetip_contraparte || ''}
                onChange={(e) => handleFieldChange('conta_cetip_contraparte', e.target.value)}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Conta CBLC"
                value={formFields.conta_cblc || ''}
                onChange={(e) => handleFieldChange('conta_cblc', e.target.value)}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Conta CBLC Custódia"
                value={formFields.conta_cblc_custodia || ''}
                onChange={(e) => handleFieldChange('conta_cblc_custodia', e.target.value)}
                fullWidth
                margin="normal"
              />
            </Grid>
            
            {/* Campos para Contas Editáveis */}
            <Grid item xs={12}>
              <Typography variant="subtitle1">Contas Editáveis</Typography>
              <Divider sx={{ mb: 2 }} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Banco"
                value={formFields.banco || ''}
                onChange={(e) => handleFieldChange('banco', e.target.value)}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Número do Banco"
                value={formFields.numero_banco || ''}
                onChange={(e) => handleFieldChange('numero_banco', e.target.value)}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Agência"
                value={formFields.agencia || ''}
                onChange={(e) => handleFieldChange('agencia', e.target.value)}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Conta Bancária"
                value={formFields.conta_bancaria || ''}
                onChange={(e) => handleFieldChange('conta_bancaria', e.target.value)}
                fullWidth
                margin="normal"
              />
            </Grid>

            {/* Campos para Corretoras */}
            <Grid item xs={12}>
              <Typography variant="subtitle1">Corretoras</Typography>
              <Divider sx={{ mb: 2 }} />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Selecionar Corretora</InputLabel>
                <Select
                  value=""
                  onChange={(e) => handleAddCorretora(Number(e.target.value))}
                  label="Selecionar Corretora"
                >
                  {corretoras.map((corretora) => (
                    <MenuItem key={corretora.id} value={corretora.id}>
                      {corretora.corretora}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {fundosCorretoras.map((fc, index) => (
              <Grid container spacing={2} key={index}>
                <Grid item xs={6}>
                  <TextField
                    label="Corretora"
                    value={fc.corretora || ''}
                    disabled
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Código Corretora"
                    value={fc.codigo_corretora || ''}
                    onChange={(e) => handleCodigoCorretoraChange(index, e.target.value)}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                  <IconButton onClick={() => handleDeleteCorretora(index)} style={{ color: 'red' }}>
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ))}

          </Grid>
          <Grid item xs={12} sm={4} mt={2}>
            <Button onClick={handleSave} variant="contained" color="primary">
              Salvar Alterações
            </Button>
            <Button
            variant="outlined"
            color="error"
            onClick={onRequestClose}
            sx={{ ml: 2 }}
          >
            Cancelar
          </Button>
          </Grid>
        </Box>
      </Modal>

     <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default EditModal;