import React from 'react';

interface TableCarteiraDiariaConsultaProps {
  columns: Array<{ header: string, accessorKey: string }>;
  data: any[];
  sectionTitle: string;
}

const TableCarteiraDiariaConsulta: React.FC<TableCarteiraDiariaConsultaProps> = ({ columns, data, sectionTitle }) => {

  const formatNumber = (value: number) => {
    if (typeof value !== 'number') return value;
    return value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 7 });
  };

  const getNumberColor = (value: number) => {
    return value < 0 ? 'red' : 'green';
  };

  return (
    <div>
      <h3>{sectionTitle}</h3>
      <table border={1} style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            {columns.map((column) => (
              <th key={column.accessorKey}>{column.header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ? (
            data.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {columns.map((column) => (
                  <td
                    key={column.accessorKey}
                    style={{
                        color:
                        typeof row[column.accessorKey] === 'number'
                            ? getNumberColor(row[column.accessorKey])
                            : 'black',
                    }}
                    >
                    {typeof row[column.accessorKey] === 'number'
                        ? formatNumber(row[column.accessorKey])
                        : row[column.accessorKey]}
                    </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={columns.length}>Nenhum dado encontrado</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TableCarteiraDiariaConsulta;
