// services/authService.ts
import api from "./api";

export const authService = {
  login: async (email: string, password: string) => {
    const response = await api.post('/token/', { email, password });
    if (response.data.access) {
      localStorage.setItem('token', response.data.access);
      localStorage.setItem('refreshToken', response.data.refresh);
    }
    return response.data;
  },
  logout: () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
  },
  refreshToken: async () => {
    const refresh = localStorage.getItem('refreshToken');
    if (!refresh) return null;

    try {
      const response = await api.post('/token/refresh/', { refresh });
      if (response.data.access) {
        localStorage.setItem('token', response.data.access);
        return response.data;
      }
    } catch (error) {
      console.error('Erro ao renovar token:', error);
      return null;
    }
    return null;
  }
};

export const fetchCurrentUser = async () => {
  try {
    const response = await api.get('core/me/');
    return response.data;
  } catch (error) {
    console.error('Erro ao buscar perfil do usuário:', error);
    throw error;
  }
};
