import React, { useState } from 'react';
import { Box, List, ListItem, ListItemText, Typography } from '@mui/material';
import HistoricoModal from './HistoricoModal'; // Componente do modal

const HistoricoAbaContent: React.FC = () => {
  const [selectedFundo, setSelectedFundo] = useState<string | null>(null);

  const fundos = [
    'AGRELO STOCK FC FIA',
    'EDDA INFLATION PROTECTION FIM',
    'TRIUMPH CREDIT HIGH YIELD FIC FIMCP',
    'BRUGES MID FC FIM',
    'EDDA INFLA PRO FIM',
    'GUALTALLARY FC FIM',
    'GS PATTON FC FIM IE',
    'FORTIS FIM',
    'CFM INVEST FIA IE',
    'KALYTERA PREV FC FIM',
    'KINGSTON C FC FIDC',
    'PWM SELEC FIC FIRF',
  ]; // Lista de fundos

  const handleOpenModal = (fundo: string) => {
    setSelectedFundo(fundo); // Define o fundo selecionado
  };

  const handleCloseModal = () => {
    setSelectedFundo(null); // Fecha o modal
  };

  return (
    <Box>
      {/* Instrução ao usuário */}
        <Typography variant="h6" gutterBottom>
          Selecione o fundo para visualizar o histórico de ordens
        </Typography>

      {/* Lista de fundos */}
    <List>
        {fundos.map((fundo, index) => (
        <ListItem
            key={index}
            sx={{
            color: '#282c34',
            cursor: 'pointer', // Adiciona o cursor de clique
            '&:hover': { backgroundColor: '#f5f5f5' }, // Efeito de hover
            }}
            onClick={() => handleOpenModal(fundo)} // Abre o modal ao clicar
        >
            <ListItemText primary={fundo} />
        </ListItem>
        ))}
    </List>

      {/* Modal com a tabela */}
      {selectedFundo && (
        <HistoricoModal fundo={selectedFundo} onClose={handleCloseModal} />
      )}
    </Box>
  );
};

export default HistoricoAbaContent;
