import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import logo from '../assets/img/logo.png';
import { Details, FundoCorretora, Subclass } from '../types/fundosGerenciados';

interface DetailModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  details: Details;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxWidth: '1500px',
  maxHeight: '80vh',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
};

const logoStyle = {
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: '20px',
  width: '160px',
};

const DetailModal: React.FC<DetailModalProps> = ({ isOpen, onRequestClose, details }) => {
  const renderSection = (title: string, fields: { label: string, value: any }[]) => (
    <>
      <Typography variant="h6">
        <strong>{title}</strong>
      </Typography>
      <Divider sx={{ my: 1 }} />
      <Grid container spacing={2} sx={{ mb: 4 }}>
        {fields.map((field, index) => (
          <Grid item xs={4} key={index}>
            <Typography variant="body2" gutterBottom>
              <strong>{field.label}:</strong> {field.value}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </>
  );

  return (
    <Modal open={isOpen} onClose={onRequestClose}>
      <Box sx={style}>
        <img src={logo} alt="Logo" style={logoStyle} />
        <Typography variant="h5" component="h2">
          <strong>{details.fantasia}</strong>
        </Typography>
        <Divider sx={{ mb: 3 }} />

        {renderSection("Códigos identificadores", [
          { label: "CNPJ", value: details.cnpj },
          { label: "Razão Social", value: details.razao_social },
          { label: "Código ISIN", value: details.isin },
          { label: "Código ANBIMA", value: details.anbima },
          { label: "Código GIIN", value: details.giin },
          { label: "Código Galgo", value: details.codigo_galgo },
          { label: "Código CVM", value: details.cvm },
        ])}

        {renderSection("Classificações", [
          { label: "Classificação CVM", value: details.classificacao_cvm },
          { label: "Classificação ANBIMA", value: details.classificacao_anbima },
          { label: "Perfil de Risco", value: details.perfil_risco },
          { label: "Classificação de Risco Mont", value: details.risco_gestora },
          { label: "Número de Risco Mont", value: details.numero_risco_mont },
          { label: "Segmento (Gestora)", value: details.segmento_gestora },
          { label: "Tipo de Ativo (Gestora)", value: details.tipo_ativo_gestora },
        ])}

        {renderSection("Remuneração", [
          { label: "Taxa de Administração", value: details.taxa_administracao },
          { label: "Taxa de Performance", value: details.taxa_performance },
          { label: "Benchmark", value: details.benchmark },
          { label: "Referência do Benchmark", value: details.referencia_benchmark },
          { label: "Taxa de Custódia", value: details.taxa_custodia },
        ])}

        {details.condominio?.tipo === 'Aberto' && renderSection("Regras de Distribuição", [
          { label: "Status", value: details.status?.estado },
          { label: "Tributação", value: details.tributacao?.tipo },
          { label: "Data do primeiro aporte", value: details.data_primeiro_aporte },
          { label: "Condomínio", value: details.condominio.tipo },
          { label: "Código ISIN", value: details.codigo_isin },
          { label: "Captação", value: details.captacao?.tipo },
          { label: "Cotização de Aplicação", value: details.cotizacao_aplicacao },
          { label: "Cotização de Resgate", value: details.cotizacao_resgate },
          { label: "Liquidação de Resgate", value: details.liquidacao_resgate },
          { label: "Aplicação Inicial Mínima", value: details.aplicacao_inicial_minima },
          { label: "Movimentação Mínima", value: details.movimentacao_minima },
          { label: "Saldo Mínimo", value: details.saldo_minimo },
          { label: "Paga Proventos ?", value: details.paga_proventos ? "Sim" : "Não" },
          { label: "Periodicidade", value: details.periodicidade },
        ])}

        {details.condominio?.tipo === 'Fechado' && renderSection("Regras de Distribuição", [
          { label: "Status", value: details.status?.estado },
          { label: "Tributação", value: details.tributacao?.tipo },
          { label: "Data do primeiro aporte", value: details.data_primeiro_aporte },
          { label: "Condomínio", value: details.condominio.tipo },
          { label: "Código ISIN", value: details.codigo_isin },
          { label: "Código B3", value: details.codigo_b3 },
          { label: "Cotização de Aplicação", value: details.cotizacao_aplicacao },
          { label: "Data de Resgate", value: details.data_resgate },
          { label: "Data de Liquidação", value: details.data_liquidacao },
          { label: "Aplicação Mínima", value: details.aplicacao_minima },
          { label: "Tem subclasses ?", value: details.subclasses ? "Sim" : "Não" },
          { label: "Subclasses e séries",
            value: details.subclasse_items?.map((fc: Subclass) => `${fc.subclasse}: ${fc.serie}`).join(", ")},
        ])}

        {renderSection("Contas Definitivas", [
          { label: "Conta SELIC", value: details.conta_selic },
          { label: "Conta CETIP", value: details.codigo_cetip },
          { label: "Conta CETIP Contraparte", value: details.conta_cetip_contraparte },
          { label: "Conta CBLC", value: details.conta_cblc },
          { label: "Conta CBLC Custódia", value: details.conta_cblc_custodia },
        ])}

        {renderSection("Contas Editáveis", [
          { label: "Banco", value: details.banco },
          { label: "Número do Banco", value: details.numero_banco },
          { label: "Agência", value: details.agencia },
          { label: "Conta Bancária", value: details.conta_bancaria },
          { label: "Corretoras e Códigos",
            value: details.fundos_corretoras?.map((fc: FundoCorretora) => `${fc.corretora}: ${fc.codigo_corretora}`).join(", ")},
        ])}

        <Button onClick={onRequestClose} variant="contained" sx={{ mt: 2 }}>
          Fechar
        </Button>
      </Box>
    </Modal>
  );
};

export default DetailModal;
