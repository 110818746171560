import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { authService } from '../services/authService'; // Substitua pelo caminho correto do seu authService

// Define a interface para o contexto
interface AuthContextType {
  isAuthenticated: boolean;
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
}

// Cria o contexto com um valor inicial `null` e tipa-o corretamente
const AuthContext = createContext<AuthContextType | null>(null);

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  // Valida o token no carregamento
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const isValid = validateToken(token); // Implementação da validação do token
      setIsAuthenticated(isValid);
    }
  }, []);

  const login = async (email: string, password: string) => {
    try {
      await authService.login(email, password);
      setIsAuthenticated(true);
      const redirectPath = localStorage.getItem('redirectPath') || '/';
      localStorage.removeItem('redirectPath');
      navigate(redirectPath); // Redireciona após login
    } catch (err) {
      console.error('Erro ao fazer login:', err);
      throw new Error('Falha no login. Verifique suas credenciais.');
    }
  };

  const logout = () => {
    authService.logout();
    setIsAuthenticated(false);
    navigate('/login'); // Redireciona após logout
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

// Hook para acessar o contexto
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth deve ser usado dentro de um AuthProvider');
  }
  return context;
};

// Função para validar o token (simples exemplo, ajuste conforme necessário)
const validateToken = (token: string): boolean => {
  try {
    const payload = JSON.parse(atob(token.split('.')[1])); // Decodifica o payload JWT
    const isExpired = payload.exp * 1000 < Date.now(); // Verifica se está expirado
    return !isExpired;
  } catch {
    return false; // Se der erro ao decodificar, considera inválido
  }
};