import api from './api';
import { 
  Tributacao,
  Condominio,
  Corretora,
  Distribuicao,
  Captacao,
  Status,
  FundosGerenciadosData 
} from '../types/fundosGerenciados';

// Fetch all FundosGerenciados
export const getFundosGerenciados = async (): Promise<FundosGerenciadosData[]> => {
  try {
    const response = await api.get<{ results: FundosGerenciadosData[] }>('fundos/fundos_gerenciados/');
    return response.data.results;
  } catch (error) {
    console.error('Error fetching data from API', error);
    throw error;
  }
};

// Fetch Tributacao
export const getTributacoes = async (): Promise<Tributacao[]> => {
  try {
    const response = await api.get<{results: Tributacao[]}>('/core/tributacoes/');
    return response.data.results;
  } catch (error) {
    console.error('Error fetching tributacoes:', error);
    throw error;
  }
};

// Fetch Condominio
export const getCondominios = async (): Promise<Condominio[]> => {
  try {
    const response = await api.get<{results: Condominio[]}>('/core/condominios/');
    return response.data.results;
  } catch (error) {
    console.error('Error fetching condominios:', error);
    throw error;
  }
};

// Fetch Corretora
export const getCorretoras = async (): Promise<Corretora[]> => {
  try {
    const response = await api.get<{results: Corretora[]}>('/core/corretoras/');
    return response.data.results;
  } catch (error) {
    console.error('Error fetching corretoras:', error);
    throw error;
  }
};

// Fetch Distribuicao
export const getDistribuicoes = async (): Promise<Distribuicao[]> => {
  try {
    const response = await api.get<{results: Distribuicao[]}>('/core/distribuicoes/');
    return response.data.results;
  } catch (error) {
    console.error('Error fetching distribuicoes:', error);
    throw error;
  }
};

// Fetch Captacao
export const getCaptacoes = async (): Promise<Captacao[]> => {
  try {
    const response = await api.get<{results: Captacao[]}>('/core/captacoes/');
    return response.data.results;
  } catch (error) {
    console.error('Error fetching captacoes:', error);
    throw error;
  }
};

// Fetch Status
export const getStatusList = async (): Promise<Status[]> => {
  try {
    const response = await api.get<{ results: Status[] }>('/core/status/');
    return response.data.results;
  } catch (error) {
    console.error('Error fetching status list:', error);
    throw error;
  }
};

// Update Fundo Gerenciado
export const editarFundo = async (fundo: FundosGerenciadosData): Promise<FundosGerenciadosData> => {
  try {
    const response = await api.put<FundosGerenciadosData>(`fundos/fundos_gerenciados/${fundo.id}/`, fundo);
    return response.data;
  } catch (error) {
    console.error('Error updating fundo gerenciado:', error);
    throw error;
  }
};

export const createFundoGerenciado = async (fundoData: FundosGerenciadosData): Promise<FundosGerenciadosData> => {
  try{
  const response = await api.post<FundosGerenciadosData>('fundos/fundos-gerenciados/create/', fundoData);
  return response.data;
  } catch (error) {
    console.error('Error creating fundo gerenciado:', error);
    throw error;
  }
};


export const deleteFundoGerenciado = async (id: number) => {
  try{
  const response = await api.delete(`/fundos/fundos-gerenciados/${id}/delete/`);
  return response.data;
  } catch (error) {
    console.error('Error deleting fundo gerenciado:', error);
    throw error;
  }
};
